import { BehaviorSubject, Observable } from 'rxjs';

import { ajaxGetResponse } from 'common/AjaxGetResponses';

import { ajaxPost } from '../../../common/ajax';

const conversationSid$ = new BehaviorSubject<string | null>(null);
const conversation$ = new BehaviorSubject<any>(null);

const virtualVisitService = {
    updateVirtualAppointment$: (appointmentId: string, conversationSid: string) =>
        ajaxPost(`api/virtualappointments/update`, {
            appointmentId: appointmentId,
            conversationSid: conversationSid,
        }),
    getMyUser: (): Observable<{ userId: string }> => ajaxGetResponse('/api/me'),
    getInitialData: (): any => ajaxGetResponse(`/api/CareOnDemandUserSettings/GetInitialData`),
    saveUserPreferences: ({ userId, moduleName, userPreferencesFilters, columns }) =>
        ajaxPost(`/api/CareOnDemandUserSettings/SaveUserPreferences`, {
            userId,
            moduleName,
            userPreferencesFilters,
            columns,
        }),
};

export { conversationSid$, conversation$, virtualVisitService };
