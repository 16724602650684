import { HubConnectionBuilder } from '@microsoft/signalr';

import Auth from '../../Auth';

export const buildHubConnection = (url) => {
    return new HubConnectionBuilder()
        .withUrl(url, {
            accessTokenFactory: Auth.getToken,
        })
        .build();
};
