import {
    Dispatch,
    Reducer,
    ReducerAction,
    ReducerState,
    SetStateAction,
    useCallback,
    useReducer,
    useState,
} from 'react';

import { useIsMounted } from './useIsMounted';

export const useMountedState = <S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>] => {
    const isMounted = useIsMounted();
    const [state, setState] = useState(initialState);

    const setMountedState = useCallback(
        (arg: SetStateAction<S>) => {
            if (isMounted()) setState(arg);
        },
        [setState, isMounted],
    );

    return [state, setMountedState];
};

export const useMountedReducer = <R extends Reducer<any, any>>(
    reducer: R,
    initialState: ReducerState<R>,
    initializer?: undefined,
): [ReducerState<R>, Dispatch<ReducerAction<R>>] => {
    const isMounted = useIsMounted();
    const [state, dispatch] = useReducer(reducer, initialState, initializer);
    const mountedDispatch = useCallback(
        (arg: ReducerAction<R>) => {
            if (isMounted()) dispatch(arg);
        },
        [isMounted, dispatch],
    );

    return [state, mountedDispatch];
};
