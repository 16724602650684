import React from 'react';

import { DataManager, ODataV4Adaptor, JsonAdaptor, Query, Predicate } from '@syncfusion/ej2-data';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import debounce from 'lodash/debounce';
import { filter, map } from 'rxjs/operators';

import { currentUserService } from 'subjects/common/CurrentUserService';
import { filtersChangedService } from 'subjects/common/FiltersService';

import { ajaxGet } from '../../common/ajax';
import { getAvaDataManager } from '../../common/AVADataManager';
import { getFilterOptionsById, getFields, pagingSize } from '../../common/filterOptions';
import { getGroupUsersData } from './FiltersHelpers';

class MultiSelectTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.elementId = props.elementId;
        this.type = props.type;
        this.fields = props.fields || getFields(props.type, props.elementId);
        this.placeHolder = props.placeHolder;
        this.onChange = props.onChange;
        this.onDestroy = props.onDestroy;
        this.getIPAOrgIds = props.getIPAOrgIds;
        this.pcpOrgMap = {};
        this.inputText = '';
        var enableStatus = this.props.data ? this.props.data.length > 0 : false;
        this.state = {
            enabled: enableStatus,
        };
        this.start = 100;
        this.pagingSize = this.props.pagingSize || pagingSize;
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps?.data) {
            this.setData();
        }
    }

    componentDidMount() {
        this.setEnabled();
        this.setData();

        this.msObject.value = this.props.defaultValue || [];
        if (this.elementId === 'PCPs') {
            this.filtersChangedService$ = filtersChangedService
                .get()
                .pipe(
                    filter((f) => f.panelid === this.props.panelid && f.name === 'OrgIds'),
                    map((f) => f.value),
                )
                .subscribe((ipaOrgIds) => {
                    this.msObject.selectAll(false);
                    this.msObject.dataSource = getAvaDataManager(
                        `/odata/sessionorganizations?OrgType=${this.type}&ParentOrgIds=${
                            ipaOrgIds.length === 0 ? '' : ipaOrgIds.join(',')
                        }`,
                        ODataV4Adaptor,
                    );
                });
        } else if (this.elementId === 'GroupUsers' || this.elementId === 'TargetAccounts') {
            this.filtersChangedService$ = filtersChangedService
                .get()
                .pipe(
                    filter((f) => f.panelid === this.props.panelid && f.name === 'Groups'),
                    map((f) => f.value),
                )
                .subscribe((groups) => {
                    this.msObject.selectAll(false);
                    this.msObject.dataSource = getAvaDataManager(
                        `/api/filters/groupusers?groupids=${groups.length === 0 ? '' : groups.join(',')}`,
                        ODataV4Adaptor,
                    );
                });
        }
    }

    componentWillUnmount() {
        this.subscription && this.subscription.unsubscribe();
        if (this.filtersChangedService$) {
            this.filtersChangedService$.unsubscribe();
        }
        if (this.onDestroy) {
            this.onDestroy();
        }
    }

    setObservableData(observableData) {
        this.msObject.showSpinner();
        this.subscription = observableData.subscribe({
            next: (data) => {
                this.msObject.dataSource = new DataManager({
                    json: data,
                    adaptor: new JsonAdaptor(),
                });
                this.msObject.hideSpinner();
            },
        });
    }

    setData() {
        if (this.props.observableData) {
            this.setObservableData(this.props.observableData);
            return;
        }

        const { type, elementId } = this.props;
        if (elementId === 'ContractTypes' || elementId === 'ActiveCounties') {
            const observableData = ajaxGet(`/api/CommonData/${elementId}`).pipe(map(({ response }) => response));
            this.setObservableData(observableData);
            return;
        }

        let dataSource;
        if (this.props.data) {
            dataSource = this.props.data;
        } else if (type && ['HPs', 'IPAs', 'PCPs', 'MSOs', 'Company', 'Providers'].includes(elementId)) {
            dataSource = getAvaDataManager(`/odata/sessionorganizations?OrgType=${type}&$orderby=name`, ODataV4Adaptor);
        } else if (elementId === 'Groups' && type === 'group') {
            dataSource = getAvaDataManager('/api/filters/nonemptygroups?$orderby=name', ODataV4Adaptor);
        } else if ((elementId === 'GroupUsers' || elementId === 'TargetAccounts') && type === 'groupuser') {
            dataSource = getGroupUsersData();
        } else {
            dataSource = getFilterOptionsById(elementId);
        }

        this.msObject.dataSource = dataSource;
    }

    setEnabled() {
        if (this.elementId === 'HPs' || this.elementId === 'IPAs' || this.elementId === 'PCPs') {
            currentUserService
                .get()
                .pipe(
                    filter((user) => user && user.organizationLevel),
                    map((user) => user.organizationLevel),
                )
                .subscribe((organizationLevel) => {
                    this.setState({
                        enabled: new Boolean(organizationLevel[this.type.toLowerCase()]).valueOf(),
                    });
                });
        } else {
            this.setState({ enabled: true });
        }
    }

    handleChange = () => {
        this.onChange(this.msObject);
        if (typeof this.props.onSelected === 'function') {
            this.props.onSelected();
        }
    };

    handleClose = () => {
        this.inputText = '';
    };

    performFiltering = (args) => {
        if (this.props.filterQuery) {
            args.updateData(this.msObject.dataSource, this.props.filterQuery(args.text).take(this.pagingSize));
        } else {
            let predicate = new Predicate(this.fields.text, 'contains', args.text, true);
            if (this.props.filterWithValue && this.fields.text !== this.fields.value) {
                predicate = predicate.or(this.fields.value, 'contains', args.text, true);
            }
            args.updateData(this.msObject.dataSource, new Query().where(predicate).take(this.pagingSize));
            this.inputText = args.text;
        }
    };

    handleOpen = () => {
        if (
            !this.msObject.dataSource.executeQuery ||
            this.scrollEventListenerCreated ||
            this.props.omitLazyLoadingOnScroll
        ) {
            return;
        }
        var listElement = this.msObject.list;
        listElement.addEventListener('scroll', () => {
            if (listElement.scrollTop + listElement.offsetHeight >= listElement.scrollHeight) {
                const query = new Query()
                    .where('name', 'contains', this.inputText, true)
                    .range(this.start, this.start + this.pagingSize);
                this.msObject.dataSource
                    .executeQuery(query)
                    .then((event) => {
                        this.start = this.start + this.pagingSize;
                        this.msObject.addItem(event.result);
                    })
                    .catch((e) => {
                        console.error('multiselecttemplate filter scroll error', e);
                    });
            }
        });
        this.scrollEventListenerCreated = true;
    };

    debouncedFilter = debounce(this.performFiltering, 600);

    render() {
        return (
            <MultiSelectComponent
                id={`${this.props.elementId}-multiselect-filter-${this.props.panelid}`}
                ref={(multiselect) => (this.msObject = multiselect)}
                name={this.elementId}
                enabled={this.state.enabled}
                fields={this.fields}
                placeholder={this.placeHolder}
                change={this.handleChange}
                width={this.props.width || '200px'}
                popupHeight='400px'
                popupWidth={this.props.popupWidth || '350px'}
                mode='CheckBox'
                query={this.props.allowPaging && new Query().take(this.pagingSize)}
                enableGroupCheckBox={true}
                allowFiltering={this.props.allowFiltering ?? true}
                allowPaging={this.props.allowPaging}
                showDropDownIcon={true}
                showClearButton={true}
                maximumSelectionLength={this.props.maximumSelectionLength || 20}
                itemTemplate={this.props.itemTemplate}
                filtering={(args) => {
                    if (this.props.enableDebounce) {
                        args.preventDefaultAction = true;
                        this.debouncedFilter(args);
                    } else {
                        this.performFiltering(args);
                    }
                }}
                open={this.handleOpen}
                close={this.handleClose}
                tabIndex={this.props.tabIndex}
                showSelectAll={this.props.showSelectAll}
                value={this.msObject?.value}
            >
                <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
        );
    }
}

MultiSelectTemplate.defaultProps = {
    omitLazyLoadingOnScroll: false,
};
export default MultiSelectTemplate;
