import { EMPTY, map, of, switchMap } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import Auth from '../../Auth';
import { notificationService } from '../common/NotificationService';

export type ODataResponse = {
    '@odata.context': string;
};

export type ODataArrayResponse<T> = {
    '@odata.count': string;
    value: T[];
} & ODataResponse;

export type ODataSingleResponse<T> = ODataResponse & T;

function fromAuthToken() {
    if (Auth.isExpired()) {
        notificationService.error({
            title: 'Session Expired',
            content: 'Please login again to re-authorize',
        });

        return EMPTY;
    }

    return of(`Bearer ${Auth.getToken()}`);
}

export const constructApiUrl = (urlPrefix, methodName, args = {}) => {
    let baseUrl = `odata/${urlPrefix}/default.${methodName}`;

    if (Object.keys(args).length === 0) {
        return baseUrl;
    }

    const alias = {};
    let p = 0;

    baseUrl += '(';
    baseUrl += Object.keys(args)
        .map((key) => {
            if (args[key] === undefined) return '';

            if (args[key] === null) return '';

            if (typeof args[key] === 'string') {
                return `${key}='${encodeURIComponent(args[key])}'`;
            }

            if (typeof args[key].toISOString === 'function') {
                const aliasKey = `@p${p++}`;
                alias[aliasKey] = args[key].toISOString().replace('Z', '%2b00:00');
                return `${key}=${aliasKey}`;
            }
            return `${key}=${args[key]}`;
        })
        .join(',');
    baseUrl += ')';

    if (Object.keys(alias).length == 0) return baseUrl;

    baseUrl += `?`;
    baseUrl += Object.keys(alias)
        .map((k) => `${k}=${alias[k]}`)
        .join('&');

    return baseUrl;
};

export function getODataObject<T>(url) {
    return fromAuthToken().pipe(
        switchMap((header) =>
            ajax<ODataSingleResponse<T>>({
                url,
                method: 'GET',
                responseType: 'json',
                headers: {
                    Authorization: header,
                },
            }),
        ),
        map((response) => response.response),
    );
}

export function getODataArray<T>(url: string) {
    return fromAuthToken().pipe(
        switchMap((header) =>
            ajax<ODataArrayResponse<T>>({
                url,
                method: 'GET',
                responseType: 'json',
                headers: {
                    Authorization: header,
                },
            }),
        ),
        map((response) => response.response),
        map((odataResponse) => odataResponse.value),
    );
}
