import { useEffect, useRef, useState } from 'react';

import {
    ColumnChooser,
    ExcelExport,
    GridComponent,
    Inject,
    Page,
    Resize,
    Sort,
    Toolbar,
} from '@syncfusion/ej2-react-grids';
import { AxiosError } from 'axios';

import { useToast } from '@hooks';
import { requests } from 'common/axios';
import { IpaHospital } from 'components/ProviderData/IpaAdministration/atoms/Models';
import {
    ipaHospitalColumns,
    ipaHospitalExtraColumns,
    toolbarOptions,
} from 'components/ProviderData/IpaAdministration/organisms/IpaHospital/IpaHospital.metadata';
import IpaHospitalForm from 'components/ProviderData/IpaAdministration/organisms/IpaHospital/IpaHospitalForm';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import { Dialog } from 'interfaces/Dialog';
import { dialogService } from 'subjects/common/DialogService';

type IpaHospitalTabProps = {
    ipa?: string;
    companyId: string;
};
const IpaHospitalTab = (props: IpaHospitalTabProps) => {
    const { ipa, companyId } = props;
    const { showErrorToast } = useToast();
    const gridRef: any = useRef<GridComponent | null>(null);
    const [ipaHospitals, setIpaHospitals] = useState<IpaHospital[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchIpaHospitals = () => {
        setLoading(true);
        requests
            .get<IpaHospital[]>(`/api/IpaHospital/GetIpaHospitals?ipa=${ipa}&companyId=${companyId}`)
            .then((data) => {
                setIpaHospitals(data);
            })
            .catch((e) => {
                const message = `${(e as AxiosError)?.response?.data}`;
                showErrorToast(message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const toolbarClick = (args: { item: { id: string } }) => {
        if (args.item.id === 'ipaHospital_exportexcel') {
            const columnsToExport = [...ipaHospitalColumns, ...ipaHospitalExtraColumns];

            gridRef.current?.excelExport({
                includeHiddenColumn: true,
                fileName: 'IpaHospital-report.xlsx',
                columns: columnsToExport,
            });
        }
    };

    const showIpaHospitalForm = (row) => {
        const dialog: Dialog = {};
        dialog.header = 'Viewing Hospital Details';
        dialog.content = () => <IpaHospitalForm ipaHospital={row.rowData} />;
        dialog.width = 900;
        dialogService.open(dialog);
    };

    useEffect(() => {
        fetchIpaHospitals();
    }, []);

    return (
        <DisabledWrapper
            disabled={loading}
            withSpinner={loading}
        >
            <GridComponent
                ref={gridRef}
                allowPaging
                allowSorting
                dataSource={ipaHospitals}
                allowExcelExport={true}
                pageSettings={{
                    pageSize: 10,
                    pageSizes: [10, 25, 50, 100],
                }}
                height={450}
                rowHeight={50}
                columns={ipaHospitalColumns}
                recordDoubleClick={showIpaHospitalForm}
                toolbar={toolbarOptions}
                toolbarClick={toolbarClick}
                sortSettings={{ columns: [{ field: 'organizationName', direction: 'Ascending' }] }}
                showColumnChooser={true}
            >
                <Inject services={[Toolbar, Page, Resize, Sort, ColumnChooser, ExcelExport]} />
            </GridComponent>
        </DisabledWrapper>
    );
};

export default IpaHospitalTab;
