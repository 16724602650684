import { useCallback, useEffect, useRef, useState } from 'react';

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Filter,
    GridComponent,
    Inject,
    Page,
    RecordDoubleClickEventArgs,
    Sort,
    Toolbar,
} from '@syncfusion/ej2-react-grids';
import {
    HtmlEditor,
    Inject as RTEInject,
    RichTextEditorComponent,
    Toolbar as RTEToolbar,
    Link,
    QuickToolbar,
    ChangeEventArgs,
} from '@syncfusion/ej2-react-richtexteditor';

import { useUnsubscribe } from '@hooks';
import { trackEvent } from 'ApplicationInsights';
import { SupplementalBenefitIncentive } from 'interfaces/CRM/Benefit/SupplementalBenefitIncentive';
import { myQueueService } from 'subjects/CRM/MyQueueService';

import getSupplementalBenefitIncentiveGridCols from './benefitConfigurator.utils';

const BenefitConfigurator = () => {
    const [supplementalBenefitsMasterList, setSupplementalBenefitsMasterList] = useState<
        SupplementalBenefitIncentive[]
    >([]);
    const [loadingBenefitConfig, setLoadingBenefitConfig] = useState(false);
    const [loadingBenefits, setLoadingBenefits] = useState(false);
    const [showModalForm, setShowModalForm] = useState(false);
    const richTextEditorInstanceRef = useRef<RichTextEditorComponent>(null);
    const [{ richText, selectedBenefitIncentive }, setSelectedBenefitIncentiveAndRichText] = useState<{
        selectedBenefitIncentive: SupplementalBenefitIncentive | null;
        richText: string;
    }>({ selectedBenefitIncentive: null, richText: '' });
    const gridRef = useRef<GridComponent>(null);
    const pushSubscription = useUnsubscribe();

    useEffect(() => {
        setLoadingBenefits(true);
        pushSubscription(
            myQueueService.getSupplementalBenefitsMasterList$().subscribe({
                next: ({ value }) => {
                    setLoadingBenefits(false);
                    setSupplementalBenefitsMasterList(value);
                },
                error: (err) => {
                    setLoadingBenefits(false);
                    trackEvent('GetSupplementalBenefitsMasterError', SeverityLevel.Error, err);
                },
            }),
        );
    }, []);

    const loadConfig = useCallback((code: string, year: string, type: string) => {
        setLoadingBenefitConfig(true);
        pushSubscription(
            myQueueService.getSupplementalBenefitConfig$(code, year, type).subscribe({
                next: (res) => {
                    setLoadingBenefitConfig(false);
                    setSelectedBenefitIncentiveAndRichText((currentState) => ({
                        ...currentState,
                        richText: res?.displayText || '',
                    }));
                },
                error: (err) => {
                    setLoadingBenefitConfig(false);
                    trackEvent('GetSupplementalBenefitsMasterError', SeverityLevel.Error, err);
                },
            }),
        );
    }, []);

    const handleRecordDoubleClick = useCallback((args: RecordDoubleClickEventArgs) => {
        const { rowData } = args;
        const benefitIncentive = rowData as SupplementalBenefitIncentive;
        setSelectedBenefitIncentiveAndRichText({
            selectedBenefitIncentive: benefitIncentive,
            richText: '',
        });
        setShowModalForm(true);
        loadConfig(
            benefitIncentive.code,
            benefitIncentive.effectiveFrom.match(/\d{4}/)?.[0] || '',
            benefitIncentive?.type,
        );
    }, []);

    const handleChangeRichText = useCallback((args: ChangeEventArgs) => {
        setSelectedBenefitIncentiveAndRichText((currentState) => ({
            ...currentState,
            richText: args.value,
        }));
    }, []);

    const handleClickSend = () => {
        if (!selectedBenefitIncentive) return;
        pushSubscription(
            myQueueService
                .upsertSupplementalBenefitConfig$(
                    selectedBenefitIncentive?.code,
                    selectedBenefitIncentive.effectiveFrom.match(/\d{4}/)?.[0] || '',
                    selectedBenefitIncentive?.type,
                    richText,
                )
                .subscribe({
                    next: () => {
                        setShowModalForm(false);
                    },
                    error: (error) => trackEvent('UpsertSupplementalBenefitConfigError', SeverityLevel.Error, error),
                }),
        );
    };

    return (
        <Box sx={{ position: 'relative' }}>
            {loadingBenefits && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        zIndex: 999,
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                >
                    <CircularProgress sx={{ color: '#fc176f' }} />
                </Box>
            )}
            <GridComponent
                ref={gridRef}
                dataSource={supplementalBenefitsMasterList}
                toolbar={[{ text: 'Supplemental Benefits', align: 'Left' }]}
                pageSettings={{
                    pageSize: 50,
                    pageSizes: [25, 50, 100],
                }}
                columns={getSupplementalBenefitIncentiveGridCols()}
                allowTextWrap
                textWrapSettings={{ wrapMode: 'Content' }}
                allowReordering={false}
                allowResizing
                allowSorting
                allowPaging
                recordDoubleClick={handleRecordDoubleClick}
                allowFiltering
                selectionSettings={{ checkboxOnly: true }}
                filterSettings={{ type: 'Excel' }}
            >
                <Inject services={[Toolbar, Page, Sort, Filter]} />
            </GridComponent>

            <Dialog
                PaperProps={{
                    sx: {
                        width: '90vw',
                        '@media screen and (min-width: 800px)': {
                            width: '80vw',
                        },
                        '@media screen and (min-width: 1200px)': {
                            width: '60vw',
                        },
                    },
                }}
                open={showModalForm}
                onClose={setShowModalForm.bind({}, false)}
            >
                <DialogTitle>
                    {selectedBenefitIncentive?.code}-{selectedBenefitIncentive?.effectiveFrom.match(/\d{4}/)?.[0]}
                </DialogTitle>
                <div>
                    {loadingBenefitConfig && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                zIndex: 999,
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                            }}
                        >
                            <CircularProgress sx={{ color: '#fc176f' }} />
                        </Box>
                    )}
                    <RichTextEditorComponent
                        ref={richTextEditorInstanceRef}
                        created={() => {
                            richTextEditorInstanceRef.current?.refreshUI();
                        }}
                        change={handleChangeRichText}
                        value={richText}
                        toolbarSettings={{
                            items: [
                                'Bold',
                                'Italic',
                                'Underline',
                                '|',
                                'Formats',
                                'Alignments',
                                'OrderedList',
                                'UnorderedList',
                                '|',
                                'CreateLink',
                                '|',
                                'SourceCode',
                                'Undo',
                                'Redo',
                            ],
                        }}
                    >
                        <RTEInject services={[RTEToolbar, HtmlEditor, Link, QuickToolbar]} />
                    </RichTextEditorComponent>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
                        <Button
                            variant='outlined'
                            onClick={handleClickSend}
                        >
                            Send
                        </Button>
                    </Box>
                </div>
            </Dialog>
        </Box>
    );
};

export default BenefitConfigurator;
