import { Skeleton } from '@mui/material';
import range from 'lodash/range';
import 'components/Common/FiltersPanel.css';

type FiltersPanelSkeletonProps = {
    inputCount: number;
};

const FiltersPanelSkeleton = ({ inputCount = 5 }: FiltersPanelSkeletonProps) => {
    return (
        <div className='d-flex flex-column align-items-center gap-3 m-3'>
            <div className='d-flex flex-wrap gap-3'>
                {range(inputCount).map(() => (
                    <Skeleton
                        variant='rounded'
                        width={200}
                        height={32}
                        sx={{ display: 'inline-block' }}
                    />
                ))}
            </div>
            <div className='d-flex gap-5'>
                <Skeleton
                    variant='rounded'
                    width={120}
                    height={32}
                    sx={{ display: 'inline-block' }}
                />
                <Skeleton
                    variant='rounded'
                    width={120}
                    height={32}
                    sx={{ display: 'inline-block' }}
                />
            </div>
        </div>
    );
};

export default FiltersPanelSkeleton;
