import { useEffect, useRef, useState } from 'react';

import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import {
    TreeGridComponent,
    Inject as InjectToolbar,
    Toolbar,
    ColumnChooser,
    Page,
    Sort,
} from '@syncfusion/ej2-react-treegrid';
import moment from 'moment/moment';

import { requests } from 'components/AvaEzm/AxiosApi';
import { getItems } from 'components/AvaEzm/Utilities/ODataUtility';
import {
    IpaCountyMapGridColumns,
    tabsToolbarOptions,
} from 'components/ProviderData/IpaAdministration/atoms/IpaAdministration.Metadata';
import { IpaCounty } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { IpaCountyMappingForm } from 'components/ProviderData/IpaAdministration/organisms/CountyMapping/IpaCountyMappingForm';
import { ipaPlanService } from 'components/ProviderData/subjects/IpaPlanService';
import { dialogService } from 'subjects/common/DialogService';

type IpaCountyMappingProps = {
    ipaCode?: string;
    ipaId?: number;
    companyCode: string;
    isReadOnlyViewer: boolean;
    state?: string;
};
const IpaCountyMappingTab = (props: IpaCountyMappingProps) => {
    const { ipaCode, ipaId, companyCode, isReadOnlyViewer } = props;
    const ipaCountyGridRef = useRef<TreeGridComponent>(null);
    const toastRef = useRef<ToastComponent>(null);
    const [ipaCounty, setIpaCounty] = useState<IpaCounty | null>();
    const [IPAPlanData, setIPAPlanData] = useState<any>([]);
    const [contiguousCounties, setContiguousCounties] = useState<any[]>([]);
    const [operatingCounties, setOperatingCounties] = useState<any[]>([]);
    const [planCountyMapping, setPlanCountyMapping] = useState(false);

    const fetchContiguousCounties = async () => {
        const _counties: any[] = await requests.get(`/api/IpaCountyMapping/GetContiguousCounties`);
        setContiguousCounties(_counties);
    };
    const fetchOperatingCounties = async () => {
        const year = moment(ipaCounty?.effectiveBeginDate).format('YYYY');
        const _opCounties: any[] = await requests.get(
            `/api/IpaCountyMapping/GetOperatingCounties?IpaCode=${ipaCode}&companyCode=${companyCode}&planYear=${year}`,
        );
        setOperatingCounties(_opCounties);
    };

    const deleteIpaCountyHandler = async () => {
        try {
            await requests.deleteWithParams('/api/IpaCountyMapping/DeleteIpaCounty', {
                id: ipaCounty?.id,
                ipaCode,
                companyCode,
            });
            toastRef.current?.show({
                title: 'Success !',
                content: 'Ipa County deleted successfully',
                cssClass: 'e-toast-success',
            });
            updateDataSource();
            dialogService.close();
        } catch (e) {
            toastRef.current?.show({
                title: 'Error !',
                content: 'Error in deleting Ipa County',
                cssClass: 'e-toast-danger',
            });
        }
    };

    const saveIpaCountyButtons = [
        {
            buttonModel: {
                disabled: isReadOnlyViewer,
                content: 'Save',
                iconCss: 'e-icons e-save-icon',
                cssClass: 'e-submit',
            },
            click: () => {
                ipaPlanService.submit();
            },
        },
        {
            buttonModel: {
                disabled: isReadOnlyViewer,
                content: 'Delete',
                iconCss: 'e-icons e-delete-icon',
                cssClass: 'e-cancel',
            },
            click: () => {
                deleteIpaCountyHandler();
            },
        },
        {
            buttonModel: {
                disabled: isReadOnlyViewer,
                content: 'Cancel',
                iconCss: 'e-icons e-cancel-icon',
                cssClass: 'e-cancel',
            },
            click: () => {
                dialogService.close();
                setIpaCounty(null);
            },
        },
    ];

    const updateDataSource = () => {
        const searchContactsUrl = `/api/IpaCountyMapping/GetListOfIpaCountyMappings?ipaCode=${ipaCode}&companyCode=${companyCode}&top=10&skip=0`;
        getItems<any>(searchContactsUrl).then((data: any[]) => {
            const updateData = data.map((data) => {
                const contiguousCounties = (data.contiguousCounties || []).map((c) => ({
                    ...data,
                    ...c,
                    countyType: 'contiguous Counties',
                }));
                const operatingCounties = (data.operatingCounties || []).map((c) => ({
                    ...data,
                    ...c,
                    countyType: 'operating Counties',
                }));

                return {
                    ...data,
                    combinedCounty: [...contiguousCounties, ...operatingCounties],
                };
            });
            setIPAPlanData(updateData);
        });
    };

    const toolbarHandler = (args: any) => {
        if (args.item.id === 'refresh') {
            updateDataSource();
        }
        if (args.item.id === 'create') {
            ipaPlanService?.select({} as IpaCounty);
            setPlanCountyMapping(true);
        }
    };

    useEffect(() => {
        if (ipaCountyGridRef && props.isReadOnlyViewer) {
            ipaCountyGridRef.current?.toolbarModule.enableItems(['create', 'edit', 'delete'], false);
        }

        if (ipaCounty && planCountyMapping) {
            const dialog: any = {};
            dialog.header = `${ipaCounty.id ? 'Edit' : 'Create'} County Mapping`;
            const newButtons = saveIpaCountyButtons.filter((b) => b.buttonModel.content !== 'Delete');
            dialog.buttons = ipaCounty.id ? saveIpaCountyButtons : newButtons;
            dialog.content = () => (
                <IpaCountyMappingForm
                    IPAPlanData={IPAPlanData}
                    ipaCounty={ipaCounty}
                    companyCode={companyCode}
                    ipaCode={ipaCode}
                    ipaId={ipaId}
                    isViewer={props.isReadOnlyViewer}
                    contiguousCounties={contiguousCounties}
                    operatingCounties={operatingCounties}
                />
            );
            dialog.width = 900;
            dialog.height = 600;
            dialogService.open(dialog);
            setIpaCounty(null);
            setPlanCountyMapping(false);
        }
    }, [ipaCounty, planCountyMapping]);

    useEffect(() => {
        updateDataSource();
        fetchOperatingCounties();
        fetchContiguousCounties();
        ipaPlanService.get$().subscribe((ipap) => setIpaCounty(ipap));
        const subscription = ipaPlanService.submit$().subscribe((validated) => {
            if (validated) {
                if (validated.error) {
                    toastRef.current?.show({
                        title: 'Error',
                        content: validated.message,
                        cssClass: 'e-toast-danger',
                    });
                } else {
                    toastRef.current?.show({
                        title: 'Success !',
                        content: 'Ipa County saved successfully',
                        cssClass: 'e-toast-success',
                    });
                    updateDataSource();
                }
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return (
        <div className='tab-content tabDiv p-3'>
            <div className='grid-container'>
                <TreeGridComponent
                    pageSettings={{ pageCount: 3 }}
                    columns={IpaCountyMapGridColumns}
                    height={450}
                    dataSource={IPAPlanData}
                    toolbar={tabsToolbarOptions as any}
                    recordDoubleClick={(data) => {
                        ipaPlanService?.select(data.rowData), setPlanCountyMapping(true);
                    }}
                    ref={ipaCountyGridRef}
                    toolbarClick={toolbarHandler}
                    treeColumnIndex={0}
                    childMapping='combinedCounty'
                    selectionSettings={{ mode: 'Row', type: 'Single' }}
                    editSettings={{ allowAdding: true }}
                    allowSelection={true}
                    allowExcelExport={true}
                    showColumnChooser={true}
                    allowPaging={true}
                    allowSorting={true}
                >
                    <InjectToolbar services={[Toolbar, ColumnChooser, Page, Sort]} />
                </TreeGridComponent>
            </div>
            <ToastComponent
                id='toast_target'
                ref={toastRef}
                position={{ X: 'Right', Y: 'Top' }}
                style={{ position: 'fixed', top: '50px' }}
            />
        </div>
    );
};

export default IpaCountyMappingTab;
