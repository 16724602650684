import { ColumnModel as GridColumnModel } from '@syncfusion/ej2-react-grids';
import { ColumnModel as TreeGridColumnModel } from '@syncfusion/ej2-react-treegrid';

import { RiskPoolRateDetail } from 'components/ProviderData/IpaAdministration/atoms/Models';

export const TEXTBOX_ATTRS = { showSpinButton: false, validateDecimalOnType: true, min: 0, strictMode: true };

export const DECIMAL_4 = {
    ...TEXTBOX_ATTRS,
    placeholder: '0.0000',
    format: '0.0000',
    decimals: 4,
};

export const BASE_URI = 'api/IpaRiskPoolRate';

export enum HistoryViewMode {
    Pending = 'Pending',
    All = 'All',
}

export const riskPoolRatesColumns: (TreeGridColumnModel & { field: keyof RiskPoolRateDetail })[] = [
    {
        field: 'sequence',
        width: 170,
        headerText: 'Sequence',
        type: 'text',
    },
    {
        field: 'status',
        width: 100,
        headerText: 'Status',
        type: 'text',
    },
    {
        field: 'rateId',
        width: 80,
        headerText: 'ID',
        type: 'text',
    },
    {
        field: 'effectiveStartDate',
        width: 140,
        headerText: 'Effective Date',
        type: 'date',
        format: 'MM/dd/yyyy',
    },
    {
        field: 'effectiveEndDate',
        width: 120,
        headerText: 'End Date',
        type: 'date',
        format: 'MM/dd/yyyy',
    },
    {
        field: 'createdById',
        width: 120,
        headerText: 'Created By',
        type: 'text',
    },
    {
        field: 'createdAt',
        width: 150,
        headerText: 'Created Date',
        type: 'datetime',
        format: 'MM/dd/yyyy hh:mm:ss',
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'lastUpdatedById',
        width: 120,
        headerText: 'Updated By',
        type: 'text',
    },
    {
        field: 'lastUpdatedAt',
        width: 150,
        headerText: 'Updated Date',
        type: 'datetime',
        format: 'MM/dd/yyyy hh:mm:ss',
        clipMode: 'EllipsisWithTooltip',
    },
];

export const RiskPoolChangeHistoryColumns: (GridColumnModel & { field: keyof RiskPoolChangeHistory })[] = [
    {
        field: 'name',
        headerText: 'Field Name',
        clipMode: 'EllipsisWithTooltip',
        autoFit: true,
        valueAccessor: (_, data) => RiskPoolLabel[data['name']] ?? data['name'],
    },
    {
        field: 'currentValue',
        headerText: 'Updated From',
        width: 200,
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'newValue',
        headerText: 'Updated To',
        width: 200,
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'updatedBy',
        headerText: 'Updated By',
        clipMode: 'EllipsisWithTooltip',
        autoFit: true,
    },
    {
        field: 'updatedAt',
        headerText: 'Updated On',
        sortComparer: (a, b) => new Date(b as string).getTime() - new Date(a as string).getTime(),
    },
    {
        field: 'approvedBy',
        headerText: 'Approved By',
        clipMode: 'EllipsisWithTooltip',
        autoFit: true,
    },
    {
        field: 'approvedAt',
        headerText: 'Approved On',
        clipMode: 'EllipsisWithTooltip',
        type: 'DateTime',
        format: 'MM/dd/yyyy hh:mm:ss a',
        autoFit: true,
    },
];

export const defaultValuesRiskPoolRates = {
    hospitalBudgetPercent: 0,
    adminFeePercent: 0,
    surplusSharePercent: 0,
    deficitSharePercent: 0,
    esrdPremiumPercent: 0,
    deficitProrationPercent: 1,
    effectiveStartDate: new Date(),
    effectiveEndDate: new Date(),
    isCawCost: false,
    isESRDApplicable: false,
    isHospitalistPmPm: false,
    isJSAPmPm: false,
    isRiskPoolRate: false,
    notes: '',
    isDeleted: false,
    riskPoolRateTiers: [],
};

export const defaultValuesRiskPoolTieredRates = {
    hospitalBudgetPercent: 0,
    adminFeePercent: 0,
    surplusSharePercent: 0,
    deficitSharePercent: 0,
    esrdPremiumPercent: 0,
    deficitProrationPercent: 1,
    effectiveStartDate: new Date(),
    effectiveEndDate: new Date(),
    isCawCost: false,
    isESRDApplicable: false,
    isHospitalistPmPm: false,
    isJSAPmPm: false,
    isRiskPoolRate: false,
    notes: '',
    id: '',
    isDeleted: false,
};

export enum RiskPoolLabel {
    capRevenue = 'Cap Revenue',
    ipaCode = 'IPA Code',
    hospitalBudgetPercent = 'Hospital Budget %',
    adminFeePercent = 'Admin Fee %',
    surplusSharePercent = 'Surplus Share %',
    deficitSharePercent = 'Deficit Share %',
    esrdPremiumPercent = 'ESRD Premium %',
    deficitProrationPercent = 'Deficit Proration %',
    effectiveStartDate = 'Effective Date',
    effectiveEndDate = 'End Date',
    isESRDApplicable = 'Is ESRD Applicable?',
    isCawCost = 'CAW Cost?',
    isHospitalistPmPm = 'Hospital PMPM',
    isJSAPmPm = 'JSA PMPM',
    notes = 'Notes',
    memberCountStart = 'Member Count Start',
    memberCountEnd = 'Member Count End',
    sequence = 'Sequence',
}

export interface RiskPoolChangeHistory {
    name: string | null;
    currentValue: string | null;
    newValue: string | null;
    updatedAt: string | null;
    updatedBy: string | null;
    approvedAt: string | null;
    approvedBy: string | null;
}
