import { DataManager, Query, ReturnOption, WebApiAdaptor } from '@syncfusion/ej2-data';

import { getODataV4DataManager, getAvaDataManager } from 'common/AVADataManager';

export async function getItems<T>(url: string): Promise<T[]> {
    try {
        const oDataAdaptor = getODataV4DataManager(url);
        const response: ReturnOption = await oDataAdaptor.executeQuery(new Query());
        return response.result as T[];
    } catch (error) {
        console.error(error);
        return [] as T[];
    }
}

export function getUrlDataManager(url): DataManager {
    return getAvaDataManager(url, WebApiAdaptor);
}
