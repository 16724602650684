import { useCallback, useEffect, useState } from 'react';

import { Query } from '@syncfusion/ej2-data';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent, Inject, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, FilteringEventArgs, SortOrder } from '@syncfusion/ej2-react-dropdowns';
import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AxiosError } from 'axios';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { useForm, Validate } from 'react-hook-form';

import { useMultiStepsForm, useToast } from '@hooks';
import { requests } from 'common/axios';
import { formatUTCDate } from 'common/widgets';
import { getItems } from 'components/AvaEzm/Utilities/ODataUtility';
import { IPA } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { refreshTabService } from 'components/ProviderData/subjects/IpaAdministrationService';
import { Dialog } from 'interfaces/Dialog';
import { dialogService } from 'subjects/common/DialogService';

import { asyncDebounce } from '../atoms/utils';

type IpaCreateProps = {
    ipaId: string;
    isCopyToNew: boolean;
};

export const IpaAdministrationWizard = (props: IpaCreateProps) => {
    const { ipaId, isCopyToNew } = props;
    const [ipa, setIpa] = useState<any>({});
    const [visitedSteps, setVisitedSteps] = useState<number[]>([0]);
    const { showSuccessToast, showErrorToast } = useToast();
    const [companyCodes, setCompanyCodes] = useState<string[]>([]);
    const [mso, setMso] = useState<any>({});
    const [contracts, setContracts] = useState<any[]>([]);
    const [risks, setRisks] = useState<any[]>([]);
    const [types, setTypes] = useState<any[]>([]);
    const [termed, setTermed] = useState<string>();
    const [vendors, setVendors] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<IPA>();
    const fields: object = { text: 'vendorId', value: 'vendorId' };

    const query: Query = new Query().select(['vendorId', 'vendorId']).take(50);

    const sortOrder: SortOrder = 'Ascending';

    function onFiltering(e: FilteringEventArgs) {
        if (e.text === '') {
            e.updateData(vendors);
        } else {
            if (e.text.length < 3) {
                return;
            }
            let query: Query = new Query().select(['vendorId', 'vendorId']);
            query = e.text !== '' ? query.where('vendorId', 'startswith', e.text, true) : query;
            e.updateData(vendors, query);
        }
    }

    const fetchCompanyCodes = async () => {
        const _companyCodes = await getItems<string>('/api/MedicalGroup/GetCompanyCodes');
        setCompanyCodes(_companyCodes);
    };
    const fetchMso = async (contractId) => {
        let _mso: any = {};
        _mso = await getItems<string>(`/api/MedicalGroup/GetMsoDetails?contractId=${contractId}`);
        setMso(_mso);
        for (const m in _mso) {
            const field = m.charAt(0).toUpperCase() + m.slice(1);
            setValue(('mso' + field) as keyof IPA, _mso[field]);
        }
        setTermed(_mso.isTermed);
    };
    const fetchVendors = async () => {
        let _vendors: any = [];
        _vendors = await getItems<string>(`/api/MedicalGroup/GetVendorID`);
        setVendors(_vendors);
    };
    const fetchContracts = async (_companyCode) => {
        let _contracts: any = [];
        _contracts = await getItems<string>(
            `/api/MedicalGroup/GetIpaContractsByCompanyCode?companyCode=${_companyCode}`,
        );
        setContracts(_contracts);
    };
    const fetchRisks = async () => {
        let _risks: any = [];
        _risks = await getItems<string>(`/api/MedicalGroup/GetIpaRisks`);
        setRisks(_risks);
    };
    const fetchTypes = async () => {
        let _types: any = [];
        _types = await getItems<string>(`/api/MedicalGroup/GetIpaTypes`);
        setTypes(_types);
    };
    const checkIpaCode = async (ipaCode, effDate) => {
        const exist = await getItems<string>(
            `/api/medicalgroup/IpaCodeAlreadyExists?ipaCode=${ipaCode}&effDate=${effDate}`,
        );
        return !exist;
    };
    const fetchVendorDetails = async (vendorId) => {
        let _vendor: any = {};
        _vendor = await getItems<string>(`/api/MedicalGroup/GetVendorData?vendorId=${vendorId}`);
        setValue('vendorNm', _vendor.vendorName);
        setValue('ipaDesc', _vendor.vendorName);
        setValue('displayVendorNm', _vendor.vendorName);
        setValue('city', _vendor.city);
        setValue('addr', _vendor.street);
        setValue('state', _vendor.state);
        setValue('zip', _vendor.zip);
        setValue('street2', _vendor.street2);
        setValue('phone', _vendor.phone);
        setValue('fax', _vendor.fax);
        setValue('email', _vendor.email);
        setValue('taxId', _vendor.taxId);
        setValue('npi', _vendor.vendorNpi);
        setValue('vendorId', _vendor.vendorId);
    };
    const IpaInfo = () => (
        <div className='accordion-content'>
            <Row>
                <Col
                    md={6}
                    className='px-4'
                    style={{ borderRight: '1px solid #ccc' }}
                >
                    <div className='infoStepsWrap'>
                        <p className='section-container-header mx-0'>Main Info</p>
                    </div>
                    <Row>
                        <Col md={6}>
                            <div
                                style={{ width: '100%' }}
                                className='datepicker e-input-group-margin'
                            >
                                <label className='e-label-select'>Effective Date *</label>
                                <DatePickerComponent
                                    format='MM/dd/yyyy'
                                    enableMask={true}
                                    id='calendar_start'
                                    {...register('effDate', {
                                        required: 'Effective Date is required',
                                    })}
                                    change={({ value }) => setValue('effDate', value)}
                                    min={undefined}
                                    max={new Date(9999, 11, 31)}
                                    strictMode
                                >
                                    <Inject services={[MaskedDateTime]} />
                                </DatePickerComponent>
                                {errors.effDate && <span className='error-form'>{errors.effDate.message}</span>}
                            </div>
                        </Col>

                        <Col md={6}>
                            <div
                                style={{ width: '100%' }}
                                className='datepicker e-input-group-margin'
                            >
                                <label className='e-label-select'>Term Date *</label>
                                <DatePickerComponent
                                    format='MM/dd/yyyy'
                                    enableMask={true}
                                    id='calendar_end'
                                    {...register('endDate', {
                                        required: 'Term Date is required',
                                        validate: (value) => {
                                            const effDate = moment(new Date(getValues('effDate')));
                                            const endDate = moment(new Date(getValues('endDate')));
                                            if (value && effDate && endDate.isBefore(effDate)) {
                                                return `End date must be Greater than Effective Date`;
                                            }
                                        },
                                    })}
                                    {...register('endDate', {
                                        required: 'Term Date is required',
                                    })}
                                    change={({ value }) => setValue('endDate', value)}
                                    min={undefined}
                                    max={new Date(9999, 11, 31)}
                                    strictMode
                                >
                                    <Inject services={[MaskedDateTime]} />
                                </DatePickerComponent>
                                {errors.endDate && <span className='error-form'>{errors.endDate.message}</span>}
                            </div>
                        </Col>
                    </Row>
                    <Col md={12}>
                        <div className={`e-float-input e-input-group e-input-group-margin`}>
                            <input
                                type='text'
                                {...register('ipaCode', {
                                    required: 'IPA Code is required',
                                    maxLength: {
                                        value: 12,
                                        message: 'IPA Code must be maximun 12 characters length',
                                    },
                                    validate: {
                                        checkUrl: asyncDebounce(async (value) => {
                                            const effDate = formatUTCDate(getValues('effDate'));
                                            return (
                                                (await checkIpaCode(value, effDate)) || 'That IPA Code already exists'
                                            );
                                        }, 1000) as Validate<string>,
                                    },
                                })}
                            />
                            <span className='e-float-line'></span>
                            <label className='e-float-text'>IPA Code *</label>
                        </div>
                        {errors.ipaCode && <p className='error-form'>{errors.ipaCode.message}</p>}
                    </Col>
                    <Row>
                        <Col md={6}>
                            <div className='e-input-group-margin'>
                                <div className='control-label label-mask'>IPA Phone 1</div>
                                <MaskedTextBoxComponent
                                    mask='(000) 000-0000'
                                    {...register('ipaPhone', {
                                        maxLength: 10,
                                        minLength: 10,
                                    })}
                                />
                            </div>
                            {errors.ipaPhone && (
                                <p className='error-form'>Incorrect format phone number (123) 123-1234</p>
                            )}
                        </Col>
                        <Col md={6}>
                            <div className='e-input-group-margin'>
                                <div className='control-label label-mask'>IPA Phone 2</div>
                                <MaskedTextBoxComponent
                                    mask='(000) 000-0000'
                                    {...register('ipaPhone2', {
                                        maxLength: 10,
                                        minLength: 10,
                                    })}
                                />
                            </div>
                            {errors.ipaPhone2 && (
                                <p className='error-form'>Incorrect format phone number (123)123-1234</p>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className='e-input-group-margin'>
                                <label className='e-label-select'>Contract Type *</label>
                                <DropDownListComponent
                                    id='risk'
                                    dataSource={risks}
                                    value={getValues().risk}
                                    placeholder='Select a Contract Type'
                                    fields={{ text: 'text', value: 'id' }}
                                    change={({ itemData }) => setValue('risk', itemData.id)}
                                    popupHeight='220px'
                                    {...register('risk', { required: 'Contract Type is required' })}
                                />
                            </div>
                            {errors.risk && <span className='error-form'>{errors.risk.message}</span>}
                        </Col>
                        <Col md={6}>
                            <div className='e-input-group-margin'>
                                <label className='e-label-select'>Type *</label>
                                <DropDownListComponent
                                    id='type'
                                    dataSource={types}
                                    placeholder='Select a type'
                                    {...register('type', { required: 'Type is required' })}
                                    change={({ value }) => {
                                        setValue('type', value);
                                    }}
                                    popupHeight='220px'
                                />
                            </div>
                            {errors.type && <p className='error-form'>{errors.type.message}</p>}
                        </Col>
                    </Row>
                    <Col md={12}>
                        <div style={{ display: 'flex' }}>
                            <div
                                className={`e-float-input e-input-group e-input-group-margin`}
                                style={{ marginRight: '8px' }}
                            >
                                <input
                                    type='text'
                                    {...register('webSite', {
                                        maxLength: {
                                            value: 200,
                                            message: 'Website must be maximun 200 characters length.',
                                        },
                                    })}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>Website</label>
                                {errors.webSite && <p className='error-form'>{errors.webSite.message}</p>}
                            </div>
                            <ButtonComponent
                                type='button'
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (getValues('webSite')) {
                                        const website = '//' + getValues('webSite');
                                        window.open(website, '_blank');
                                    }
                                }}
                                style={{ height: '32px', padding: '8px 10px', marginTop: '15px' }}
                                cssClass='e-primary btn-primary'
                            >
                                View
                            </ButtonComponent>
                        </div>
                    </Col>
                    <div className='e-float-input e-input-group'>
                        <textarea
                            rows={4}
                            {...register('notes', {
                                maxLength: {
                                    value: 50,
                                    message: 'Notes must be maximun 50 characters length',
                                },
                            })}
                        />
                        <span className='e-float-line'></span>
                        <label className='e-float-text'>Notes</label>
                    </div>
                </Col>
                <Col
                    md={6}
                    className='px-4'
                >
                    <div className='infoStepsWrap'>
                        <p className='section-container-header mx-0'>Vendor Details</p>
                    </div>
                    <Row>
                        <Col md={6}>
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={`e-float-input e-input-group disable-field`}
                                    style={{ marginRight: '5px' }}
                                >
                                    <input
                                        type='text'
                                        style={{ paddingLeft: '10px' }}
                                        {...register('vendorId', {
                                            maxLength: {
                                                value: 40,
                                                message: 'Vendor ID must be maximun 40 characters length',
                                            },
                                        })}
                                    />
                                    <span className='e-float-line'></span>
                                    <label className='e-float-text'>Vendor ID</label>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                                <input
                                    type='text'
                                    {...register('npi')}
                                    style={{ paddingLeft: '10px' }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>NPI</label>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className={`e-float-input e-input-group disable-field`}>
                                <input
                                    type='text'
                                    {...register('taxId')}
                                    style={{ paddingLeft: '10px' }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>Tax ID</label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                                <input
                                    type='text'
                                    {...register('addr')}
                                    style={{ paddingLeft: '10px' }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>Street Address</label>
                            </div>
                        </Col>
                    </Row>

                    <Row className='e-input-group-margin'>
                        <Col md={4}>
                            <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                                <input
                                    type='text'
                                    {...register('city')}
                                    style={{ paddingLeft: '10px' }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>City</label>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                                <input
                                    type='text'
                                    {...register('state')}
                                    style={{ paddingLeft: '10px' }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>State</label>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                                <input
                                    type='text'
                                    {...register('zip')}
                                    style={{ paddingLeft: '10px' }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>Zip Code</label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className='infoStepsWrap'>
                            <p className='section-container-header mx-0'>Contract & MCO Details</p>
                        </div>
                        <Col md={4}>
                            <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                                <input
                                    type='text'
                                    {...register('companyCode')}
                                    value={getValues().companyCode}
                                    style={{ paddingLeft: '10px' }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>Company Code</label>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                                <input
                                    type='text'
                                    {...register('contractName')}
                                    value={getValues().contractName}
                                    style={{ paddingLeft: '10px' }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>Contract Name</label>
                            </div>
                            {termed === 'Y' && <span className='error-form'>The contract is termed</span>}
                        </Col>
                    </Row>
                    <Col md={12}>
                        <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                            <input
                                type='text'
                                {...register('msoName')}
                                value={mso?.name}
                            />
                            <label className='e-float-text'>MSO Name</label>
                        </div>
                    </Col>
                </Col>
            </Row>
        </div>
    );
    const MemberProviderInfo = () => (
        <Row className='accordion-content'>
            <Col md={6}>
                <div className={`e-input-group-margin`}>
                    <div className='e-float-text'>Member Card/IPA Phone *</div>
                    <MaskedTextBoxComponent
                        mask='(000) 000-0000'
                        {...register('ipaPhoneIdCard', {
                            required: true,
                            maxLength: 10,
                            minLength: 10,
                        })}
                    />
                    {errors.ipaPhoneIdCard && <p className='error-form'>IPA phone is required</p>}
                </div>
            </Col>

            <Col md={6}>
                <div className={`e-float-input e-input-group`}>
                    <input
                        type='text'
                        {...register('ipaNameIdCard', {
                            required: 'IPA Name is required',
                            maxLength: {
                                value: 100,
                                message: 'IPA Name must be maximun 100 characters length',
                            },
                        })}
                    />
                    <span className='e-float-line'></span>
                    <label className='e-float-text'>Member Card/IPA Name *</label>
                </div>
                {errors.ipaNameIdCard && <span className='error-form'>{errors.ipaNameIdCard.message}</span>}
            </Col>
        </Row>
    );
    const ContractInfo = () => (
        <div className='accordion-content'>
            <Row>
                <Col md={5}>
                    <Row>
                        <Col md={6}>
                            <div>
                                <label className='e-label-select'>Type *</label>
                                <DropDownListComponent
                                    id='type'
                                    dataSource={types}
                                    placeholder='Select a type'
                                    {...register('type', { required: 'Type is required' })}
                                    change={({ value }) => {
                                        setValue('type', value);
                                    }}
                                    popupHeight='220px'
                                />
                            </div>
                            {errors.type && <p className='error-form'>{errors.type.message}</p>}
                        </Col>
                        <Col md={6}>
                            <div>
                                <label className='e-label-select'>Contract Type *</label>
                                <DropDownListComponent
                                    id='risk'
                                    dataSource={risks}
                                    value={getValues().risk}
                                    placeholder='Select a Contract Type'
                                    fields={{ text: 'text', value: 'id' }}
                                    change={({ itemData }) => setValue('risk', itemData.id)}
                                    popupHeight='220px'
                                    {...register('risk', { required: 'Contract Type is required' })}
                                />
                            </div>
                            {errors.risk && <span className='error-form'>{errors.risk.message}</span>}
                        </Col>
                    </Row>
                </Col>

                <Col md={7}>
                    <Row>
                        <Col md={5}>
                            <label className='e-label-select'>Company Code *</label>
                            <DropDownListComponent
                                id='companyCode'
                                {...register('companyCode', {
                                    required: 'Company Code is required',
                                })}
                                dataSource={companyCodes}
                                placeholder='Select a Company Code'
                                value={getValues().companyCode}
                                change={({ value }) => {
                                    setValue('companyCode', value);
                                    fetchContracts(value);
                                }}
                                fields={{
                                    text: 'companyName',
                                    value: 'companyCode',
                                }}
                                popupHeight='220px'
                            />
                            {errors.companyCode && <p className='error-form'>{errors.companyCode.message}</p>}
                        </Col>
                        <Col md={7}>
                            <label className='e-label-select'>Contract Name *</label>
                            <DropDownListComponent
                                id='contractName'
                                {...register('contractName', {
                                    required: 'Contract Name is required',
                                })}
                                dataSource={contracts}
                                placeholder='Select a Contract Name'
                                value={getValues().contractName}
                                change={({ itemData, value }) => {
                                    setValue('contractLevelId', Number(itemData.id));
                                    setValue('contractName', value);
                                    fetchMso(itemData.id);
                                }}
                                popupHeight='220px'
                            />
                            {termed === 'Y' && <span className='error-form'>The contract is termed</span>}
                            {errors.contractName && <p className='error-form'>{errors.contractName.message}</p>}
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* <Row className='mt-2'>
                <Col md={5}>
                    <Row>
                        <Col md={6}>
                            <div className={`e-float-input e-input-group e-input-group-margin`}>
                                <input
                                    type='text'
                                    {...register('facetsCode', {
                                        required: 'Facets Code is required',
                                    })}
                                    placeholder='Facets Code'
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>Facets Code *</label>
                            </div>
                            {errors.facetsCode && <p className='error-form'>{errors.facetsCode.message}</p>}
                        </Col>
                    </Row>
                </Col>
            </Row> */}
        </div>
    );
    const MsoDetails = () => (
        <div className='accordion-content'>
            <Row>
                <Col md={4}>
                    <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                        <input
                            type='text'
                            {...register('msoName')}
                            value={mso?.name}
                        />
                        <label className='e-float-text'>MSO Name</label>
                    </div>
                </Col>

                <Col md={4}>
                    <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                        <input
                            type='text'
                            {...register('msoAddress1')}
                            value={mso?.address1}
                        />
                        <span className='e-float-line'></span>
                        <label className='e-float-text'>Address</label>
                    </div>
                </Col>

                <Col md={4}>
                    <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                        <input
                            type='text'
                            {...register('msoAddress2')}
                            defaultValue={mso?.address2}
                        />
                        <span className='e-float-line'></span>
                        <label className='e-float-text'>Suite/Bldg/No</label>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                        <input
                            type='text'
                            {...register('msoCity')}
                            defaultValue={mso?.city}
                        />
                        <span className='e-float-line'></span>
                        <label className='e-float-text'>City</label>
                    </div>
                </Col>
                <Col md={4}>
                    <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                        <input
                            type='text'
                            {...register('msoState')}
                            defaultValue={mso?.state}
                        />
                        <span className='e-float-line'></span>
                        <label className='e-float-text'>State</label>
                    </div>
                </Col>
                <Col md={4}>
                    <div className={`e-float-input e-input-group e-input-group-margin disable-field`}>
                        <input
                            type='text'
                            {...register('msoZipCode')}
                            defaultValue={mso?.zipCode}
                        />
                        <span className='e-float-line'></span>
                        <label className='e-float-text'>Zip</label>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <div className='e-input-group-margin'>
                        <label className='e-label-select'>Phone / Fax</label>
                        <MaskedTextBoxComponent
                            mask='(000) 000-0000'
                            {...register('msoPhone')}
                            value={mso?.phone}
                            readOnly={true}
                        />
                        <span className='e-float-line'></span>
                    </div>
                </Col>

                <Col md={4}>
                    <div className={`e-float-input e-input-group e-input-group-margin disable-field col-md-4`}>
                        <input
                            type='text'
                            {...register('msoEmail')}
                            defaultValue={mso?.email}
                        />
                        <span className='e-float-line'></span>
                        <label className='e-float-text'>Email</label>
                    </div>
                </Col>

                <Col md={4}>
                    <div className={`e-float-input e-input-group e-input-group-margin disable-field col-md-4`}>
                        <input
                            type='text'
                            {...register('msoNotes')}
                            defaultValue={mso?.notes}
                        />
                        <span className='e-float-line'></span>
                        <label className='e-float-text'>Notes</label>
                    </div>
                </Col>
            </Row>
        </div>
    );
    const AhcPoi = () => (
        <Row className='accordion-content'>
            <Col md={6}>
                <div className={`e-float-input e-input-group e-input-group-margin`}>
                    <input
                        type='text'
                        {...register('manager')}
                        defaultValue={getValues()?.manager}
                    />
                    <span className='e-float-line'></span>
                    <label className='e-float-text'>Manager</label>
                </div>
            </Col>

            <Col md={6}>
                <div className={`e-float-input e-input-group`}>
                    <input
                        type='text'
                        {...register('specialist')}
                        defaultValue={getValues()?.specialist}
                    />
                    <span className='e-float-line'></span>
                    <label className='e-float-text'>Specialist</label>
                </div>
            </Col>
        </Row>
    );

    const VendorInfo = () => (
        <div className='infoStepsMainWrap'>
            <div
                className='mb-2'
                style={{ padding: '10px' }}
            >
                <label className='e-label-select mb-1'>
                    <b>Vendor ID(EZCAP)</b>
                </label>
                <DropDownListComponent
                    id='Vendor_ID'
                    {...register('vendorId', { required: 'Vendor ID is required' })}
                    allowFiltering={true}
                    popupHeight='220px'
                    filtering={onFiltering}
                    sortOrder={sortOrder}
                    dataSource={[{ vendorId: getValues().vendorId }, ...vendors]}
                    fields={fields}
                    query={query}
                    placeholder='Select Vendor ID'
                    value={getValues().vendorId}
                    change={({ value }) => {
                        fetchVendorDetails(value);
                    }}
                />
                {errors.vendorId && <span className='error-form'>{errors.vendorId.message}</span>}
            </div>
            <p className='section-container-header'>Vendor Info</p>
            <div className='accordion-content'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='labelInputWrap'>
                            <label className='customLabel'>
                                <b className='fontWeightNormal'>Display Name</b>
                            </label>
                            <input
                                type='text'
                                {...register('ipaDesc')}
                                className='customInputField lblCls'
                                readOnly={true}
                            />
                        </div>

                        <div className='labelInputWrap'>
                            <label className='customLabel'>
                                <b className='fontWeightNormal'>Vendor Name</b>
                            </label>
                            <input
                                type='text'
                                {...register('vendorNm')}
                                className='customInputField lblCls'
                                readOnly={true}
                            />
                        </div>

                        <div className='labelInputWrap'>
                            <label className='customLabel'>
                                <b className='fontWeightNormal'>Address</b>
                            </label>
                            <input
                                type='text'
                                {...register('addr')}
                                className='customInputField lblCls'
                                maxLength={80}
                                readOnly={true}
                            />
                        </div>

                        <div className='labelInputWrap'>
                            <label className='customLabel'>
                                <b className='fontWeightNormal'>Suite/Bldg/No</b>
                            </label>
                            <input
                                type='text'
                                {...register('street2')}
                                className='customInputField lblCls'
                                readOnly={true}
                            />
                        </div>

                        <div className='labelInputWrap'>
                            <label className='customLabel'>
                                <b className='fontWeightNormal'>City</b>
                            </label>
                            <input
                                type='text'
                                {...register('city')}
                                className='customInputField lblCls'
                                maxLength={50}
                                readOnly={true}
                            />
                        </div>
                        <div className='labelInputWrap'>
                            <label className='customLabel'>
                                <b className='fontWeightNormal'>State</b>
                            </label>
                            <input
                                type='text'
                                {...register('state')}
                                className='customInputField lblCls'
                                maxLength={4}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='labelInputWrap'>
                            <label className='customLabel'>
                                <b className='fontWeightNormal'>Zip Code</b>
                            </label>
                            <input
                                type='text'
                                {...register('zip')}
                                className='customInputField lblCls'
                                maxLength={20}
                                readOnly={true}
                            />
                        </div>
                        <div className='labelInputWrap'>
                            <label className='customLabel'>
                                <b className='fontWeightNormal'>Fax</b>
                            </label>
                            <input
                                type='text'
                                {...register('fax')}
                                className='customInputField lblCls'
                                readOnly={true}
                            />
                        </div>
                        <div className='labelInputWrap'>
                            <div className='customLabel'>
                                <b className='fontWeightNormal'>Phone</b>
                            </div>
                            <input
                                type='text'
                                {...register('phone')}
                                className='customInputField lblCls'
                                readOnly={true}
                            />
                        </div>

                        <div className='labelInputWrap'>
                            <label className='customLabel'>
                                <b className='fontWeightNormal'>Email</b>
                            </label>
                            <input
                                readOnly={true}
                                type='text'
                                {...register('email')}
                                className='customInputField lblCls'
                            />
                        </div>

                        <div className='labelInputWrap'>
                            <label className='customLabel'>
                                <b className='fontWeightNormal'>Contract Name</b>
                            </label>
                            <input
                                className='customInputField lblCls'
                                type='text'
                                {...register('contractName')}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const ContractMcoInfo = () => (
        <div className='infoStepsMainWrap'>
            <div className='infoStepsWrap'>
                <p className='section-container-header'>Contract Info</p>
                <ContractInfo />
            </div>

            <div className='infoStepsWrap'>
                <p className='section-container-header'>MSO Info</p>
                <MsoDetails />
            </div>
        </div>
    );
    const IpaDetailsInfo = useCallback(
        () => (
            <div className='infoStepsMainWrap'>
                <IpaInfo />
            </div>
        ),
        [errors],
    );

    const MemberIDProviderInfo = useCallback(
        () => (
            <div className='infoStepsMainWrap'>
                <div className='infoStepsWrap'>
                    <p className='section-container-header'>Member Provider Info</p>
                    <MemberProviderInfo />
                </div>

                <div className='infoStepsWrap'>
                    <p className='section-container-header'>AHC/Network Management Point of contact</p>
                    <AhcPoi />
                </div>
                {loading && <Loading />}
            </div>
        ),
        [errors],
    );

    const stepTitles = ['Contract & MSO Info', 'Vendor Info', 'IPA Details Info', 'Member ID & Provider Info'];
    const stepsElements = [
        <ContractMcoInfo key={3} />,
        <VendorInfo key={2} />,
        <IpaDetailsInfo key={1} />,
        <MemberIDProviderInfo key={4} />,
    ];
    const { currentStep, step, isFirstStep, isLastStep, next, back, goTo } = useMultiStepsForm(stepsElements);
    const stepsData = stepTitles.map((title, index) => ({ title: title, value: index }));

    const setStepsVisit = (stepIndex: number) => {
        if (stepIndex != 0) {
            setVisitedSteps((prevStep: number[]) => {
                if (!prevStep.includes(stepIndex)) {
                    return [...prevStep, stepIndex];
                }
                return prevStep;
            });
        }
    };

    const saveContractInformation = async (data: IPA, e) => {
        const selectedStep = stepsData.filter((f) => f.value == parseInt(e.nativeEvent.submitter.id));
        if (selectedStep.length > 0) {
            if (
                (visitedSteps.length >= selectedStep[0]?.value || visitedSteps.length === stepTitles.length) &&
                (selectedStep[0]?.value <= currentStep + 1 || visitedSteps.length === stepTitles.length)
            ) {
                goTo(selectedStep[0]?.value);
                setStepsVisit(selectedStep[0]?.value);
            }
        } else if (!isLastStep) {
            next();
            setStepsVisit(currentStep + 1);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else if (data) {
            setLoading(true);
            try {
                let action = 'AddNewIpa';
                const effDt = new Date(data.effDate);
                const endDt = new Date(data.endDate);
                data.effDate = `${effDt.getMonth() + 1}/${effDt.getDate()}/${effDt.getFullYear()}`;
                data.endDate = `${endDt.getMonth() + 1}/${endDt.getDate()}/${endDt.getFullYear()}`;
                const adaptedData = {
                    ...data,
                };
                for (const d in adaptedData) {
                    if (d.includes('mso')) {
                        delete adaptedData[d];
                    }
                }
                if (ipa.id && !isCopyToNew) {
                    action = 'UpdateIpa';
                    adaptedData.id = ipa.id;
                }
                adaptedData.termed = false;
                const url = `/api/MedicalGroup/${action}`;
                await requests.post(url, adaptedData);
                showSuccessToast(`IPA ${ipa.id ? 'Updated' : 'Created'} Successfully`);
                dialogService.close();
                refreshTabService.performAction('refresh');
                setLoading(false);
            } catch (e) {
                console.error(e);
                const message = `${(e as AxiosError)?.response?.data}`;
                showErrorToast(message);
                setLoading(false);
            }
        }
    };
    const cleanPhoneNumber = (phoneNumber) => phoneNumber.replace(/[()\- ]/g, '');

    useEffect(() => {
        setLoading(true);
        const controller = new AbortController();
        Promise.all([fetchCompanyCodes(), fetchRisks(), fetchTypes(), fetchVendors()]).finally(() => {
            setLoading(false);
        });
        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        if (ipaId && getValues('contractLevelId')) {
            fetchMso(getValues('contractLevelId'));
        }
    }, [getValues('contractLevelId')]);

    useEffect(() => {
        if (companyCodes.length && risks.length && types.length) {
            if (ipaId) {
                requests.get(`/api/MedicalGroup/GetDetailByIpaId?ipaId=${ipaId}`).then((_ipa: any) => {
                    if (_ipa) {
                        setIpa(_ipa);
                        for (const field in _ipa) {
                            setValue(field as keyof IPA, _ipa[field]);
                        }
                        setValue('npi', _ipa.vendorNpi);
                        setValue('effDate', _ipa.effDate);
                        setValue('endDate', _ipa.endDate);
                        setValue('ipaPhone', cleanPhoneNumber(_ipa.ipaPhone));
                        setValue('ipaPhone2', cleanPhoneNumber(_ipa.ipaPhone2));
                        fetchContracts(_ipa.companyCode);
                        setLoading(false);
                    }
                });
            } else {
                setValue('effDate', moment(new Date()).format());
                setValue('endDate', moment(new Date(9999, 11, 31)).format());
                setLoading(false);
            }
        }
    }, [companyCodes, risks, types, ipaId]);
    const Loading = () => (
        <div>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'lightgray',
                    opacity: '0.1',
                    position: 'absolute',
                    zIndex: '99999',
                }}
            ></div>
            <div
                className='spinner-border'
                style={{
                    position: 'absolute',
                    top: '30vh',
                    left: 'calc(50% - 20px)',
                    height: '50px',
                    width: '50px',
                    color: 'rgb(227, 22, 91)',
                }}
            ></div>
        </div>
    );
    return (
        <>
            <form
                className='mainFormWrapper'
                onSubmit={handleSubmit(saveContractInformation)}
            >
                {!loading ? (
                    <>
                        <div className='stepsWrapper'>
                            {stepsData &&
                                stepsData.map((item, i) => (
                                    <div
                                        className={`${
                                            i === currentStep
                                                ? 'arrowBox-active arrowActiveright '
                                                : 'arrowBox-Inactive arrow-right'
                                        } arrowBox`}
                                        key={i}
                                    >
                                        <input
                                            className='customStepsBtn'
                                            type='submit'
                                            id={i.toString()}
                                            value={item.title}
                                        />
                                        <input
                                            className='customStepsBtn'
                                            formNoValidate={true}
                                            type='button'
                                            id={i.toString()}
                                            value={item.title}
                                            onClick={() => {
                                                goTo(i);
                                            }}
                                        />
                                    </div>
                                ))}
                        </div>

                        <div> {step} </div>

                        <div
                            className='buttonsWrapper'
                            style={{ paddingBottom: '10px' }}
                        >
                            {!isFirstStep && (
                                <input
                                    type='button'
                                    className='btn btn-primary btn-flat btn-sm mx-1'
                                    value='Back'
                                    formNoValidate={true}
                                    onClick={back}
                                />
                            )}
                            <input
                                type='submit'
                                className='btn btn-primary btn-flat btn-sm mx-1'
                                value={isLastStep ? 'Save' : 'Next'}
                                disabled={isLastStep && loading}
                            />
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </form>
        </>
    );
};

export const CreateIpaMember = (ipaId, isCopyToNew) => {
    const dialog: Dialog = {
        header: 'Create New',
        content: () => (
            <div
                className={'col'}
                style={{ height: '460px' }}
            >
                <IpaAdministrationWizard
                    ipaId={ipaId}
                    isCopyToNew={isCopyToNew ?? false}
                />
            </div>
        ),
        width: '80%',
        height: '80%',
    };
    dialogService.open(dialog);
};
