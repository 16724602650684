import { Observable, Subject } from 'rxjs';

import { IpaPlan } from 'components/ProviderData/IpaAdministration/atoms/Models';
interface IPaPlanResponse {
    error: boolean;
    message?: string;
}
const selectedIpaPlanSubject = new Subject<IpaPlan>();
const IpaPlanSubject = new Subject<IPaPlanResponse | void>();

const ipaPlanService = {
    select: (ooa: IpaPlan) => selectedIpaPlanSubject.next(ooa),
    get$: (): Observable<IpaPlan> => selectedIpaPlanSubject.asObservable(),
    submit: (validation: IPaPlanResponse | void) => IpaPlanSubject.next(validation),
    submit$: (): Observable<IPaPlanResponse | void> => IpaPlanSubject.asObservable(),
};

export { ipaPlanService };
