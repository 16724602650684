import { useEffect, useRef, useState } from 'react';

import { ColumnChooser, GridComponent, Inject, Selection, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';

import { requests } from 'common/axios';
import { formatUTCDate } from 'common/widgets';
import { getItems } from 'components/AvaEzm/Utilities/ODataUtility';
import {
    AdditionalContactGridColumns,
    tabsToolbarOptions,
} from 'components/ProviderData/IpaAdministration/atoms/IpaAdministration.Metadata';
import { AdditionalContact } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { AdditionalContactForm } from 'components/ProviderData/IpaAdministration/organisms/AdditionalContactForm';
import { additionalContactService } from 'components/ProviderData/subjects/IpaAdditionalContactService';
import { dialogService } from 'subjects/common/DialogService';

type AdditionalContactProps = {
    id: number;
    isReadOnlyViewer: boolean;
    risk: string;
};

const AdditionalContactTab = (props: AdditionalContactProps) => {
    const [departments, setDepartments] = useState<any[]>([]);
    const toastRef = useRef<ToastComponent>(null);
    const contactGridRef = useRef<GridComponent>(null);
    const [contact, setContact] = useState<AdditionalContact | null>();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [additionalContacts, setAdditionalContacts] = useState<any>([]);

    const fetchDepartments = async () => {
        const _departments = await getItems<string>('/api/AdditionalContact/GetDepartments?status=A');
        setDepartments(_departments);
    };

    useEffect(() => {
        fetchDepartments();
        updateDataSource();
        additionalContactService.get$().subscribe((contact) => setContact(contact));
        const subscription = additionalContactService.submit$().subscribe((response) => {
            if (response) {
                if (response.error) {
                    toastRef.current?.show({
                        title: 'Error',
                        content: response.message,
                        cssClass: 'e-toast-danger',
                    });
                } else {
                    toastRef.current?.show({
                        title: 'Success !',
                        content: response.message,
                        cssClass: 'e-toast-success',
                    });
                    updateDataSource();
                }
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    function handleFormDirtyChange(isDirty) {
        setIsDirty(isDirty);
    }

    const saveContactsButtons = [
        {
            buttonModel: {
                disabled: props.isReadOnlyViewer,
                content: 'Save',
                iconCss: 'e-icons e-save-icon',
                cssClass: 'e-submit',
            },
            click: () => {
                additionalContactService.submit();
            },
        },
        {
            buttonModel: {
                disabled: props.isReadOnlyViewer,
                content: 'Cancel',
                iconCss: 'e-icons e-cancel-icon',
                cssClass: 'e-cancel',
            },
            click: () => {
                dialogService.close();
                setContact(null);
            },
        },
    ];

    const updateDataSource = () => {
        const searchContactsUrl = `/api/AdditionalContact/SearchAdditionalContacts?ipaWebId=${props.id}`;

        requests.get<any>(searchContactsUrl).then((data) => {
            const formattedData = data.map((d) => ({
                ...d,
                createdDt: formatUTCDate(d.createdDt),
                updatedDt: formatUTCDate(d.updatedDt),
            }));
            setAdditionalContacts(formattedData);
        });
    };

    const toolbarHandler = (args: any) => {
        if (args.item.id === 'refresh') {
            updateDataSource();
        }
        if (args.item.id === 'create') {
            additionalContactService?.select({} as AdditionalContact);
        }
    };

    useEffect(() => {
        if (contactGridRef && props.isReadOnlyViewer) {
            // Disable toolbar items.
            contactGridRef.current?.toolbarModule.enableItems(['create'], false);
        }
        if (contact) {
            const dialog: any = {};
            dialog.header = 'Save Additional Contact';
            dialog.buttons = saveContactsButtons;
            dialog.content = () => (
                <AdditionalContactForm
                    contact={contact}
                    departments={departments}
                    id={props.id}
                    onFormDirtyChange={handleFormDirtyChange}
                    isReadOnlyViewer={props.isReadOnlyViewer}
                    risk={props.risk}
                />
            );
            dialog.width = 800;
            dialog.height = 550;
            dialogService.open(dialog);
            setContact(null);
        }
    }, [contact, isDirty]);

    return (
        <div className='tab-content tabDiv p-3'>
            <div className='grid-container'>
                <GridComponent
                    id='searchGridRef'
                    height={450}
                    allowPaging={true}
                    allowSorting={true}
                    columns={AdditionalContactGridColumns}
                    dataSource={additionalContacts}
                    toolbar={tabsToolbarOptions as any}
                    recordClick={(data) => {
                        additionalContactService?.select(data.rowData);
                    }}
                    ref={contactGridRef}
                    toolbarClick={toolbarHandler}
                    showColumnChooser={true}
                >
                    <Inject services={[Selection, Sort, Toolbar, ColumnChooser]} />
                </GridComponent>
            </div>
            <ToastComponent
                id='toast_target'
                ref={toastRef}
                title='Login Account'
                content=''
                position={{ X: 'Right', Y: 'Top' }}
            />
        </div>
    );
};

export default AdditionalContactTab;
