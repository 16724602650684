import VideoParticipant from './VideoParticipant/VideoParticipant';

export const getMainParticipant = (participants, memberConnectionId, room, setEnableAudio, memberPhone) => {
    if (participants.length > 0) {
        const member = participants.find(
            (participant) =>
                participant.identity === memberConnectionId || participant.identity === (memberPhone || '').trim(),
        );
        if (member) {
            return (
                <VideoParticipant
                    key={member.sid}
                    participant={member}
                    room={room}
                    setEnableAudio={setEnableAudio}
                />
            );
        }
    }
    return participants.length === 0 ? <div className='alert-waiting'>Waiting for member to join...</div> : <></>;
};
