import { useEffect, useState } from 'react';

import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { AxiosError } from 'axios';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { requests } from 'common/axios';
import { ControlledSyncfusionDatePicker } from 'components/Common/SyncfusionWrappers/Syncfusion';
import { IpaNetworkMapping } from 'components/ProviderData/IpaAdministration/atoms/Models';
import {
    NetworkMappingResponse,
    IpaNetworkMappingService,
} from 'components/ProviderData/subjects/IpaNetworkMappingService';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import { dialogService } from 'subjects/common/DialogService';

export const IpaNetworkMappingForm = ({ network, ipa, onFormDirtyChange, isReadOnlyViewer }) => {
    const {
        control,
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors, isDirty },
        watch,
        trigger,
    } = useForm<any>();

    const [startDate, setStartDate] = useState<Date>();
    const [networkMapDetail, setNetworkMapDetail] = useState<IpaNetworkMapping>();
    const [contractCodeList, setContractCodeList] = useState();

    const getNetworkMappingDetail = (id: string) => {
        const searchNetworkMappingUrl = `/api/IpaNetworkMapping/GetNetworkMappingDetail?id=${id}`;
        requests.get<any>(searchNetworkMappingUrl).then((data) => {
            setNetworkMapDetail(data);
        });
    };

    const getContractCodeList = () => {
        const getContractCodeUrl = `/api/IpaNetworkMapping/GetNetworkMappingContractList`;
        requests.get<any>(getContractCodeUrl).then((data) => {
            console.log('getContractCodeList', data);
            setContractCodeList(data);
        });
    };

    const saveInformation = async (data: IpaNetworkMapping) => {
        try {
            dialogService.loading(true);
            const adaptedData = {
                ...data,
                ipaCode: ipa.ipaCode,
            };

            if (network?.id) {
                adaptedData.id = network?.id;
                const response = (await requests.put('/api/IpaNetworkMapping/UpdateNetworkMapping', {
                    data: { ...network, ...networkMapDetail, ...adaptedData },
                })) as NetworkMappingResponse;
                IpaNetworkMappingService.submit(response);
            } else {
                const response = (await requests.post('/api/IpaNetworkMapping/CreateNetworkMapping', {
                    data: adaptedData,
                })) as NetworkMappingResponse;
                IpaNetworkMappingService.submit(response);
            }
        } catch (e) {
            const message = `${(e as AxiosError)?.response?.data}`;
            IpaNetworkMappingService.submit({ error: true, message });
        } finally {
            dialogService.loading(false);
            dialogService.close();
        }
    };

    const PcpData = [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
    ];
    useEffect(() => {
        getContractCodeList();
        if (network.id) {
            getNetworkMappingDetail(network.id);
        } else {
            reset();
        }
    }, []);

    useEffect(() => {
        const subscription = IpaNetworkMappingService.submit$().subscribe((validation) => {
            if (validation === undefined) {
                handleSubmit(saveInformation)();
            }
        });
        if (network.id) {
            for (const c in getValues()) {
                setValue(c, network[c]);
            }
        } else {
            reset();
        }
        return () => {
            subscription.unsubscribe();
        };
    }, [network]);

    useEffect(() => {
        onFormDirtyChange(isDirty);
    }, [watch]);

    return (
        <DisabledWrapper disabled={isReadOnlyViewer}>
            <form>
                <Row className='mb-3'>
                    {network?.networkMapId && (
                        <Col md='6'>
                            <div>
                                <div className={`e-float-input`}>
                                    <label>Network Mapping ID</label>
                                    <br />
                                    <label>{network?.networkMapId}</label>
                                </div>
                            </div>
                        </Col>
                    )}
                    <Col md='6'>
                        <div>
                            <div className={`e-float-input`}>
                                <label>IPA Code</label>
                                <br />
                                <label>{ipa.ipaCode}</label>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className='mb-3'>
                    <Col md='6'>
                        <div className='no-padding'>
                            <label className='e-label-select mb-1'>Start Date *</label>
                            <ControlledSyncfusionDatePicker
                                format='MM/dd/yyyy'
                                name='effectiveStartDate'
                                control={control}
                                dpAttrs={{
                                    showClearButton: true,
                                    placeholder: 'Start date',
                                    min: undefined,
                                    max: new Date(9999, 11, 31),
                                    strictMode: true,
                                    enableMask: true,
                                }}
                                isRetrievingDateAsString={true}
                                rules={{
                                    required: 'Start date is required',
                                }}
                                onChangeHandler={({ valueAsDate }: { valueAsDate: Date }) => {
                                    setStartDate(valueAsDate);
                                }}
                            />
                            {errors.effectiveStartDate && (
                                <p className='error-form'>{errors.effectiveStartDate.message}</p>
                            )}
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='no-padding'>
                            <label className='e-label-select mb-1'>End Date *</label>
                            <ControlledSyncfusionDatePicker
                                name='effectiveEndDate'
                                format='MM/dd/yyyy'
                                control={control}
                                dpAttrs={{
                                    showClearButton: true,
                                    placeholder: 'End date',
                                    min: startDate,
                                    max: new Date(9999, 11, 31),
                                    strictMode: true,
                                    enableMask: true,
                                }}
                                isRetrievingDateAsString={true}
                                rules={{
                                    required: 'End date is required',
                                }}
                            />
                            {errors.effectiveEndDate && <p className='error-form'>{errors.effectiveEndDate.message}</p>}
                        </div>
                    </Col>
                </Row>

                <Row className='mb-3'>
                    <Col
                        className='field-col'
                        md={6}
                    >
                        <div className='e-input-group-margin'>
                            <label className='e-label-select mb-1'>Contract Code *</label>
                            <DropDownListComponent
                                id='contractCode'
                                {...register('contractCode', {
                                    required: 'Contract Code is required',
                                })}
                                value={contractCodeList && getValues('contractCode')}
                                dataSource={contractCodeList}
                                fields={{ text: 'text', value: 'id' }}
                                placeholder='Contract Code'
                                change={({ value }) => {
                                    setValue('contractCode', value);
                                }}
                                popupHeight='220px'
                            />
                            {errors.contractCode && <p className='error-form'>Contract Code is Required</p>}
                        </div>
                    </Col>
                    <Col
                        className='field-col'
                        md={6}
                    >
                        <div className='e-input-group-margin'>
                            <label className='e-label-select mb-1'>PCP</label>
                            <DropDownListComponent
                                id='isPcp'
                                {...register('isPcp')}
                                dataSource={PcpData}
                                fields={{ text: 'label', value: 'value' }}
                                placeholder='Select Yes/No'
                                change={({ value }) => {
                                    setValue('isPcp', value);
                                }}
                                popupHeight='220px'
                            />
                        </div>
                    </Col>
                </Row>

                <Row className='mb-3'>
                    <Col md='4'>
                        <div className={`e-float-input e-input-group`}>
                            <input
                                type='number'
                                {...register('tin')}
                                onChange={(obj) => {
                                    setValue('tin', obj.currentTarget.value);
                                }}
                            />
                            <span className='e-float-line'></span>
                            <label className='e-float-text'>Tin</label>
                        </div>
                    </Col>
                    <Col md='4'>
                        <div className={`e-float-input e-input-group`}>
                            <input
                                type='text'
                                {...register('class')}
                                onChange={(obj) => {
                                    setValue('class', obj.currentTarget.value);
                                }}
                            />
                            <span className='e-float-line'></span>
                            <label className='e-float-text'>Class</label>
                        </div>
                    </Col>
                    <Col md='4'>
                        <div className={`e-float-input e-input-group`}>
                            <input
                                type='text'
                                {...register('specialty')}
                                onChange={(obj) => {
                                    setValue('specialty', obj.currentTarget.value);
                                }}
                            />
                            <span className='e-float-line'></span>
                            <label className='e-float-text'>Specialty</label>
                        </div>
                    </Col>
                </Row>

                <Row className='mb-3'>
                    <Col md='4'>
                        <div>
                            <div className={`e-float-input e-input-group`}>
                                <input
                                    type='text'
                                    {...register('networkId', {
                                        validate: (value) => {
                                            if (value === '') {
                                                return 'Network ID is Required';
                                            }
                                        },
                                    })}
                                    onChange={(obj) => {
                                        setValue('networkId', obj.currentTarget.value);
                                        trigger(['networkId']);
                                    }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>Network ID *</label>
                            </div>
                            {errors.networkId && <p className='error-form'>Network ID is Required</p>}
                        </div>
                    </Col>
                    <Col md='4'>
                        <div>
                            <div className={`e-float-input e-input-group`}>
                                <input
                                    type='text'
                                    {...register('networkPrefix', {
                                        validate: (value) => {
                                            if (value === '') {
                                                return 'Network Prefix is Required';
                                            }
                                        },
                                    })}
                                    onChange={(obj) => {
                                        setValue('networkPrefix', obj.currentTarget.value);
                                        trigger(['networkPrefix']);
                                    }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>Network Prefix *</label>
                            </div>
                            {errors.networkPrefix && <p className='error-form'>Network Prefix is Required</p>}
                        </div>
                    </Col>
                    <Col md='4'>
                        <div>
                            <div className={`e-float-input e-input-group`}>
                                <input
                                    type='text'
                                    {...register('agreementId', {
                                        validate: (value) => {
                                            if (value === '') {
                                                return 'Agreement ID is Required';
                                            }
                                        },
                                    })}
                                    onChange={(obj) => {
                                        setValue('agreementId', obj.currentTarget.value);
                                        trigger(['agreementId']);
                                    }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>Agreement ID *</label>
                            </div>
                            {errors.agreementId && <p className='error-form'>Agreement ID is Required</p>}
                        </div>
                    </Col>
                </Row>

                <Row className='mb-2'>
                    <label className='fw-bold'>IPA:</label>
                </Row>
                <Row>
                    <Col md='4'>
                        <div>
                            <div className={`e-float-input e-input-group`}>
                                <input
                                    type='text'
                                    {...register('ipaNetworkId', {
                                        validate: (value) => {
                                            if (value === '') {
                                                return 'Network ID is Required';
                                            }
                                        },
                                    })}
                                    onChange={(obj) => {
                                        setValue('ipaNetworkId', obj.currentTarget.value);
                                        trigger(['ipaNetworkId']);
                                    }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>Network ID *</label>
                            </div>
                            {errors.ipaNetworkId && <p className='error-form'>Network ID is Required</p>}
                        </div>
                    </Col>
                    <Col md='4'>
                        <div>
                            <div className={`e-float-input e-input-group`}>
                                <input
                                    type='text'
                                    {...register('ipaNetworkPrefix', {
                                        validate: (value) => {
                                            if (value === '') {
                                                return 'Network Prefix is Required';
                                            }
                                        },
                                    })}
                                    onChange={(obj) => {
                                        setValue('ipaNetworkPrefix', obj.currentTarget.value);
                                        trigger(['ipaNetworkPrefix']);
                                    }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>Network Prefix *</label>
                            </div>
                            {errors.ipaNetworkPrefix && <p className='error-form'>Network Prefix is Required</p>}
                        </div>
                    </Col>
                    <Col md='4'>
                        <div>
                            <div className={`e-float-input e-input-group`}>
                                <input
                                    type='text'
                                    {...register('dofrAgreementId', {
                                        validate: (value) => {
                                            if (value === '') {
                                                return 'Agreement ID is Required';
                                            }
                                        },
                                    })}
                                    onChange={(obj) => {
                                        setValue('dofrAgreementId', obj.currentTarget.value);
                                        trigger(['dofrAgreementId']);
                                    }}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>Agreement ID *</label>
                            </div>
                            {errors.dofrAgreementId && <p className='error-form'>Agreement ID is Required</p>}
                        </div>
                    </Col>
                </Row>
            </form>
        </DisabledWrapper>
    );
};
