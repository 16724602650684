import { ColumnModel, PageSettingsModel } from '@syncfusion/ej2-react-grids';

import { DataType } from 'components/ProviderData/DataImportUtility/Model';
export const DataTypes: DataType[] = [
    {
        text: 'Additional Contact',
        value: 0,
    },
    {
        text: 'Facility',
        value: 1,
    },
    {
        text: 'Out Of Area',
        value: 2,
    },
    {
        text: 'PBP Map',
        value: 3,
    },
    {
        text: 'Cap Rates',
        value: 4,
    },
];

export const ProviderNetworkDataTypes: DataType[] = [
    {
        text: 'Additional Contact',
        value: 0,
    },
    {
        text: 'Gain Share Rates',
        value: 1,
    },
    {
        text: 'Risk Pool Rates',
        value: 2,
    },
];

export const dataImportHistoryColumns: ColumnModel[] = [
    {
        field: 'fileName',
        width: 300,
        headerText: 'File Name',
        type: 'string',
        autoFit: true,
    },
    {
        field: 'importType',
        width: 200,
        headerText: 'Import Type',
        type: 'string',
        autoFit: true,
    },
    {
        field: 'numberOfRecords',
        width: 100,
        headerText: 'Number Of Records',
        type: 'string',
        autoFit: true,
    },
    {
        field: 'uploadedStatus',
        width: 140,
        headerText: 'Data Import Status',
        type: 'string',
        autoFit: true,
    },
    {
        field: 'importNotes',
        width: 250,
        headerText: 'Import Notes',
        type: 'string',
        autoFit: true,
    },
    {
        field: 'download',
        width: 250,
        headerText: 'Download',
        autoFit: true,
        disableHtmlEncode: false,
    },
    {
        field: 'uploadedBy',
        width: 200,
        headerText: 'Uploaded By',
        type: 'string',
        autoFit: true,
    },
    {
        field: 'uploadedOn',
        width: 140,
        headerText: 'Date Uploaded',
        type: 'date',
        format: 'MM/dd/yyyy',
        autoFit: true,
    },
];

export const dataImportHistoryPageSettings: PageSettingsModel = { pageSize: 50 };
