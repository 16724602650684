import { BehaviorSubject, Observable, merge, Subject, combineLatest, timer, connectable, Connectable } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';

import { talkdeskConstants as constants, talkdeskValidator as validator } from '../../components/Common/TalkdeskUtils';

const talkdeskCtiMessages = new BehaviorSubject<MessageEvent>({} as MessageEvent);
const userSessionMessages = new BehaviorSubject<MessageEvent>({} as MessageEvent);
const iframeToggle$ = new Subject<void>();

// Authenticates the current user using its externalId.
// AVA users have an externalId in Talkdesk because Talkdesk
// consumes this endpoint once a day: AHC.CommandCenter.Controllers.CRM.Talkdesk
// The CTI is not usable when:
// - The externalId is not passed to the CTI
// - The externalId is passed but Talkdesk hasn't updated the agent's data
const externalIdMessages: Observable<MessageEvent<any>> = talkdeskCtiMessages.pipe(
    filter((e) => validator.isTalkdeskMessage(e, constants.authenticationMessage)),
    debounceTime(2500), // to let the CurrentUserService warm up
);

// Show iframe to authorize usage
const showMessages$: Observable<boolean> = talkdeskCtiMessages.pipe(
    filter((e) => validator.isTalkdeskMessage(e, 'show')),
    map((_) => true),
);

// Hide iframe after authorization
const hideMessages$: Observable<boolean> = talkdeskCtiMessages.pipe(
    filter((e) => validator.isTalkdeskMessage(e, 'hide')),
    map((_) => false),
);

const loggedInMessages$: Observable<boolean> = userSessionMessages.pipe(
    filter((e) => validator.isTalkdeskMessage(e, 'login') || validator.isTalkdeskMessage(e, 'logout')),
    map((e) => validator.isTalkdeskMessage(e, 'login')),
    startWith(false),
);

const loginReminders$: Connectable<boolean> = connectable(
    combineLatest([loggedInMessages$, timer(5000, 60000)]).pipe(map(([isLoggedIn, _]) => isLoggedIn)),
);

const talkdeskCtiService = {
    /**
     * A behavior subject which will multicast the CTI's MessageEvents (show, hide, authenticate)
     */
    ctiMessages: talkdeskCtiMessages,
    /**
     * A behavior subject which will multicast user session events (login, logut)
     * */
    userSessionMessages,
    /**
     * An observable for "show" and "hide" events. If the agent has already logged into
     * Talkdesk in the browser, the CTI will hide automatically.
     * Otherwise it will stay open and prompt the user to log in.
     * @returns A boolean indicating whether to show the CTI's iframe or not
     */
    iframeVisibilityMessages$: (): Observable<boolean> => merge(showMessages$, hideMessages$),
    /**
     * An observable for toggling the iframe visibility.
     */
    iframeToggleMessages$: (): Observable<void> => iframeToggle$,
    /**
     * The Talkdesk.tsx component handles the CTI's authentication requests.
     * You should not need to subscribe to these events.
     * @returns An observable with the CTI's authentication requests
     */
    externalIdMessages$: (): Observable<MessageEvent<any>> => externalIdMessages,
    /**
     * True if the user session is active, emits an event every minute.
     * */
    loginReminders$,
    initLoginReminders: () => loginReminders$.connect(),
};

/*
MODULES USSING THIS SUBJECT:
    - CRM
    - Talkdesk
*/
export { talkdeskCtiService };
