import React, { createRef } from 'react';

import { push } from '@lagunovsky/redux-react-router';
import { SidebarComponent, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import './NavMenu.css';

import { withRouter } from 'hoc/withRouter';
import { navMenuItemClickedService, tabsToValidateService } from 'subjects/common/NavMenuClickHandlerService';

import { IconSelector } from '../../icons/svgIcons';
import { userNavItems$ } from '../../subjects/common/UserNavItems';

const switchModuleSubject = new Subject();
export { switchModuleSubject };
class NavMenu extends React.Component {
    state = {
        search: '',
        visibleModules: [],
    };
    constructor(props) {
        super(props);

        this.isOpen = true;
        this.width = '230px';
        this.mediaQuery = '(min-width: 600px)';
        this.dockSize = '44px';
        this.url = '';
        this.onCreate = this.onCreate.bind(this);
        this.onClose = this.onClose.bind(this);
        this.nodeTemplate = this.nodeTemplate.bind(this);
        this.onNodeClicked = this.onNodeClicked.bind(this);
        this.nodeIdLookup = {};
        this.fields = {
            dataSource: [],
            modules: [],
            id: 'nodeId',
            text: 'nodeText',
            child: 'nodeChild',
        };
        this.onChangeDebounced = debounce(this.onChangeDebounced, 250);
        this.inputRef = createRef();
    }

    updateSideBar = () => {
        if (this.isOpen) {
            this.sidebarobj.show();
            document.querySelector('.e-content-animation').style.marginLeft = '230px';
        } else {
            this.sidebarobj.hide();
            this.treeviewobj.collapseAll();
        }
    };

    handleInputChange = (e) => {
        this.setState({ search: e.target.value });
        this.onChangeDebounced(e);
    };

    onChangeDebounced = () => {
        this.searchModules();
    };

    sortNodes(nodes) {
        nodes.sort((a, b) => {
            return a.nodeText.toLowerCase().localeCompare(b.nodeText.toLowerCase());
        });
    }

    fillTreeObject(initial) {
        this.setState({ visibleModules: [] });
        let parentNodeId;
        this.navItemsSubscription = userNavItems$.pipe(filter((items) => items && items.length)).subscribe({
            next: (items) => {
                this.sortNodes(items);
                const avaHome = items.find((node) => node.nodeId === '01');
                items = items.filter((node) => node.nodeId !== '01');
                items.unshift(avaHome);

                this.fields.dataSource = items;
                this.treeviewobj.fields.dataSource = items;

                const navItems = items.map((item) => ({
                    node: item,
                    parentNodeId: null,
                }));
                while (navItems.length) {
                    const item = navItems.pop();
                    if (item.node.nodeChild) {
                        item.node.nodeChild.forEach((child) => {
                            navItems.push({ node: child, parentNodeId: item.node.nodeId });
                        });
                    } else {
                        const nodeId = item.node.nodeId;

                        const url = item.node.url;
                        this.nodeIdLookup[url] = { nodeId, parentNodeId };
                        if (url === this.props.router.location.pathname) {
                            parentNodeId = item.parentNodeId;
                            if (parentNodeId) {
                                this.treeviewobj.expandedNodes = [parentNodeId];
                            }
                            this.treeviewobj.selectedNodes = [nodeId];
                        }
                    }
                    if (initial) {
                        this.fields.modules.push(item.node);
                    }
                }
                this.treeviewobj.dataBind();
                this.treeviewobj.refresh();
                if (!this.state.search) {
                    this.treeviewobj.collapseAll();
                }
                this.treeviewobj.expandAll([parentNodeId]);
            },
            error: console.error,
        });
    }
    componentDidMount() {
        this.fillTreeObject(true);
        this.sidebarToggleSubscription = sidebarToggleClicked$.subscribe({
            next: (args) => {
                if (args) {
                    this.sidebarobj.show();
                    this.isOpen = true;
                    document.querySelector('.e-content-animation').style.marginLeft = '230px';
                } else {
                    this.sidebarobj.hide();
                    this.isOpen = false;
                    this.treeviewobj.collapseAll();
                }
            },
        });
        window.addEventListener('resize', this.updateSideBar);
    }
    searchModules() {
        this.fillTreeObject();
        const search = this.state.search.toLocaleLowerCase();
        if (!search) {
            return;
        }
        let nodesToOpen = [];
        const nodesToDelete = [];
        const visibleModules = [];
        for (const module of this.fields.modules) {
            const { nodeId } = module;
            const currentNode = this.treeviewobj.getNode(nodeId);
            const parent = this.treeviewobj.getNode(currentNode.parentID);
            const parentToOpen = parent.parentID ? parent.parentID : currentNode.parentID;

            if (currentNode.text.toLocaleLowerCase().includes(search)) {
                if (currentNode.hasChildren) {
                    const childrenIds = module.nodeChild.map((c) => c.nodeId);
                    nodesToOpen = nodesToOpen.concat(childrenIds);
                    nodesToOpen.push(currentNode.id);
                    if (currentNode.parentID) {
                        nodesToOpen.push(currentNode.parentID);
                    }
                } else {
                    nodesToOpen.push(parentToOpen, currentNode.parentID);
                }
                visibleModules.push(nodeId);
            } else {
                nodesToDelete.push(nodeId, parentToOpen, currentNode.parentID);
            }
        }
        this.treeviewobj.removeNodes(nodesToDelete.filter((n) => !nodesToOpen.includes(n)));
        this.treeviewobj.dataBind();
        this.treeviewobj.expandAll();
        this.setState({ visibleModules });
    }

    shouldComponentUpdate(nextProps, nextState) {
        const pathName = nextProps.router.location?.pathname;
        if (pathName && this.pathName !== pathName) {
            this.pathName = pathName;
            switchModuleSubject.next();
        }
        const nodeId = nextProps?.router?.location?.state?.nodeId;
        const childNodeId = nextProps?.router?.location?.state?.childNodeId;
        if (childNodeId?.split('.').length === 1) {
            const node = this.treeviewobj.fields.dataSource.filter((item) => item.nodeId === nodeId);
            let firstChildNodeId = '';
            if (node[0]?.nodeChild) {
                firstChildNodeId = node[0].nodeChild[0].nodeId;
            }
            this.treeviewobj.selectedNodes = [firstChildNodeId];
        } else {
            this.treeviewobj.selectedNodes = [childNodeId];
        }
        this.treeviewobj.expandAll([nodeId]);
        const visibleModules = [];
        const navItems = this.treeviewobj.rootData.map((item) => ({
            node: item,
            parentNodeId: null,
        }));
        while (navItems.length) {
            const item = navItems.pop();
            if (item.node.nodeChild) {
                item.node.nodeChild.forEach((child) => {
                    navItems.push({ node: child, parentNodeId: item.node.nodeId });
                });
            } else {
                visibleModules.push(item.node.nodeId);
            }
        }
        const needsUpdate = nextState.visibleModules.length && !isEqual(visibleModules, nextState.visibleModules);
        if (needsUpdate) {
            this.treeviewobj.render();
        }
        return needsUpdate;
    }

    componentWillUnmount() {
        this.navItemsSubscription.unsubscribe();
        this.sidebarToggleSubscription.unsubscribe();
        window.removeEventListener('resize', this.updateSideBar);
    }

    onCreate() {
        this.sidebarobj.element.style.visibility = '';
    }

    onClose() {
        this.treeviewobj.collapseAll();
    }

    nodeTemplate(data) {
        return (
            <div data-url={data.url}>
                <i className='icon-sm'>{IconSelector(data.iconKey)}</i> {data.nodeText}
            </div>
        );
    }

    goToNode(url, nodeId, childNodeId) {
        if (this.fields.modules.find((m) => m.url === url)?.openInNewTab) {
            window.open(url, '_blank');
            return;
        }
        this.props.push(url, { nodeId, childNodeId });
        tabsToValidateService.clear();
    }

    onNodeClicked(args) {
        const domElement = args.node;
        const url = (args.event.target.dataset || {}).url;
        const nodeId = domElement.getAttribute('data-uid').split('.')[0];
        const childNodeId = domElement.getAttribute('data-uid');
        if (url) {
            if (tabsToValidateService.getCount() > 0) {
                navMenuItemClickedService.select({
                    retryNavigation: () => this.goToNode(url, nodeId, childNodeId),
                });
            } else this.goToNode(url, nodeId, childNodeId);
        }
    }

    nodeSelecting = (args) => {
        if (args.nodeData.parentID === null) {
            this.treeviewobj.collapseAll();
        }
    };

    render() {
        return (
            <SidebarComponent
                id='sidebar-treeview'
                ref={(Sidebar) => (this.sidebarobj = Sidebar)}
                width={this.width}
                mediaQuery={this.mediaQuery}
                created={this.onCreate}
                close={this.onClose}
                dockSize={this.dockSize}
                enableDock={true}
                enableGestures={false}
                style={{ visibility: 'hidden' }}
                fields={this.fields}
            >
                <div className='main-menu'>
                    <div style={{ paddingLeft: 1 }}>
                        <div
                            className='e-input-group'
                            style={{ backgroundColor: 'white', marginTop: 10 }}
                        >
                            <input
                                className='e-input'
                                type='text'
                                placeholder='Search Module'
                                onChange={this.handleInputChange}
                                style={{ backgroundColor: 'white' }}
                                ref={this.inputRef}
                            />
                            <span
                                className={`e-input-group-icon e-icons ${
                                    this.state.search ? 'e-cancel-icon' : 'e-search-icon'
                                }`}
                                title='Search'
                                aria-label='search'
                                onClick={() => {
                                    this.setState({ search: '' });
                                    this.inputRef.current.value = '';
                                    this.fillTreeObject();
                                }}
                            ></span>
                        </div>
                        <TreeViewComponent
                            id='main-treeview'
                            ref={(Treeview) => (this.treeviewobj = Treeview)}
                            fields={{
                                dataSource: [],
                                id: 'nodeId',
                                text: 'nodeText',
                                child: 'nodeChild',
                            }}
                            expandOn='Click'
                            nodeTemplate={this.nodeTemplate}
                            nodeClicked={this.onNodeClicked}
                            nodeExpanding={this.nodeSelecting}
                            nodeSelecting={this.nodeSelecting}
                            loadOnDemand={false}
                        />
                    </div>
                </div>
            </SidebarComponent>
        );
    }
}
export default connect(null, { push })(withRouter(NavMenu));

const sidebarToggleClicked$ = new Subject();

export { sidebarToggleClicked$ };
