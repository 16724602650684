import { DatePickerModel } from '@syncfusion/ej2-react-calendars';
import { ToolbarItem, ColumnModel } from '@syncfusion/ej2-react-grids';
import { ItemModel } from '@syncfusion/ej2-react-navigations';

import { DOFRContract, DOFRAssignment } from 'components/ProviderData/IpaAdministration/atoms/Models';

export const DOFR_CONTRACT_TAB_ID = 'dofr-contract-tab';

export const BASE_URI = '/api/DOFRContract';

export type SubmitServiceMapDialogForm = {
    submitForm?: () => void;
    resetForm?: () => void;
};

const _getFormButtons = ({
    onSubmitClick,
    onCancelClick,
}: {
    onSubmitClick: () => void;
    onCancelClick: () => void;
}) => [
    {
        buttonModel: {
            content: 'Cancel',
            cssClass: 'e-cancel',
        },
        click: onCancelClick,
    },
    {
        buttonModel: {
            content: 'Submit',
            cssClass: 'e-submit',
        },
        click: onSubmitClick,
    },
];

export const dofrContractForm = {
    datePickerAttrs: { strictMode: true, placeholder: 'MM/DD/YYYY' } as DatePickerModel,
    getButtons: _getFormButtons,
};
export const dofrContractsColumns: (ColumnModel & { field: keyof DOFRContract })[] = [
    {
        field: 'effectiveBeginDate',
        headerText: 'Start Date',
        type: 'DateTime',
        format: 'MM/dd/yyyy',
    },
    {
        field: 'effectiveEndDate',
        headerText: 'End Date',
        type: 'DateTime',
        format: 'MM/dd/yyyy',
    },
    {
        field: 'templateName',
        headerText: 'Template',
    },
    {
        field: 'createdById',
        headerText: 'Created By',
    },
    {
        field: 'createdAt',
        headerText: 'Created On',
        type: 'DateTime',
        format: 'MM/dd/yyyy',
    },
    {
        field: 'lastUpdatedById',
        headerText: 'Updated By',
    },
    {
        field: 'lastUpdatedAt',
        headerText: 'Updated On',
        type: 'DateTime',
        format: 'MM/dd/yyyy',
    },
];
export enum DOFRContractsToolbarIds {
    New = 'create-dofr-contract',
    Edit = 'edit-dofr-contract',
    Delete = 'delete-dofr-contract',
    Close = 'close-dofr-contract',
}
export const dofrContractsToolbar: (ToolbarItem | ItemModel | string)[] = [
    { id: DOFRContractsToolbarIds.New, text: 'New' },
    { id: DOFRContractsToolbarIds.Edit, text: 'Edit' },
    { id: DOFRContractsToolbarIds.Delete, text: 'Delete' },
    {
        id: DOFRContractsToolbarIds.Close,
        template: `<button id="${DOFRContractsToolbarIds.Close}" class="e-btn avaButton text-capitalize">Cancel</button>`,
        align: 'Right',
    },
];

export const dofrAssignmentForm = {
    datePickerAttrs: { strictMode: true, placeholder: 'MM/DD/YYYY' } as DatePickerModel,
    getButtons: _getFormButtons,
};
export enum DOFRAssignmentsToolbarIds {
    New = 'create-dofr-assignment',
    Edit = 'edit-dofr-assignment',
    Delete = 'delete-dofr-assignment',
}
export const dofrAssignmentsToolbar: (ToolbarItem | ItemModel | string)[] = [
    { id: DOFRAssignmentsToolbarIds.New, text: 'New' },
    { id: DOFRAssignmentsToolbarIds.Edit, text: 'Edit' },
    { id: DOFRAssignmentsToolbarIds.Delete, text: 'Delete' },
    'Search',
    'ColumnChooser',
];
const booleanToText = (row, index) => (row[index] ? 'Yes' : 'No');
export const dofrAssignmentsColumns: (ColumnModel & { field: keyof DOFRAssignment })[] = [
    {
        field: 'category',
        width: 250,
        headerText: 'Category',
        clipMode: 'EllipsisWithTooltip',
        autoFit: true,
    },
    {
        field: 'subCategory',
        width: 200,
        headerText: 'Sub Category',
        clipMode: 'EllipsisWithTooltip',
        autoFit: true,
    },
    {
        field: 'isHospitalLiability',
        width: 150,
        headerText: 'Hospital Risk',
        template: (row) => booleanToText(row, 'isHospitalLiability'),
        autoFit: true,
    },
    {
        field: 'isSharedLiability',
        width: 150,
        headerText: 'Shared Risk',
        template: (row) => booleanToText(row, 'isSharedLiability'),
        autoFit: true,
    },
    {
        field: 'isIpaLiability',
        width: 150,
        headerText: 'IPA Risk',
        template: (row) => booleanToText(row, 'isIpaLiability'),
        autoFit: true,
    },
    {
        field: 'isOurLiability',
        width: 150,
        headerText: 'AHC Risk',
        template: (row) => booleanToText(row, 'isOurLiability'),
        autoFit: true,
    },
    {
        field: 'link',
        width: 200,
        headerText: 'Link',
        visible: false,
        autoFit: true,
    },
    {
        field: 'comment',
        width: 300,
        headerText: 'Comment',
        visible: false,
        autoFit: true,
    },
    {
        field: 'createdById',
        headerText: 'Created By',
        visible: false,
        autoFit: true,
    },
    {
        field: 'createdAt',
        headerText: 'Created On',
        type: 'DateTime',
        format: 'MM/dd/yyyy hh:mm:ss',
        visible: false,
        autoFit: true,
    },
    {
        field: 'lastUpdatedById',
        headerText: 'Updated By',
        width: 200,
        autoFit: true,
    },
    {
        field: 'lastUpdatedAt',
        headerText: 'Updated On',
        type: 'DateTime',
        format: 'MM/dd/yyyy hh:mm:ss',
        width: 200,
        autoFit: true,
    },
];
