import './CustomDialog.scss';

const CustomDialog = (props) => {
    return (
        <div className='formula_form_popup_overlay'>
            <div className='formula_form_popup_content'> {props.children}</div>
        </div>
    );
};
export default CustomDialog;
