import { useEffect, useRef, useState, useCallback } from 'react';

import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent, MaskedDateTime, Inject } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, ListBoxComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { AxiosError } from 'axios';
import { Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

import { requests } from 'components/AvaEzm/AxiosApi';
import { getItems } from 'components/AvaEzm/Utilities/ODataUtility';
import {
    defaultValuesCapSpecialRates,
    scbPremiumComponentValues,
} from 'components/ProviderData/IpaAdministration/atoms/IpaAdministration.Metadata';
import { CapRateDetail, CapSpecialRate, IPADetail } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { fillWithZeros } from 'components/ProviderData/IpaAdministration/atoms/utils';
import { submitCapRateService } from 'components/ProviderData/subjects/IpaAdministrationService';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import { dialogService } from 'subjects/common/DialogService';

import CustomDialog from '../CustomDialog';

type CapSpecialRateProps = {
    ipaDetails: IPADetail;
    capRateId: number;
    capSpecialRateId: number;
    dates: {
        effDate: Date;
        endDate: Date;
    };
    isReadOnlyViewer: boolean;
};

export const CapSpecialRateForm = (props: CapSpecialRateProps) => {
    const { ipaDetails, capRateId, capSpecialRateId, dates, isReadOnlyViewer } = props;
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        control,
    } = useForm<any>();
    const toastRef = useRef<ToastComponent>(null);
    const [effDate, setEffDate] = useState();
    const [capSpecialRate, setCapSpecialRate] = useState<CapSpecialRate>();
    const [companyCodes, setCompanyCodes] = useState<string[]>([]);
    const [contractCodes, setContractCodes] = useState<string[]>([]);
    const [sequence, setSequence] = useState<string>('');
    const [countyList, setCountyList] = useState<string[]>([]);
    const [pbpList, setPbpList] = useState<string[]>([]);
    const [types, setTypes] = useState<any[]>([]);
    const [scbSelectedFormulaField, setSelectedScbFormulaField] = useState<string>('');
    const [selectedComponent, setSelectedComponent] = useState<string>('');
    const [showDialog, setshowDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>();

    const fetchCapSpecialRateDetail = async (id) => {
        if (id && id != capSpecialRate?.id) {
            const _capSpecialRateDetails: CapSpecialRate = await requests.get<CapSpecialRate>(
                `/api/CapSpecialRate/GetCapSpecialRateDetail?id=${id}`,
            );
            setCapSpecialRate(_capSpecialRateDetails);
            setSequence(_capSpecialRateDetails?.seq?.toString() ?? '');
            const fields = getValues();
            for (const field in fields) {
                if (fields[field] !== undefined) {
                    const value = _capSpecialRateDetails[field];
                    const decimals = `${defaultValuesCapSpecialRates[field]}`.split('.')[1];
                    if (typeof value === 'number' && decimals) {
                        setValue(
                            field as keyof CapSpecialRate,
                            value ? fillWithZeros(value, decimals.length) : defaultValuesCapSpecialRates[field],
                        );
                    } else {
                        setValue(field as keyof CapSpecialRate, value);
                    }
                }
            }
            setValue('effDate', _capSpecialRateDetails?.effDate);
            setValue('endDate', _capSpecialRateDetails?.endDate);
        } else {
            resetForm();
        }
    };

    const fetchCompanyCodes = async () => {
        const _companyCodes = await getItems<string>('/api/MedicalGroup/GetCompanyCodes');
        setCompanyCodes(_companyCodes);
    };

    const fetchTypes = async () => {
        let _types: any = [];
        _types = await getItems<string>(`/api/MedicalGroup/GetIpaTypes`);
        setTypes(_types);
    };

    const fetchContractCodes = async (companyCode: string) => {
        const response = await requests.get<string[]>(`/api/IpaPlan/GetContractCodes?companyCode=${companyCode}`);
        setContractCodes(response);
    };

    const fetchCounties = async (companyCode: string) => {
        const _counties = await requests.get<string[]>(
            `/api/CapSpecialRate/GetCountiesByCompanyCode?companyCode=${companyCode}`,
        );
        setCountyList(_counties);
    };

    const fetchPbpCodes = async (contractCode: string) => {
        const response = await requests.get<string[]>(
            `/api/IpaPlan/GetPBPCodes?companyCode=${ipaDetails?.companyCode}&contractCode=${contractCode}`,
        );
        setPbpList(response);
    };

    const resetForm = () => {
        setValue('id', 0);
        reset();
        for (const field in defaultValuesCapSpecialRates) {
            setValue(field as keyof CapSpecialRate, defaultValuesCapSpecialRates[field]);
        }

        setSequence('');
        setValue('companyCode', ipaDetails?.companyCode);
        setValue('type', ipaDetails?.type);
        setValue('effDate', dates?.effDate);
        setValue('endDate', dates?.endDate);
    };

    const saveInformation = async (data: CapSpecialRate) => {
        try {
            const url = `/api/CapSpecialRate/`;
            let action = 'AddNewCapSpecialRate';
            const formattedData = {
                ...data,
                id: capSpecialRateId,
                ipaCode: ipaDetails.ipaCode,
                pbp: data?.pbps ? data?.pbps.join(',') : '',
                countyDescription: data?.countyDescs ? data?.countyDescs.join(',') : '',
                ipaCapId: !capSpecialRateId ? capRateId : capSpecialRate?.ipaCapId,
                createDate: new Date(),
                updateDt: new Date(),
            };
            if (formattedData.id > 0) {
                action = 'UpdateCapSpecialRate';
            }
            await requests.post(`${url}${action}`, formattedData);
            submitCapRateService.submit();
            dialogService.close();
        } catch (e) {
            const message = `${(e as AxiosError)?.response?.data}`;
            toastRef.current?.show({
                title: 'Error',
                content: message,
                cssClass: 'e-toast-danger',
            });
        }
    };

    const ControlledMaskInput = useCallback(
        ({ name, decimals, label }: any) => {
            let regex = /^[0-9]*$/;
            const defaultValue = `0.${'0'.padEnd(decimals, '0')}`;
            if (decimals === 4) {
                regex = /^[0-9]*\.[0-9]{4}$/;
            } else if (decimals === 2) {
                regex = /^[0-9]*\.[0-9][0-9]$/;
            }
            return (
                <Controller
                    render={() => (
                        <div>
                            <div className='e-float-input e-input-group'>
                                <input
                                    {...register(name, { pattern: regex })}
                                    id={name}
                                    onBlur={({ target }) => {
                                        if (decimals && !regex.test(target.value)) {
                                            const defaultDecimals = `.${'0'.padEnd(decimals, '0')}`;
                                            let defaultIntegers = '0';
                                            const valueFilled = fillWithZeros(target.value, decimals);
                                            const valueSplitted = target.value ? `${target.value}`.split('.') : '';
                                            if (valueSplitted[1] && valueSplitted[1].length > decimals) {
                                                if (decimals == 2) {
                                                    defaultIntegers = '0000';
                                                }
                                                setError(name, {
                                                    type: 'custom',
                                                    message: `Format ${defaultIntegers}${defaultDecimals}`,
                                                });
                                            } else if (valueSplitted && valueFilled) {
                                                setValue(name as keyof CapRateDetail, valueFilled);
                                                clearErrors(name);
                                            }
                                        } else if (decimals == 0) {
                                            const valueSplitted = target.value ? `${target.value}`.split('.') : '';
                                            if (valueSplitted[1] && valueSplitted[1].length > 0) {
                                                setError(name, {
                                                    type: 'custom',
                                                    message: `Integer only`,
                                                });
                                            } else {
                                                setValue(name as keyof CapRateDetail, target.value);
                                                clearErrors(name);
                                            }
                                        } else {
                                            clearErrors(name);
                                        }
                                    }}
                                    placeholder={decimals ? defaultValue : '0'}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>{label}</label>
                            </div>
                            {errors[name] && <p className='error-form'> {errors[name].message}</p>}
                        </div>
                    )}
                    control={control}
                    name={name}
                />
            );
        },
        [errors],
    );

    const openFormulaDialog = async (fieldName: string) => {
        setValue('formula', getValues(fieldName));
        setSelectedScbFormulaField(fieldName);
        setshowDialog(true);
    };

    const onHandleSelectedFormula = async (fieldName: string, action: string) => {
        if (action == 'Save') {
            console.log('Formula value: ', getValues('formula'));
            setValue(fieldName, getValues('formula'));
            console.log(fieldName, getValues(fieldName));
        }

        setValue('formula', '');
        console.log('save formula and hide the form');
        setshowDialog(false);
    };

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        Promise.all([fetchCompanyCodes(), fetchContractCodes(ipaDetails?.companyCode), fetchTypes()]).finally(() => {
            fetchCapSpecialRateDetail(capSpecialRateId);
            setLoading(false);
        });
        return () => {
            controller.abort();
        };
    }, []);

    const deleteCapSpecialRate = async (id: string) => {
        try {
            const url = `/api/CapSpecialRate/DeleteCapSpecialRateDetail?id=${id}`;

            await requests.delete(url);
            submitCapRateService.deleteCapSpecialRate(id);

            toastRef.current?.show({
                title: 'Success !',
                content: `Special Rate (Id: ${id}) was deleted Successfully`,
                cssClass: 'e-toast-success',
            });
            dialogService.close();
        } catch (e) {
            const message = `${(e as AxiosError)?.response?.data}`;
            toastRef.current?.show({
                title: 'Error',
                content: message,
                cssClass: 'e-toast-danger',
            });
        }
    };

    const deleteRecordConfirmation = (id) => {
        const dialog: any = {};
        dialog.header = `Delete Cap Special Rate (Id: ${id})`;
        dialog.buttons = confirmButtons;
        dialog.content = () => <p>{`Do you want to delete this cap special rate (Id: ${id})?`}</p>;
        dialog.width = 400;
        dialogService.open(dialog);
    };

    const onHandleSelection = (selectedItem: string[]) => {
        setSelectedComponent(selectedItem[0]);
    };

    const onCreateFormula = async () => {
        const composedFormula = getValues('formula') + selectedComponent;
        setValue('formula', composedFormula);
    };

    const onFormulaChange = (event) => {
        console.log(event.target.value);
        setValue('formula', event.target.value);
    };

    const confirmButtons = [
        {
            buttonModel: {
                content: 'Yes',
                iconCss: '',
                cssClass: 'e-submit',
            },
            click: () => {
                deleteCapSpecialRate(String(capSpecialRateId ?? ''));
                dialogService.close();
            },
        },
        {
            buttonModel: {
                content: 'No',
                iconCss: 'e-icons e-cancel-icon',
                cssClass: 'e-cancel',
            },
            click: () => {
                dialogService.close();
            },
        },
    ];

    return (
        <DisabledWrapper disabled={isReadOnlyViewer}>
            <div
                className='tab-content'
                style={{ border: '1px solid #c8c8c8', padding: '10px', position: 'relative' }}
            >
                {!loading ? (
                    <>
                        <div className='mb-4'>
                            <h4>
                                <b>IPA Cap Special Rates for Cap Rate ({capSpecialRate?.ipaCapId ?? capRateId})</b>
                            </h4>
                        </div>
                        <Row>
                            <Col md={12}>
                                <form
                                    id='cap_SpecialRate_Form'
                                    onSubmit={handleSubmit(saveInformation)}
                                >
                                    <Row className='center-fields mb-2'>
                                        <Col md={3}>
                                            <label className='e-label-select mb-2'>Company Code</label>
                                            <DropDownListComponent
                                                id='companyCode'
                                                {...register('companyCode', {
                                                    required: 'Company Code is required',
                                                })}
                                                dataSource={companyCodes}
                                                placeholder='Select a Company Code'
                                                value={getValues().companyCode}
                                                change={({ value }) => {
                                                    fetchCounties(value);
                                                }}
                                                enabled={false}
                                                fields={{
                                                    text: 'companyName',
                                                    value: 'companyCode',
                                                }}
                                                popupHeight='220px'
                                            />
                                            {errors.companyCode && (
                                                <p className='error-form'>{errors.companyCode.message}</p>
                                            )}
                                        </Col>
                                        <Col md={2}>
                                            <div className='e-float-input e-input-group'>
                                                <input
                                                    className='e-disabled'
                                                    type='text'
                                                    {...register('ipaCode')}
                                                    value={ipaDetails?.ipaCode}
                                                />
                                                <span className='e-float-line'></span>
                                                <label className='e-float-text'>IPA Code</label>
                                            </div>
                                        </Col>
                                        <Col md={1}>
                                            <div className='e-float-input e-input-group'>
                                                <input
                                                    className='e-disabled'
                                                    type='text'
                                                    {...register('seq')}
                                                    value={sequence}
                                                />
                                                <span className='e-float-line'></span>
                                                <label className='e-float-text'>Sequence</label>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='e-float-input e-input-group'>
                                                <input
                                                    className='e-disabled'
                                                    type='text'
                                                    {...register('ipaDescription')}
                                                    value={ipaDetails?.ipaDesc}
                                                />
                                                <span className='e-float-line'></span>
                                                <label className='e-float-text'>IPA Description</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='center-fields mb-2'>
                                        <Col md={3}>
                                            <label className='e-label-select mb-2'>Contract Code *</label>
                                            <DropDownListComponent
                                                id='contractCode'
                                                dataSource={contractCodes}
                                                value={getValues().contractCode}
                                                placeholder='Select a Contract Code'
                                                change={({ value }) => {
                                                    setValue('contractCode', value);
                                                    if (!capSpecialRateId) fetchCounties(ipaDetails?.companyCode);
                                                    fetchPbpCodes(value);
                                                }}
                                                popupHeight='220px'
                                                {...register('contractCode', {
                                                    required: 'Contract Code is required',
                                                })}
                                            />
                                            {errors.contractCode && (
                                                <p className='error-form'>{errors.contractCode.message}</p>
                                            )}
                                        </Col>
                                        <Col md={2}>
                                            <label className='e-label-select mb-2'>Type</label>
                                            <DropDownListComponent
                                                id='type'
                                                {...register('type')}
                                                dataSource={types}
                                                value={getValues().type}
                                                enabled={false}
                                                popupHeight='220px'
                                            />
                                            {errors.companyCode && (
                                                <p className='error-form'>{errors.companyCode.message}</p>
                                            )}
                                        </Col>
                                        <Col md={1}>
                                            <label className='e-label-select mb-2'>Status</label>
                                            <DropDownListComponent
                                                id='status'
                                                {...register('status')}
                                                dataSource={['A', 'I']}
                                                enabled={false}
                                                popupHeight='220px'
                                            />
                                            {errors.status && <p className='error-form'> {errors.status.message}</p>}
                                        </Col>
                                        <Col md={6}>
                                            <label className='e-label-select mb-2'>PBP Code</label>
                                            <MultiSelectComponent
                                                id='pbps'
                                                dataSource={pbpList}
                                                value={
                                                    capSpecialRate?.pbp
                                                        ? capSpecialRate.pbp.split(',').filter((c) => c)
                                                        : []
                                                }
                                                mode='Default'
                                                placeholder='Select PBP Code'
                                                change={({ value }) => {
                                                    setValue('pbps', value);
                                                }}
                                                {...register('pbps')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='center-fields mb-2'>
                                        <Col md={12}>
                                            <label className='e-label-select mb-2'>County Code</label>
                                            <MultiSelectComponent
                                                id='countyDescs'
                                                dataSource={countyList}
                                                value={
                                                    capSpecialRate?.countyDescription
                                                        ? capSpecialRate.countyDescription.split(',').filter((c) => c)
                                                        : []
                                                }
                                                mode='Default'
                                                placeholder='Select County'
                                                change={({ value }) => {
                                                    setValue('countyDescs', value);
                                                }}
                                                {...register('countyDescs')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='center-fields mb-2'>
                                        <Col md={3}>
                                            <div>
                                                <label className='e-label-select'>Effective Date</label>
                                                <DatePickerComponent
                                                    {...register('effDate', { required: 'Effective Date is required' })}
                                                    id='effDate'
                                                    change={({ value }) => {
                                                        setValue('effDate', value);
                                                        setEffDate(value);
                                                    }}
                                                    format='MM/dd/yyyy'
                                                    min={dates?.effDate}
                                                    max={dates?.endDate}
                                                    enableMask={true}
                                                    strictMode
                                                    disabled
                                                >
                                                    <Inject services={[MaskedDateTime]} />
                                                </DatePickerComponent>
                                            </div>
                                            {errors.effDate && <p className='error-form'> {errors.effDate.message}</p>}
                                        </Col>
                                        <Col md={3}>
                                            <div>
                                                <label className='e-label-select'>End Date</label>
                                                <DatePickerComponent
                                                    {...register('endDate', { required: 'End Date is required' })}
                                                    id='endDate'
                                                    change={({ value }) => setValue('endDate', value)}
                                                    format='MM/dd/yyyy'
                                                    min={effDate}
                                                    max={dates?.endDate}
                                                    enableMask={true}
                                                    strictMode
                                                    disabled
                                                >
                                                    <Inject services={[MaskedDateTime]} />
                                                </DatePickerComponent>
                                                {errors.endDate && (
                                                    <p className='error-form'> {errors.endDate.message}</p>
                                                )}
                                            </div>
                                        </Col>

                                        <Col md={3}></Col>
                                        <Col md={3}></Col>
                                    </Row>
                                    <Row className='center-fields mb-2'>
                                        <Col md={3}>
                                            <ControlledMaskInput
                                                label='PREMIUM PCT'
                                                name='premiumPCT'
                                                decimals={4}
                                                value={capSpecialRate?.premiumPCT}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <ControlledMaskInput
                                                label='MediMedi PCT'
                                                name='mediMediPCT'
                                                decimals={4}
                                                value={capSpecialRate?.mediMediPCT}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <ControlledMaskInput
                                                label='PMPM Amt'
                                                name='pmPmAmt'
                                                decimals={2}
                                                value={capSpecialRate?.pmPmAmt}
                                            />
                                        </Col>
                                        <Col md={3}></Col>
                                    </Row>
                                    <Row className='center-fields mb-2'>
                                        <Col md={3}>
                                            <ControlledMaskInput
                                                label='ESRD PMPM'
                                                name='esrdPmPm'
                                                decimals={2}
                                                value={capSpecialRate?.esrdPmPm}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <ControlledMaskInput
                                                name='esrdPmPmMm'
                                                label='ESRD PMPM (MM)'
                                                decimals={2}
                                                value={capSpecialRate?.esrdPmPmMm}
                                            />
                                        </Col>

                                        <Col md={3}>
                                            <ControlledMaskInput
                                                label='ESRD POP'
                                                name='esrdPop'
                                                decimals={4}
                                                value={capSpecialRate?.esrdPop}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <ControlledMaskInput
                                                label='ESRD POP(MM)'
                                                name='esrdPopMm'
                                                decimals={4}
                                                value={capSpecialRate?.esrdPopMm}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='center-fields mb-2'>
                                        <Col md={3}>
                                            <ControlledMaskInput
                                                label='VIRTUAL PMPM'
                                                name='virtualPmPm'
                                                decimals={2}
                                                value={capSpecialRate?.virtualPmPm}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <ControlledMaskInput
                                                label='VIRTUAL PMPM (MM)'
                                                name='virtualPmPmMm'
                                                decimals={2}
                                                value={capSpecialRate?.virtualPmPmMm}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <ControlledMaskInput
                                                label='VALUE BASED PMPM'
                                                name='valueBasedPmPm'
                                                decimals={2}
                                                value={capSpecialRate?.valueBasedPmPm}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <ControlledMaskInput
                                                label='VALUE BASED PMPM (MM)'
                                                name='valueBasedPmPmMm'
                                                decimals={2}
                                                value={capSpecialRate?.valueBasedPmPmMm}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='center-fields mb-2'>
                                        <Col md={3}>
                                            <CheckBoxComponent
                                                label='SCB Amount Applicable?'
                                                id='scbAmtApplicable'
                                                checked={capSpecialRate?.scbAmtApplicable}
                                                {...register('scbAmtApplicable')}
                                                change={({ checked }) => {
                                                    setValue('scbAmtApplicable', checked);
                                                    if (!checked) {
                                                        setValue('scbEsrdStatus', null);
                                                        setValue('scbFunction', null);
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <label className='e-label-select mb-2'>SCB ESRD Status</label>
                                            <DropDownListComponent
                                                id='active'
                                                {...register('scbEsrdStatus')}
                                                value={
                                                    capSpecialRate?.scbEsrdStatus
                                                        ? capSpecialRate?.scbEsrdStatus.trim()
                                                        : ''
                                                }
                                                dataSource={['Y', 'N']}
                                                placeholder='- Select -'
                                                popupHeight='220px'
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <label className='e-label-select mb-2'>SCB Function</label>
                                            <DropDownListComponent
                                                id='scbFunction'
                                                {...register('scbFunction')}
                                                value={capSpecialRate?.scbFunction}
                                                dataSource={['Maximum']}
                                                placeholder='- Select -'
                                                popupHeight='220px'
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <ControlledMaskInput
                                                label='SCB Limit Value'
                                                name='scbLimitValue'
                                                decimals={0}
                                                value={capSpecialRate?.scbLimitValue}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='center-fields mb-2'>
                                        <Col md={5}>
                                            <div className='e-float-input e-input-group'>
                                                <textarea
                                                    {...register('scbPremiumComponent')}
                                                    rows={2}
                                                />
                                                <span className='e-float-line'></span>
                                                <label className='e-float-text'>SCB Premium Components</label>
                                            </div>
                                        </Col>
                                        <Col md={1}>
                                            <button
                                                className={'btn btn-primary'}
                                                type={'button'}
                                                onClick={() => {
                                                    const fieldName = 'scbPremiumComponent';
                                                    openFormulaDialog(fieldName);
                                                }}
                                            >
                                                <i className='fa fa-search'></i>
                                            </button>
                                        </Col>
                                        <Col md={5}>
                                            <div className='e-float-input e-input-group'>
                                                <textarea
                                                    {...register('scbPremiumFormula')}
                                                    rows={2}
                                                />
                                                <span className='e-float-line'></span>
                                                <label className='e-float-text'>SCB Formula</label>
                                            </div>
                                        </Col>
                                        <Col md={1}>
                                            <button
                                                className={'btn btn-primary'}
                                                type={'button'}
                                                onClick={() => {
                                                    const fieldName = 'scbPremiumFormula';
                                                    openFormulaDialog(fieldName);
                                                }}
                                            >
                                                <i className='fa fa-search'></i>
                                            </button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className='e-float-input e-input-group'>
                                                <textarea
                                                    {...register('notes')}
                                                    rows={3}
                                                />
                                                <span className='e-float-line'></span>
                                                <label className='e-float-text'>Notes</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}></Col>
                                        <Col md={6}>
                                            <div style={{ width: '100%', textAlign: 'right', marginTop: '24px' }}>
                                                <button
                                                    type='button'
                                                    onClick={() => submitCapRateService.cancel()}
                                                    className='btn btn-secondary btn-flat'
                                                    style={{ maxWidth: 120, marginLeft: 10 }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary btn-flat'
                                                    style={{ maxWidth: 120, marginLeft: 10 }}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    type='button'
                                                    hidden={!capSpecialRateId}
                                                    onClick={() => deleteRecordConfirmation(capSpecialRateId)}
                                                    className='btn btn-danger btn-flat'
                                                    style={{ maxWidth: 120, marginLeft: 10 }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                        <ToastComponent
                            id='toast_target'
                            ref={toastRef}
                            title=''
                            content=''
                            position={{ X: 'Right', Y: 'Top' }}
                        />
                        <div>
                            {showDialog && (
                                <CustomDialog>
                                    <div className='custom-dialog-header'>Create Formula</div>
                                    <div className='custom-dialog-content'>
                                        <Row className='center-fields mb-2'>
                                            <Col md={5}>
                                                <ListBoxComponent
                                                    dataSource={scbPremiumComponentValues}
                                                    onChange={({ value }) => {
                                                        onHandleSelection(value);
                                                    }}
                                                />
                                            </Col>
                                            <Col md={1}>
                                                <button
                                                    className='e-submit'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onCreateFormula();
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                                                </button>
                                            </Col>
                                            <Col md={6}>
                                                <div className='e-float-input e-input-group'>
                                                    <textarea
                                                        {...register('formula')}
                                                        rows={3}
                                                        onBlur={onFormulaChange}
                                                    />
                                                    <span className='e-float-line'></span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}></Col>
                                            <Col md={6}>
                                                <div style={{ width: '100%', textAlign: 'right', marginTop: '24px' }}>
                                                    <button
                                                        type='button'
                                                        onClick={() => {
                                                            onHandleSelectedFormula(scbSelectedFormulaField, 'Cancel');
                                                        }}
                                                        className='btn btn-danger btn-flat'
                                                        style={{ maxWidth: 120, marginLeft: 10 }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-flat'
                                                        onClick={() => {
                                                            onHandleSelectedFormula(scbSelectedFormulaField, 'Save');
                                                        }}
                                                        style={{ maxWidth: 120, marginLeft: 10 }}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </CustomDialog>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            style={{
                                height: '600px',
                                width: '790px',
                            }}
                        >
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    backgroundColor: 'lightgray',
                                    opacity: '0.1',
                                    position: 'absolute',
                                    zIndex: '99999',
                                }}
                            ></div>
                            <div
                                className='spinner-border'
                                style={{
                                    position: 'absolute',
                                    top: '30vh',
                                    left: 'calc(50% - 20px)',
                                    height: '50px',
                                    width: '50px',
                                    color: 'rgb(227, 22, 91)',
                                }}
                            ></div>
                        </div>
                    </>
                )}
            </div>
        </DisabledWrapper>
    );
};
