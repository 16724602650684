import 'components/ProviderData/utils/DisabledWrapper.scss';
import { Spinner } from 'react-bootstrap';

const DisabledWrapper = ({ children, disabled = true, withSpinner = false }) => (
    <div className={disabled ? 'disabled-wrapper' : ''}>
        <div>{children}</div>
        <div className='overlay' />
        {withSpinner && (
            <div className='position-absolute top-0 start-0 d-flex justify-content-center align-items-center w-100 h-100'>
                <Spinner
                    animation='border'
                    variant='danger'
                />
            </div>
        )}
    </div>
);

export default DisabledWrapper;
