import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import PropTypes from 'prop-types';
import { Col, Row, Modal } from 'reactstrap';

import { ajaxPost } from 'common/ajax';

import BaseComponent from '../../../../common/BaseComponent';
import { notificationService } from '../../../../subjects/common/NotificationService';
import { SectionHeader } from '../../../UI/BoxSection';
import styles from './EligibleDialog.module.css';

class EligibleDialog extends BaseComponent {
    static displayName = EligibleDialog.name;

    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onAssign: PropTypes.func.isRequired,
    };

    state = {
        isFetchingProviders: false,
        providers: [],
        formNotReady: true,
        isAssigningProvider: false,
    };

    subscriptions = [];

    componentDidMount() {
        this.fetchProviders();
    }

    fetchProviders() {
        this.setState({ isFetchingProviders: true });

        const res$ = ajaxPost('api/graph', {
            Query: "g.V().hasLabel('Provider').project('id', 'name').by('id').by('name')",
        });

        this.subscriptions.push(
            res$.subscribe(
                (ajaxRes) => {
                    this.setState({
                        isFetchingProviders: false,
                        providers: this.mapResToSelect(ajaxRes.response),
                    });
                },
                () => {
                    this.setState({
                        isFetchingProviders: false,
                    });
                    notificationService.error({
                        title: 'Failed Operation',
                        content: 'Ocurred fetching members',
                    });
                },
            ),
        );
    }

    mapResToSelect(res) {
        return res.map(({ id, name }) => {
            return { text: name, value: id };
        });
    }

    handleClose = () => {
        const { isAssigningProvider } = this.state;
        const { onClose } = this.props;

        if (isAssigningProvider) {
            return;
        }

        onClose();
    };

    handleProviderChange = ({ itemData }) => {
        if (itemData) {
            this.setState({ formNotReady: false, selectedProvider: itemData });
        } else {
            this.setState({ formNotReady: true, selectedProvider: null });
        }
    };

    createAssignment = () => {
        const { selectedProvider } = this.state;
        const { members, onAssign } = this.props;

        const memberIds = members.map((member) => member.memberId);

        this.setState({ isAssigningProvider: true });

        const res$ = ajaxPost('api/panelManagement/assignToProvider', {
            memberIds,
            providerNPI: selectedProvider.value,
        });

        this.subscriptions.push(
            res$.subscribe(
                () => {
                    this.setState({ isAssigningProvider: false });
                    notificationService.success({
                        title: 'Succesful Operation',
                        content: 'Member(s) were asigned',
                    });
                    onAssign(null, selectedProvider.value);
                },
                (err) => {
                    this.setState({ isAssigningProvider: false });
                    notificationService.error({
                        title: 'Failed Operation',
                        content: 'Occurred while members were assigned',
                    });
                    onAssign(err, selectedProvider.value);
                },
            ),
        );

        return res$;
    };

    handleSubmit = async (event) => {
        event && event.preventDefault();
        this.createAssignment();
    };

    render() {
        const { isFetchingProviders, providers, formNotReady, isAssigningProvider } = this.state;
        const { members, isOpen } = this.props;

        return (
            <Modal
                isOpen={isOpen}
                fade
            >
                <form
                    onSubmit={this.handleSubmit}
                    className={styles.root}
                >
                    <SectionHeader>
                        <div className={styles.header}>
                            <div>Change APC</div>
                            <button
                                type='button'
                                onClick={this.handleClose}
                                className={styles.btn}
                            >
                                X
                            </button>
                        </div>
                    </SectionHeader>
                    <article className={styles.content}>
                        <Row>
                            <Col>
                                <h4 className={styles.caption}>Members Selected</h4>
                                <div className={styles['content-left']}>
                                    {!members.length && 'No members were selected'}
                                    {members.map((member) => (
                                        <div key={member.memberId}>
                                            <div className={styles.name}>
                                                <strong>{member.fullName}</strong>
                                            </div>
                                            <div className={styles.id}>{member.memberId}</div>
                                            <div className={member.apc ? styles.assigned : styles.unassigned}>
                                                {member.apc || 'Unassigned'}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col className={styles['content-right']}>
                                <h4 className={styles.caption}>Assign an APC</h4>
                                {isFetchingProviders ? (
                                    <div className={styles['loading-container']}>
                                        <i className={`fa fa-spinner fa-spin fa-3x ${styles.icon}`}></i>
                                    </div>
                                ) : (
                                    <div>
                                        <DropDownListComponent
                                            dataSource={providers}
                                            change={this.handleProviderChange}
                                        />
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </article>
                    <footer className={styles.footer}>
                        <button
                            type='button'
                            className={styles['btn']}
                            onClick={this.handleClose}
                        >
                            Cancel
                        </button>
                        {isAssigningProvider ? (
                            <button
                                type='submit'
                                className={`${styles.btn} ${styles['btn-primary']}`}
                                disabled={formNotReady}
                            >
                                <i className={`fa fa-spinner fa-spin fa-2x ${styles['icon-inverted']}`}></i>
                            </button>
                        ) : (
                            <button
                                type='submit'
                                className={`${styles.btn} ${styles['btn-primary']}`}
                                disabled={formNotReady}
                                onClick={this.handleSubmit}
                            >
                                Assign
                            </button>
                        )}
                    </footer>
                </form>
            </Modal>
        );
    }
}

export default EligibleDialog;
