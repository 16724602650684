import React from 'react';

import { ODataV4Adaptor, UrlAdaptor } from '@syncfusion/ej2-data';
import {
    ExcelExport,
    Filter,
    GridComponent,
    Group,
    Inject,
    Page,
    Resize,
    Sort,
    Toolbar,
} from '@syncfusion/ej2-react-grids';
import defaultTo from 'lodash/defaultTo';
import { finalize } from 'rxjs/operators';

import { getAvaDataManager, getVPCPVisits } from 'common/AVADataManager';
import { getQueryStringFromObject } from 'common/utility';
import { formatDate, memberDataTypes, saveFileRequestResult } from 'common/widgets';
import ColumnChooser from 'components/Extensions/ColumnChooser';

import { exportExcelMembersService, exportPdfMembersService } from '../../subjects/common/ExportMembersService';
import { notificationService } from '../../subjects/common/NotificationService';

const textWrapSettings = { wrapMode: 'Content' };
const selectionSettings = { checkboxOnly: true };

class GridTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.currentMember = props.currentMember;
        this.gridInstance = {};
        this.height = props.height || 260;
        this.rowHeight = 50;
        this.allowTextWrap = defaultTo(this.props.allowTextWrap, true);
        this.allowPaging = props.allowPaging || false;
        this.allowGrouping = props.allowGrouping;
        this.pageSizes = props.pageSizes || [50];
        this.memberDataType = props.memberDataType;
        this.columns = props.columns;
        this.toolbarOptions = props.toolbarOptions || this.getToolbarOptions();
        this.sortSettings = props.sortSettings || this.getSortSettings();
        this.groupConfig = this.getGroupConfig();
        this.filtersConfig = this.getFiltersConfig();
        this.exportSelectedMembersPdf = this.exportSelectedMembersPdf.bind(this);
        this.data = props.dataManager || this.getData();
        this.query = props.query;
        this.rowDataBound = props.rowDataBound;
        this.queryCellInfo = props.queryCellInfo;
        this.pageSettings =
            props.pageSettings === null || props.pageSettings === undefined
                ? {
                      pageSize: this.pageSizes[0] || 50,
                      pageSizes: this.pageSizes,
                  }
                : { ...props.pageSettings, pageSize: this.pageSizes[0] || 50, pageSizes: this.pageSizes };
        this.searchSettings = this.props.searchSettings || {};
        this.autoFitColumns = defaultTo(this.props.autoFitColumns, true);
        this.allowMultiSorting = this.props.allowMultiSorting ?? false;
        this.timeout = null;
        this.emptyRecordMessage = props.emptyRecordMessage;
    }

    componentDidUpdate(prevProps) {
        if (this.props.dataManager !== prevProps.dataManager || this.props.searchTerm != prevProps.searchTerm) {
            this.gridInstance.dataSource = this.props.dataManager || this.getData(this.props.memberDataType);
        }
    }

    getColumnName = () => {
        const columns = this.gridInstance.getColumnFieldNames().slice(1);
        return columns.map((column) => {
            if (column.includes('pcp.name')) {
                return 'pcpName';
            }
            if (column.includes('hospiceEligibility.eligible')) {
                return 'gocEligibility';
            }
            if (column.startsWith('pcp')) {
                //remove substring between dots
                const match = column.replace(/\.\w*\./, '');
                //remove dots.
                return match.replace(/\./g, '');
            } else if (column === 'companyId') {
                return 'HealthPlan';
            } else if (
                column.startsWith('homeAddress') ||
                column.startsWith('alertCounts') ||
                column.startsWith('memberHealthRisk')
            ) {
                //remove substring between dots
                return column.replace(/\w*\./, '');
            } else {
                return column;
            }
        });
    };

    exportSearchResultsToExcel = (requestType, filtersAndValues, callback, url) => {
        const payload = {
            requestType,
            filtersAndValues,
            columnNames: this.getColumnName(),
            pageIndex: this.gridInstance.pageSettings.currentPage,
            pageSize: this.gridInstance.pageSettings.pageSize,
            fileName: this.props.excelFileName || 'advanced_search_results.xlsx',
        };
        exportExcelMembersService
            .post(payload, url)
            .pipe(finalize(callback))
            .subscribe({
                next: (args) => {
                    if (args.responseHeaders.warning) {
                        notificationService.warning({
                            title: 'Warning',
                            content: args.responseHeaders.warning,
                        });
                    }
                    saveFileRequestResult(args.response, args.xhr);
                },
                error: async (error) => {
                    if (error.response instanceof Blob) {
                        const responseString = await error.response.text();

                        const message = JSON.parse(responseString).message;
                        notificationService.error({
                            title: message,
                        });
                        return;
                    }
                    notificationService.error({
                        title: 'Failed to export excel, please try later.',
                    });
                },
            });
    };

    getData() {
        if (this.memberDataType === memberDataTypes.singleMemberSearch) {
            const queryObject = { searchTerm: this.props.searchTerm, ...this.props.queryParams };
            const queryString = this.props.searchTerm ? getQueryStringFromObject(queryObject) : 'searchTerm=';
            return getAvaDataManager(`/odata/members?${queryString}`, ODataV4Adaptor);
        } else if (this.memberDataType === memberDataTypes.advancedSearch) {
            return getAvaDataManager(`/odata/members`, ODataV4Adaptor);
        } else if (this.memberDataType === memberDataTypes.userManagementUserReport) {
            return getAvaDataManager('/api/userreport', UrlAdaptor);
        } else if (
            this.memberDataType === memberDataTypes.vPCPMyUpcomingVisits ||
            this.memberDataType === memberDataTypes.vPCPMyPastVisits
        ) {
            return getVPCPVisits({ appointmentType: this.props.memberDataType });
        } else if (this.memberDataType === memberDataTypes.audits) {
            return getAvaDataManager('/api/audithistory?module=vpcp', UrlAdaptor);
        }

        return null;
    }

    exportSelectedMembersPdf() {
        if (this.props.exportSelectedMembersPdf) {
            this.props.exportSelectedMembersPdf(this.gridInstance.getSelectedRecords());
        } else {
            this.gridInstance &&
                exportPdfMembersService.select(this.gridInstance.getSelectedRecords().map((record) => record.memberId));
        }
    }

    refresh = () => {
        if (this.gridInstance) {
            this.gridInstance.refresh();
        }
    };

    showSpinner = () => {
        if (this.gridInstance && this.gridInstance.showSpinner) {
            this.gridInstance.showSpinner();
        }
    };

    hideSpinner = () => {
        if (this.gridInstance && this.gridInstance.hideSpinner) {
            this.gridInstance.hideSpinner();
        }
    };

    getFilterAndValues() {
        var filterAndValues = [];
        var filters = this.props?.getFilters ? this.props.getFilters() : {};
        const selectedRecords = this.gridInstance.getSelectedRecords();

        for (var key of Object.keys(filters || {})) {
            if (filters[key] instanceof Array) {
                filterAndValues.push({
                    id: key,
                    value: filters[key].join(','),
                });
            } else if (filters[key] instanceof Date) {
                filterAndValues.push({
                    id: key,
                    value: formatDate(filters[key]),
                });
            } else {
                filterAndValues.push({
                    id: key,
                    value: filters[key],
                });
            }
        }
        if (this.props.query && this.props.query.params && this.props.query.params.length) {
            for (const param of this.props.query.params) {
                filterAndValues.push({ id: param.key, value: param.value });
            }
        }
        if (this.props.searchTerm) {
            filterAndValues.push({ id: 'searchTerm', value: this.props.searchTerm });
        }
        if (selectedRecords.length) {
            const selectedMember = selectedRecords.map((record) => record.memberId);
            filterAndValues.push({ id: 'memberId', value: selectedMember.join() });
        }
        return filterAndValues;
    }
    getToolbarOptions() {
        switch (this.memberDataType) {
            case memberDataTypes.singleMemberSearch:
                if (this.props.noExcelExport) {
                    return ['Search'];
                } else {
                    return [
                        {
                            text: 'Export All to Excel',
                            tooltipText: 'Exports the current search results to Excel',
                            prefixIcon: 'e-excelexport',
                            id: 'currentgrid_exportexcel',
                        },
                    ];
                }
            default:
                return ['Search'];
        }
    }

    removeExportExcelButtonAnimation = (button, grid, toolbarItemId) => {
        const duration = 2000;
        //once the function is done, reenable the button
        button.classList.remove('loading');
        button.classList.add('success');
        this.timeout = setTimeout(function () {
            button.classList.remove('success');
            grid.toolbarModule.enableItems([toolbarItemId], true);
        }, duration);
    };

    toolbarClick = (args) => {
        var grid = this.gridInstance;
        if (args && args.item && args.item.id && args.item.id !== 'create-new') {
            if (grid && grid.getRows()?.length === 0) {
                return;
            }
            if (
                args.item.id === 'advancedSearchgrid_exportexcel' ||
                args.item.id === 'currentgrid_exportexcel' ||
                args.item.id === 'audithistory_exportexcel' ||
                args.item.id === 'memberaudithistory_exportexcel' ||
                args.item.id === 'retentionSearchgrid_exportexcel' ||
                args.item.id === 'userReport_exportexcel' ||
                args.item.id === 'hraReport_exportexcel' ||
                args.item.id === 'performanceView_exportexcel' ||
                args.item.id === 'memberPortalAccounts_exportexcel' ||
                args.item.id === 'p360members_exportexcel' ||
                args.item.exportExcel
            ) {
                try {
                    //start export animation + disable export button
                    var btn = document.querySelector('#' + args.item.id);
                    btn.classList.add('loading', 'animatedBtn');
                    grid.toolbarModule.enableItems([args.item.id], false);
                    var callback = () => {
                        //once the function is done, reenable the button
                        btn.classList.remove('loading');
                        btn.classList.add('success');
                        this.timeout = setTimeout(function () {
                            btn.classList.remove('success');
                            grid.toolbarModule.enableItems([args.item.id], true);
                        }, 2000);
                    };

                    //call the function that will export the excel
                    if (
                        args.item.id === 'advancedSearchgrid_exportexcel' ||
                        args.item.id === 'audithistory_exportexcel' ||
                        args.item.id === 'memberaudithistory_exportexcel' ||
                        args.item.id === 'retentionSearchgrid_exportexcel' ||
                        args.item.id === 'memberPortalAccounts_exportexcel' ||
                        args.item.id === 'performanceView_exportexcel'
                    ) {
                        this.exportSearchResultsToExcel(
                            args.item.id,
                            this.getFilterAndValues(),
                            callback,
                            args.item.url,
                        );
                    } else if (args.item.id === 'p360members_exportexcel') {
                        const filterQuery = this.props.getFilters();
                        this.exportSearchResultsToExcel(
                            args.item.id,
                            null,
                            callback,
                            args.item.url +
                                '?count=false&$skip=0&$top=100000000' +
                                (filterQuery ? '&$filter=' + filterQuery : ''),
                        );
                    } else if (args.item.exportExcel) {
                        args.item.exportExcel(this.props.getFilters(), callback, grid);
                    } else {
                        const excelExportProperties = {
                            includeHiddenColumn: false,
                            fileName: this.props.excelFileName || 'SearchResults.xlsx',
                        };
                        grid.excelExport(excelExportProperties).then(() => callback());
                    }
                } catch (e) {
                    console.error('args.item.id', e);
                    btn.classList.remove('loading', 'animatedBtn');
                    grid.toolbarModule.enableItems([args.item.id], false);
                    grid.toolbarModule.enableItems([args.item.id], true);
                }
            }
        }

        if (typeof this.props.toolbarClick === 'function') {
            this.props.toolbarClick(args);
        }
    };

    getSortSettings() {
        return { columns: [] };
    }

    getGroupConfig() {
        return {
            allowGrouping: this.allowGrouping,
            groupSettings: null,
        };
    }

    getFiltersConfig() {
        return {
            allowFiltering: false,
            filterSettings: null,
        };
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    render() {
        return (
            <GridComponent
                className='advancedSearchGridTemplate'
                ref={(grid) => (this.gridInstance = grid)}
                uid={'grid-template-' + this.props.uid}
                id={this.props.id}
                dataSource={this.data}
                query={this.query}
                columns={this.columns}
                toolbar={this.toolbarOptions}
                allowPaging={this.allowPaging}
                pageSettings={this.pageSettings}
                height={this.height}
                rowHeight={this.rowHeight}
                allowSorting={true}
                sortSettings={this.sortSettings}
                allowTextWrap={this.allowTextWrap}
                textWrapSettings={textWrapSettings}
                allowGrouping={this.groupConfig.allowGrouping}
                groupSettings={this.groupConfig.groupSettings}
                allowMultiSorting={this.allowMultiSorting}
                allowReordering={false}
                selectionSettings={selectionSettings}
                allowFiltering={this.filtersConfig.allowFiltering}
                filterSettings={this.filtersConfig.filterSettings}
                allowResizing={false}
                showColumnChooser={true}
                allowExcelExport={true}
                rowDataBound={this.rowDataBound}
                queryCellInfo={this.queryCellInfo}
                resizeSettings={this.props.resizeSettings}
                searchSettings={this.searchSettings}
                actionBegin={(args) => {
                    const grid = this.gridInstance;
                    if (!grid) return;
                    if (args.requestType !== 'paging') {
                        grid.pageSettings.currentPage = 1;
                    }

                    if (
                        !grid.allowMultiSorting &&
                        args.requestType === 'sorting' &&
                        grid.sortSettings.columns.length > 1
                    ) {
                        grid.sortSettings.columns = [grid.sortSettings.columns[1]];
                    }
                }}
                actionComplete={(args) => {
                    if (args.requestType === 'paging') {
                        exportPdfMembersService.select([]);
                    }
                    if (this.props.stopSpinner) {
                        this.props.stopSpinner();
                    }
                    if (args.requestType === 'sorting' && this.props.onSortingComplete) {
                        this.props.onSortingComplete(args);
                    }
                }}
                dataBound={() => {
                    const grid = this.gridInstance;
                    if (!grid) return;
                    if (grid.element?.querySelectorAll('.e-groupcaption')) {
                        grid.element?.querySelectorAll('.e-groupcaption').forEach((el) => {
                            if (el && el.title) {
                                el.title = '';
                            }
                        });
                    }
                    if (this.autoFitColumns) {
                        grid.autoFitColumns();
                    }
                    if (this.emptyRecordMessage) {
                        this.gridInstance.localeObj.currentLocale.EmptyRecord = this.emptyRecordMessage;
                    }
                }}
                recordDoubleClick={(args) => {
                    this.props.onRowSelected && args.rowData && this.props.onRowSelected(args.rowData, args);
                }}
                actionFailure={(e) => {
                    notificationService.error({
                        title: 'There was a problem fetching the results, please try again later.',
                    });
                    if (this.props.stopSpinner) {
                        this.props.stopSpinner();
                    }
                    console.error(e);
                }}
                rowSelected={() => this.exportSelectedMembersPdf()}
                rowDeselected={() => this.exportSelectedMembersPdf()}
                beforeOpenColumnChooser={(args) => {
                    args.searchOperator = 'contains';
                }}
                toolbarClick={this.toolbarClick}
                excelQueryCellInfo={this.props.excelQueryCellInfo}
                checkBoxChange={() => {
                    this.props.selectedRows && this.props.selectedRows(this.gridInstance.getSelectedRecords());
                }}
            >
                <Inject services={[Toolbar, Page, Sort, Group, Filter, Resize, ColumnChooser, ExcelExport]} />
            </GridComponent>
        );
    }
}

export default GridTemplate;
