import { useState } from 'react';

import { Observable } from 'rxjs';

import { useSubscription } from './useSubscription';

export const useObservable = <T>(
    source$: Observable<T> | null,
    initialState: T,
    error: (err: unknown) => void = console.log,
) => {
    const [value, setValue] = useState<T>(initialState);

    useSubscription<T>(source$, setValue, error);

    return value as T;
};
