import { useEffect, useState } from 'react';

import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { Subject } from 'rxjs';
import { map, filter, takeUntil } from 'rxjs/operators';

import { useAuthProvider } from 'AuthProvider';

import { currentUserService } from '../../subjects/common/CurrentUserService';
import { talkdeskCtiService } from '../../subjects/crossModules/TalkdeskCtiService';

const UserDropDown = ({ talkdeskEnabled }) => {
    const authProvider = useAuthProvider();

    const [userName, setUserName] = useState('');
    const unsubscribe$ = new Subject();

    useEffect(() => {
        currentUserService
            .get()
            .pipe(
                takeUntil(unsubscribe$),
                filter((user) => user),
                map((user) => user.name),
                filter((name) => name),
            )
            .subscribe((name) => setUserName(name));

        return () => {
            unsubscribe$.next();
            unsubscribe$.complete();
        };
        // unsubscribe$ won't affect the hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelect = (args) => {
        if (args.item.text === 'Sign Out') {
            authProvider.logout();
        } else if (args.item.text === 'Talkdesk') {
            talkdeskCtiService.iframeToggleMessages$().next();
        }
    };

    const items = [{ text: 'Sign Out' }];
    if (talkdeskEnabled === true) items.push({ text: 'Talkdesk' });

    return (
        <DropDownButtonComponent
            id='userdd'
            items={items}
            select={onSelect}
        >
            <img
                src='/img/userAccount.png'
                className='user-image'
                alt='User'
                height='25px'
            />
            <span className='hidden-xs'>{userName}</span>
        </DropDownButtonComponent>
    );
};

export default UserDropDown;
