import React, { createContext, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider, useAccount, useMsal } from '@azure/msal-react';
import { registerLicense } from '@syncfusion/ej2-base';
import axios from 'axios';
import decodeJWT from 'jwt-decode';
import { filter, map, takeUntil } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import { useUnmountSubject } from '@hooks';
import { initAppInsights, trackEvent } from 'ApplicationInsights';
import { ajaxGet } from 'common/ajax';
import B2CConfig from 'interfaces/Config/B2CConfig';
import { CurrentUser } from 'interfaces/User';
import { accessToken$, currentUser, currentUserService, modules } from 'subjects/common/CurrentUserService';

export const AuthProviderVariables = {
    ACCESS_TOKEN: 'access_token', // do not change this value; it is used as such in different places
    CC_ACCESS_TOKEN: 'cc_access_token',
    ACCESS_TOKEN_API_LAST_ACCESSED: 'access_token_api_last_accessed',
    USER_DEFAULT_MAX_INACTIVE_SECONDS: 3600, // 1 hour
    USER_NEEDS_TO_LOGIN_AGAIN: 'user_needs_to_login_again',
    USER_MAX_INACTIVE_SECONDS: 'user_max_inactive_seconds',
};

export interface AuthProviderUser {
    name: string;
    firstName: string;
    lastName: string;
    emails: string;
    city: string;
    country: string;
}

export interface AuthContextType {
    accessToken: string | null;
    b2cConfig: B2CConfig | null;
    currentUser: CurrentUser | null;
    getAuthUser: () => AuthProviderUser | null;
    getIsTokenExpired: () => boolean;
    logout: (eventName?: string) => void;
}

export const AuthContext = createContext<AuthContextType>({
    accessToken: null,
    b2cConfig: null,
    currentUser: null,
    getAuthUser: () => null,
    getIsTokenExpired: () => true,
    logout: () => undefined,
});

export const useAuthProvider = () => {
    return useContext<AuthContextType>(AuthContext);
};

// Do not call functions that change the user's authenticated state (login, logout) outside the
// context provided by MsalProvider as the components inside the context may not properly update.
const AuthProvider = ({ children }) => {
    const unmountSubscriptions$ = useUnmountSubject();

    const msalWrappedRef = useRef<React.ElementRef<typeof MsalWrapped>>(null);

    const [b2cConfig, setB2CConfig] = useState<B2CConfig | null>(null);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);

    useEffect(() => {
        ajax('api/Config/B2C')
            .pipe(
                map((response) => response.response),
                takeUntil(unmountSubscriptions$),
            )
            .subscribe((config: any) => {
                setB2CConfig(config);
            });

        //logout if user does not have any employments
        currentUserService
            .get()
            .pipe(
                filter((user: any) => user.email),
                filter((user) => !(user.employers && user.employers.length)),
                takeUntil(unmountSubscriptions$),
            )
            .subscribe({
                next: (_) => {
                    logout();
                },
            });
    }, []);

    useEffect(() => {
        if (b2cConfig) {
            initB2CConfig();
        }
    }, [b2cConfig]);

    const initB2CConfig = () => {
        if (!b2cConfig) return;

        const { instrumentationKey, syncfusionLicenseKey, userSessionSettings } = b2cConfig;

        sessionStorage.setItem(AuthProviderVariables.ACCESS_TOKEN_API_LAST_ACCESSED, Date.now() as any);
        sessionStorage.setItem(
            AuthProviderVariables.USER_MAX_INACTIVE_SECONDS,
            userSessionSettings.userMaxInactiveSeconds as any,
        );

        registerLicense(syncfusionLicenseKey);

        initAppInsights(instrumentationKey, history);
    };

    const getAuthUser = (): AuthProviderUser | null => {
        const token = getToken();

        if (!token) return null;

        const decoded: any = decodeJWT(token);
        return {
            name: decoded.name,
            firstName: decoded.given_name,
            lastName: decoded.family_name,
            emails: decoded.emails,
            city: decoded.city,
            country: decoded.country,
        };
    };

    const setToken = (token: string | null) => {
        setAccessToken(token);
        if (token) {
            sessionStorage.setItem(AuthProviderVariables.ACCESS_TOKEN, token);
        } else {
            sessionStorage.removeItem(AuthProviderVariables.ACCESS_TOKEN);
        }
        accessToken$.next(token);
    };

    const getToken = () => {
        // reset last accessed time
        sessionStorage.setItem(AuthProviderVariables.ACCESS_TOKEN_API_LAST_ACCESSED, Date.now() as any);
        return accessToken;
    };

    const getIsTokenExpired = () => {
        const token = getToken();
        if (!token) {
            return true;
        }

        const decoded: any = decodeJWT(token);

        if (!decoded.exp) {
            return true;
        }

        return Date.now() > decoded.exp * 1000;
    };

    const logout = (eventName?: string) => {
        msalWrappedRef.current?.logout(eventName);
    };

    if (!b2cConfig) return null;

    return (
        <AuthContext.Provider
            value={{
                accessToken: getToken(),
                b2cConfig,
                currentUser,
                getAuthUser,
                getIsTokenExpired,
                logout,
            }}
        >
            <MsalProvider
                instance={
                    new PublicClientApplication({
                        auth: {
                            clientId: b2cConfig.clientId,
                            knownAuthorities: [`${b2cConfig.tenantName}.b2clogin.com`], // You must identify your tenant's domain as a known authority.
                            redirectUri: window.location.origin,
                            authority: b2cConfig.authority, // Choose sign-up/sign-in user-flow as your default.
                            postLogoutRedirectUri: window.location.origin,
                            authorityMetadata: JSON.stringify(b2cConfig.authorityMetadata), // Supply preloaded metadata
                        },
                        cache: {
                            cacheLocation: b2cConfig.cacheLocation, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                            storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
                        },
                    })
                }
            >
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={{ scopes: b2cConfig.scopes }}
                >
                    <MsalWrapped
                        ref={msalWrappedRef}
                        setCurrentUser={setCurrentUser}
                        setToken={setToken}
                    >
                        {children}
                    </MsalWrapped>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </AuthContext.Provider>
    );
};

interface MsalWrappedRef {
    logout: (eventName?: string) => void;
}

interface MsalWrappedProps {
    children: any;
    setCurrentUser: (user: CurrentUser | null) => void;
    setToken: (token: string | null) => void;
}

// cannot use useMsal hook outside of msal provider
// extra layer of wrapper to keep all auth/msal within authprovider
const MsalWrapped = forwardRef<MsalWrappedRef, MsalWrappedProps>(
    ({ children, setCurrentUser, setToken }: MsalWrappedProps, ref) => {
        const unmountSubscriptions$ = useUnmountSubject();

        const { accounts, instance } = useMsal();
        const account = useAccount(accounts[0]);

        const { accessToken, b2cConfig } = useAuthProvider();

        const [msalGetTokenTimer, setMsalGetTokenTimer] = useState<NodeJS.Timer | null>(null);

        useImperativeHandle(ref, () => ({
            logout,
        }));

        useEffect(() => {
            if (!b2cConfig?.scopes || !account) return;

            if (msalGetTokenTimer) clearInterval(msalGetTokenTimer);

            getAccessTokenFromMsal(() => {
                setMsalGetTokenTimer(
                    setInterval(() => {
                        getAccessTokenFromMsal(undefined, () => {
                            logout();
                        });
                    }, b2cConfig.userSessionSettings.tokenRefreshPeriodSeconds * 1000),
                );
                fetchCurrentUser();
            });

            return () => {
                if (msalGetTokenTimer) clearInterval(msalGetTokenTimer);
            };
        }, [b2cConfig?.scopes, account]);

        const fetchCurrentUser = () => {
            ajaxGet('api/me')
                .pipe(
                    map((response) => response.response),
                    takeUntil(unmountSubscriptions$),
                )
                .subscribe({
                    next: (user) => {
                        setCurrentUser(user);
                        currentUser.next(user);
                        modules.next(user.modules);
                    },
                    error: (error) => {
                        console.error(error);
                        if (error.status >= 400 && error.status < 500) {
                            logout();
                        }
                    },
                });
        };

        const getAccessTokenFromMsal = (onSuccess?: () => void, userIdleCallback?: () => void) => {
            if (!b2cConfig?.scopes || !account) return;

            if (!getIsUserActive() && userIdleCallback) {
                userIdleCallback();
                return;
            }

            instance
                .acquireTokenSilent({ scopes: b2cConfig?.scopes, account, redirectUri: window.location.origin })
                .then((response) => {
                    setToken(response.accessToken);
                    axios.defaults.headers.common = { Authorization: `Bearer ${response.accessToken}` };
                    if (onSuccess) onSuccess();
                })
                .catch((err) => {
                    console.error('Error in getAccessTokenFromMsal', err);
                    // Unable to acquire the token silently. Manual intervention is required
                    logout('AVA-UI-SessionExpired');
                });
        };

        const getIsUserActive = () => {
            const lastAccessedStr = sessionStorage.getItem(AuthProviderVariables.ACCESS_TOKEN_API_LAST_ACCESSED);
            if (lastAccessedStr && lastAccessedStr.length > 0) {
                const lastAccessed = Number(lastAccessedStr);
                const userMaxInactiveSecondsStr = sessionStorage.getItem(
                    AuthProviderVariables.USER_MAX_INACTIVE_SECONDS,
                );
                let userMaxInactiveSeconds = AuthProviderVariables.USER_DEFAULT_MAX_INACTIVE_SECONDS;
                if (userMaxInactiveSecondsStr && userMaxInactiveSecondsStr.length > 0) {
                    userMaxInactiveSeconds = Number(userMaxInactiveSecondsStr);
                }
                if (Date.now() - lastAccessed > 1000 * userMaxInactiveSeconds) {
                    return false;
                }
            }

            return true;
        };

        const logout = (eventName = 'AVA-UI-Logout') => {
            if (!instance || !instance.logoutRedirect) {
                throw new Error('Unable to logout. No valid instance of MSAL was found.');
            }

            trackEvent(eventName);
            currentUserService.logout();
            sessionStorage.removeItem(AuthProviderVariables.ACCESS_TOKEN);
            instance.logoutRedirect();
        };

        if (!accessToken || !sessionStorage.getItem(AuthProviderVariables.ACCESS_TOKEN)) return null;

        return children;
    },
);

export default AuthProvider;
