import { useEffect, useState } from 'react';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';

import './Notepad.css';
import { talkdeskNotesService } from '../../subjects/TalkdeskNotesService';
import { CollapsableBoxSection } from '../UI/BoxSection';

interface NotepadProps {
    talkdeskId: string;
    notes?: string | null;
    header: string;
    setVisible: (visible: boolean) => void;
}

export default function Notepad({ talkdeskId, notes, header, setVisible }: NotepadProps) {
    const [currentNotes, setCurrentNotes] = useState<string>(notes ?? '');

    useEffect(() => setCurrentNotes(''), [talkdeskId]);

    const handleChange = (notes: string) => {
        setCurrentNotes(notes);
        if (notes && notes.length > 0) {
            talkdeskNotesService.enqueue({ talkdeskId, notes });
        }
    };

    return (
        <>
            <CollapsableBoxSection header={header}>
                <div className={'notes-container'}>
                    <p className='mb-2 mt-1'>Your notes will be attached to the current call.</p>
                    <TextBoxComponent
                        placeholder='You can resize this by dragging the bottom right corner.'
                        change={(args) => handleChange(args.value)}
                        value={currentNotes}
                        multiline={true}
                    />
                </div>
                <div className='buttons-container'>
                    <button
                        className='e-control e-btn me-3 e-primary'
                        onClick={() => setVisible(false)}
                    >
                        Close
                    </button>
                </div>
            </CollapsableBoxSection>
        </>
    );
}
