import { DateTime } from 'luxon';

import { ajaxGet, ajaxPost } from 'common/ajax';

import { filterControlType, getControllerName } from '../../../common/widgets';

export const advancedSearchStaticFilters: any = [
    {
        id: 'MemberId',
        label: 'Member ID',
        controlType: filterControlType.TextBox,
        parameters: {
            placeHolder: 'Member ID',
        },
    },
    {
        id: 'LastName',
        label: 'Last Name',
        controlType: filterControlType.TextBox,
        parameters: {
            placeHolder: 'Last Name',
        },
    },
    {
        id: 'FirstName',
        label: 'First Name',
        controlType: filterControlType.TextBox,
        parameters: {
            placeHolder: 'First Name',
        },
    },
    {
        id: 'DOB',
        label: 'DOB',
        controlType: filterControlType.DatePicker,
        parameters: {
            placeHolder: 'DOB',
            maximumDate: DateTime.local().toFormat('MM/dd/yyyy'),
            initialCalendarView: {
                setInitialView: true,
                addYears: -65,
            },
        },
    },
    {
        id: 'CareAnywhereStatus',
        label: 'Care Anywhere Status',
        controlType: filterControlType.MultiSelect,
        parameters: {
            placeHolder: 'Care Anywhere Status',
        },
        showSelectAll: false,
    },
    {
        id: 'ClinicalPrograms',
        label: 'Clinical Programs',
        controlType: filterControlType.MultiSelect,
        parameters: {
            placeHolder: 'Clinical Programs',
        },
    },
    {
        id: 'IPAs',
        label: 'IPA',
        controlType: filterControlType.MultiSelect,
        parameters: {
            type: 'IPA',
            placeHolder: 'IPA',
        },
        showSelectAll: false,
    },
    {
        id: 'PCPs',
        label: 'PCP',
        controlType: filterControlType.MultiSelect,
        parameters: {
            type: 'PCP',
            placeHolder: 'PCP',
        },
        showSelectAll: false,
    },
    {
        id: 'MemberStatus',
        label: 'Member Status',
        controlType: filterControlType.MultiSelect,
        parameters: {
            placeHolder: 'Member Status',
            maxSelectionLength: 1,
        },
        showSelectAll: false,
    },
];

export const appointmentStatusTable = (url) => {
    return ajaxGet(url);
};

export const appointmentDetailedReasonTable = (url) => {
    return ajaxGet(url);
};

export const appointmenCancellationReasonTable = (url) => {
    return ajaxGet(url);
};

export const getTabSortSettings = {
    upcomingVirtualVisitsColumnSort: {
        columns: [
            {
                field: 'startTimeUTC',
                direction: 'Ascending',
            },
        ],
    },
    pastVirtualVisitsColumnSort: {
        allowUnsort: false,
        columns: [
            {
                field: 'startTimeUTC',
                direction: 'Descending',
            },
        ],
    },
};

export const MEMBER_NOT_AVAILABLE = 'Unavailable';

export const TELEHEALTH_MODULES = {
    CAREONDEMAND: 'CareOnDemand',
    VPCP: 'VirtualPCP',
    TELEHEALTH: 'Telehealth',
    PANELMANAGEMENT: 'PanelManagement',
    CRM: 'CRM',
    CAW: 'CareAnywhere',
    JSA: 'JSA',
    CM: 'CM',
    AllCoDModules: 'AllCoDModules',
};

export const UNITY_TENANTS = {
    VPCP: 'AMG',
    CAREONDEMAND: 'AHCCA',
};

export const TELEHEALTH_MODULES_TO_URL = {
    CareOnDemand: 'CareOnDemand',
    VirtualPCP: 'VirtualAppointments',
    Telehealth: 'Telehealth',
    PanelManagement: 'PanelManagementCOD',
    CRM: 'CrmAppointments',
    CM: 'CMCase',
};

export const DIALOG_TYPE = {
    onDemandVisit: 'onDemandVisit',
    scheduleAssistant: 'scheduleAssistant',
    addNewAppointment: 'addNewAppointment',
};

export const getDepartmebtAbbr = (providerRole) => {
    return providerRole === 'CareOnDemand'
        ? 'TELEHLTH'
        : providerRole === 'CareAnywhere'
        ? 'HighRisk'
        : providerRole === 'JSA'
        ? 'HOME'
        : '';
};

export const getRoleId = (providerRole) => {
    return providerRole === 'CareOnDemand'
        ? 'VCC'
        : providerRole === 'CareAnywhere'
        ? 'CAW'
        : providerRole === 'JSA'
        ? 'JSA'
        : '';
};

export const memberCardDataSubscription = (memberId: string) => {
    return ajaxGet(`odata/members?memberIds=${memberId}`);
};

export const sendVisitSummarySubscription = (module) => {
    return ajaxPost(`api/${module}/SendVisitSummary`);
};

export const saveSummarySubscription = (module, payload) => {
    return ajaxPost(`/api/${module}/Summary`, payload);
};

export const GetVPCPFeatureFlags = (module: string) => {
    return ajaxGet(`/api/${getControllerName(module)}/GetFeatureFlags`);
};

export const GetUserFlags = (module: string) => {
    return ajaxGet(`api/${getControllerName(module)}/GetUserFlags`);
};

export const getMember = (memberId) => {
    return ajaxGet(`/odata/members?memberIds=${memberId}&memberStatus=ALL`);
};

export const getAppointmentCategoriesFromIndex = (index) => {
    const mapping = {
        1: 'JSACOMP',
        2: 'JSANCOM',
        3: 'CAWCOMP',
        4: 'CAWNCOM',
        7: 'VCCNCOMP',
        8: 'VCCCOMP',
    };
    return mapping[index] || 'Unknown Category';
};
