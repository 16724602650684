import { lazy, useEffect } from 'react';

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import OpenReplay from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import { DataUtil } from '@syncfusion/ej2-data';
import { Routes, Route, Navigate } from 'react-router-dom';
import { forkJoin, of } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';

import { useUnmountSubject } from '@hooks';
import { trackEvent } from 'ApplicationInsights';
import { ajaxGet } from 'common/ajax';
import { modules } from 'common/widgets';
import AdminAndSupportAdminPortal from 'components/AvaEzm/MemberEnrollment/AdminAndSupport/AdminAndSupportAdminPortal';
import { TELEHEALTH_MODULES } from 'components/Common/VirtualVisits/MyPanelHelpers';
import BenefitConfigurator from 'components/CRM/BenefitConfigurator/BenefitConfigurator';
import Layout from 'components/Layout';
import IpaAdministrationPage from 'components/ProviderData/IpaAdministration/pages/IpaAdministrationPage';

import LazyRender from './hoc/LazyRender';
import { currentUserService } from './subjects/common/CurrentUserService';

import 'css/styles.css';

const IPADashboard = lazy(() => import('components/Dashboards/IPADashboard'));
const PCPDashboard = lazy(() => import('components/Dashboards/PCPDashboard'));
const NCIPADashboard = lazy(() => import('components/Dashboards/NCIPADashboard'));
const StarDashboard = lazy(() => import('components/Dashboards/StarDashboard'));

const AuthorizationGuideLines = lazy(() => import('components/Common/Guidelines/IndexGuideLines'));
const ClaimsGuideLines = lazy(() => import('components/Common/Guidelines/IndexGuideLines'));
const EligibilityuideLines = lazy(() => import('components/Common/Guidelines/IndexGuideLines'));
const ReportsSubmissionGuideLines = lazy(() => import('components/Common/Guidelines/IndexGuideLines'));

const PatientPriorityWorkList = lazy(() => import('components/PatientPriority/WorkList'));
const PatientPriorityAllPatients = lazy(() => import('components/PatientPriority/AllPatients'));
const PatientPriorityDashboards = lazy(() => import('components/PatientPriority/Dashboards'));
const PatientPriorityGuidelines = lazy(() => import('components/PatientPriority/PatientPriorityGuidelines'));
const PatientPriorityGapsReview = lazy(() => import('components/PatientPriority/GapsReview'));
const PatientPriorityAudit = lazy(() => import('components/PatientPriority/Audit/AuditPanel'));

const ChartsSearch = lazy(() => import('components/AvaCharts/Search'));
const ChartsUpload = lazy(() => import('components/AvaCharts/Upload'));
const ChartsSplitAndValidation = lazy(() => import('components/AvaCharts/SplitAndValidation'));
const ChartsStarsCoderView = lazy(() => import('components/AvaCharts/StarsCoderView'));
const ChartsStarsAdmin = lazy(() => import('components/AvaCharts/StarsAdmin'));

const UserManagementUserTab = lazy(() => import('components/UserManagement/UserTab'));
const UserManagementOrganizationTab = lazy(() => import('components/UserManagement/OrganizationTab'));
const UserManagementUserReport = lazy(() => import('components/UserManagement/UserReport'));
const UserManagementGroupTab = lazy(() => import('components/UserManagement/GroupTab'));
const UserManagementProviders = lazy(() => import('components/UserManagement/Providers'));
const UserManagementUserEvents = lazy(() => import('components/UserManagement/UserEvents'));

const Encounters = lazy(() => import('components/Encounters/Search/Encounters'));
const PendingClaims = lazy(() => import('components/ClaimsAuditor/PendingClaims'));
const ClaimsAuditorAdmin = lazy(() => import('components/ClaimsAuditor/Admin'));
const ClaimsAuditorRoles = lazy(() => import('components/ClaimsAuditor/RoleManagement'));
const AdvancedAudits = lazy(() => import('components/ClaimsAuditor/AdvancedAudits'));

const SNPTeamMeber = lazy(() => import('components/CareManagement/SNPMember/SNPMember'));
const PostDischargeProgramManager = lazy(
    () => import('components/CareManagement/PostDischargeProgramManager/PostDischargeProgramManagerPanel'),
);
const OutpatientTeam = lazy(() => import('components/CareManagement/OutpatientTeam/OutpatientTeam'));
const CMPaperHRA = lazy(() => import('components/CareManagement/CMPaperHRA/CMPaperHRA'));
const CMDashboards = lazy(() => import('components/CareManagement/dashboards/Dashboards'));

const EligibilityAndBenefits = lazy(() => import('components/Eligibility/EligibilityAndBenefits'));
const EligibilityAndBenefitsAgent = lazy(() => import('components/Eligibility/EligibilityAndBenefitsAgent'));
const EligibilityAndBenefitsSpecialist = lazy(() => import('components/Eligibility/EligibilityAndBenefitsSpecialist'));

const Claims = lazy(() => import('components/Claims/Claims'));
const ClaimsInternal = lazy(() => import('components/Claims/ClaimsInternal'));
const Authorization = lazy(() => import('components/Authorizations/Authorizations'));
const UMFaxManagement = lazy(() => import('components/UM/UMFaxManagement'));
const UMAuthorizationWrapper = lazy(() => import('./components/Common/UMAuthorizations/UMAuthorizationWrapper'));
const UMProductivityParent = lazy(() => import('components/UM/Productivity/UMProductivityParent'));
const UMAdmin = lazy(() => import('components/UM/UMAdmin'));
const AVADailyAdmit = lazy(() => import('components/CensusDetails/AVADailyAdmission'))
const AVADailyCensus = lazy(() => import('components/CensusDetails/AVADailyCensus'))
const AVADailyDischarge = lazy(() => import('components/CensusDetails/AVADailyDischarge'))
const AuthMetrics = lazy(() => import('components/UM/AuthMetrics'));

const Home = lazy(() => import('components/Layout/Home'));
const MessagesMainTab = lazy(() => import('components/Messaging/MessagesMainTab'));
const MemberMessages = lazy(() => import('components/Messaging/MemberMessages/Interactions'));
const Patient360 = lazy(() => import('components/Patient360/Patient360'));
const ClinicalJourney = lazy(() => import('components/Coder360/ClinicalJourneyTab'));
const UserGuidesAndTrainings = lazy(() => import('components/Patient360/UserGuidesAndTrainings'));
const Outreach = lazy(() => import('components/JSA/Outreach'));
const JSAAdmin = lazy(() => import('components/JSA/Admin/Admin'));
const Assessment = lazy(() => import('components/JSA/Assessment/AssessmentPanel'));
const AuditHistory = lazy(() => import('components/AuditHistory/AuditHistoryPage'));
const ProviderSearch = lazy(() => import('components/ProviderSearchModule/ProviderSearch'));
const DailyCensus = lazy(() => import('components/Census/DailyCensus'));
const DailyAdmission = lazy(() => import('components/Census/DailyAdmission'));
const DailyDischarge = lazy(() => import('components/Census/DailyDischarge'));

const AG360 = lazy(() => import('components/AppealsAndGrievances/AppealsAndGrievance360View'));

// CRM
const MemberPortalAdminConsole = lazy(
    () => import('components/CRM/MemberPortalAdminConsole/MemberPortalAdminConsolePage'),
);
const MyQueue = lazy(() => import('components/CRM/MyQueue'));
const CampaignStats = lazy(() => import('components/CRM/CampaignStats/CampaignStats'));
const AgentsProductivity = lazy(() => import('components/CRM/AgentProductivity/AgentsProductivity'));
const CommunicationsManager = lazy(() => import('components/CRM/CommunicationsManager/CommunicationsManager'));
const CampaignConfigurator = lazy(() => import('components/CRM/CampaignConfigurator'));
const AdvancedSearch = lazy(() => import('components/CRM/AdvancedSearch'));
const DisenrollmentDashboards = lazy(() => import('components/CRM/CrmDashboards'));
const MemberEscalation = lazy(() => import('components/CRM/MemberEscalationForm'));

const CaseReassign = lazy(() => import('components/CrmAdmin/CrmAutoAssign/CaseReassign'));
const TextReplace = lazy(() => import('components/CrmAdmin/TextReplace/TextReplace'));
const FormBuilder = lazy(() => import('components/CrmAdmin/FormBuilder/NewFormBuilder'));
const CampaignHome = lazy(() => import('components/CrmAdmin/Campaign/CampaignHome'));
const CrmServiceActions = lazy(() => import('components/CrmAdmin/ServiceFlows/ServiceFlowsHome'));
const CrmQueues = lazy(() => import('components/CrmAdmin/Queues/Queues'));
const CrmRequestPlanMaterials = lazy(() => import('components/CrmAdmin/RequestPlanMaterials/RequestPlanMaterials'));
const CrmStickyNotes = lazy(() => import('components/CrmAdmin/StickyNotes/StickyNotesHome'));
const CrmAdminBenefits = lazy(() => import('components/CrmAdmin/Benefits/BenefitsHome'));

const MyPanel = lazy(() => import('components/PanelManagement/MyPanel'));
const AdminPanel = lazy(() => import('components/PanelManagement/AdminPanel'));

const MyVPCPPanel = lazy(() => import('components/Common/VirtualVisits/MyVPCPPanel'));
const MyCoDPanel = lazy(() => import('components/Common/VirtualVisits/MyCoDPanel'));
const VirtualVisitsMessageCenter = lazy(() => import('components/VirtualPCP/MessageCenter'));
const CareOnDemandMessageCenter = lazy(() => import('components/CareOnDemand/MessageCenter'));

const CareOnDemandAuditHistoryTab = lazy(() => import('components/CareOnDemand/AuditHistoryTab'));
const VirtualVisitsAuditHistoryTab = lazy(() => import('components/VirtualPCP/AuditHistoryTab'));
const LiveDashboardTab = lazy(() => import('components/VirtualPCP/LiveDashboardTab'));
const VPCPAppointmentAdmin = lazy(() => import('components/VirtualPCP/Admin/VPCPAdmin'));
const CODAppointmentAdmin = lazy(() => import('components/CareOnDemand/Admin/CODAdmin'));
const Coder360 = lazy(() => import('components/Coder360/Coder360'));
const Coder360SubmissionsModule = lazy(() => import('components/Coder360/Coder360SubmissionsModule'));
const Coder360MyCRRsModule = lazy(() => import('components/Coder360/Coder360MyCRRsModule'));
const Coder360MyCRRsAdminModule = lazy(() => import('components/Coder360/Coder360MyCRRsModule'));

const Dashboard = lazy(() => import('components/MyOffice/Dashboard'));

const Telehealth = lazy(() => import('components/Telehealth/Telehealth'));

const ApptRequests = lazy(() => import('components/Telehealth/ApptRequests/ApptRequests'));

const EzmMemberAdminPage = lazy(() => import('components/AvaEzm/Member/MemberAdminPage'));

const EzmServiceOrganizationAdmin = lazy(
    () => import('components/ProviderData/ServiceOrganization/ServiceOrganizationAdminPage'),
);

const EzmProviderGroupAdmin = lazy(() => import('components/ProviderData/ProviderGroup/ProviderGroupAdminPage'));

const EzmBatchJobStatusReport = lazy(() => import('components/AvaEzm/CmsImport/BatchJobStatus/BatchImportStatusPage'));

const EzmBatchJobErrorReport = lazy(() => import('components/AvaEzm/CmsImport/BatchJobError/BatchJobErrorPage'));

const EzmTokenViewer = lazy(() => import('components/AvaEzm/Utilities/TokenViewerPage'));

// The module is becomig part of CRM
const CrmLoginAccounts = lazy(() => import('components/CRM/MemberPortalAdmin/MemberPortalAdminPage'));

const CRMMemberPortalAdminAuditHistoryPage = lazy(
    () => import('components/CRM/MemberPortalAdmin/MemberAuditHistoryPage'),
);

const EzmProviderPortalAdminReport = lazy(
    () => import('components/AvaEzm/ProviderPortalAdmin/ProviderPortalAdminPage'),
);
const EzmHealthPlanManagementBenefit = lazy(
    () => import('components/AvaEzm/HealthPlanManagementBenefit/HealthPlanManagementBenefitPage'),
);
const EzmHealthPlanManagement = lazy(() => import('components/AvaEzm/HealthPlanManagement/HealthPlanManagementPage'));

const InvalidMemberEnrollment = lazy(() => import('components/InvalidMemberEnrollments/InvalidMemberEnrollmentsPage'));

const EobNotificationsHistoryViewer = lazy(
    () => import('components/EobEmailNotificationHistory/EobEmailNotificationHistoryPage'),
);

const ProviderToolsAccountEditor = lazy(
    () => import('components/ProviderTools/AccountEditor/ProviderToolsAccountEditPage'),
);

const ProviderToolsEligibility = lazy(
    () => import('components/ProviderTools/MemberEligibility/ProviderToolsMemberEligibilityPage'),
);

const ProviderToolsReportUpload = lazy(
    () => import('components/ProviderTools/ReportsSubmission/ProviderToolsReportsSubmissionPage'),
);

const ProviderToolsAdmin = lazy(() => import('components/ProviderToolsAdmin'));

const ContractInfo = lazy(() => import('components/ProviderData/ContractAdmin/ContractInfoPage'));

const HospitalMaintenance = lazy(() => import('components/ProviderData/FacilityAdmin/HospitalMaintenancePage'));
const TemplateManager = lazy(() => import('components/ProviderData/DOFRTemplate/TemplateManagerPage'));

const DataImportUtility = lazy(() => import('components/ProviderData/DataImportUtility/DataImportPage'));
const ProviderDataUpdateRequest = lazy(
    () => import('components/ProviderData/DirectoryChangeRequest/DataUpdateRequestPage'),
);

const ProviderCalendar = lazy(() => import('components/AvaScheduler/MyProviderSchedulerPanel'));
const AdminApplications = lazy(() => import('components/Admin'));
const ContentManager = lazy(() => import('components/ContentManager'));
const ContentLibrary = lazy(() => import('components/ContentLibrary/ContentLibraryContainer'));
const PerformanceModule = lazy(() => import('components/Admin/Performance/PerformanceModule'));

const StaticFileManager = lazy(() => import('components/Admin/StaticFileManager'));

// Broker Portal
const NotificationManagerPage = lazy(() => import('components/SalesAgent/NotificationManager/NotificationManagerPage'));
const FileManagerBroker = lazy(() => import('components/BrokerPortal/FileManager/Overview'));
const BrokerAnalytics = lazy(() => import('components/BrokerPortal/Analytics'));

const BrokerCRM = lazy(() => import('components/BrokerPortal/BrokerPortalCRM/index'));
const BrokerCommissions = lazy(() => import('components/BrokerPortal/Commissions'));
const BrokerReports = lazy(() => import('components/BrokerPortal/Reports'));
const BrokerUserManager = lazy(() => import('components/BrokerPortal/UserSecurity'));
const BrokerUserGuides = lazy(() => import('components/BrokerPortal/UserGuide'));

// SalesBroker and SalesRep
const BrokerSearch = lazy(() => import('components/AvaEzm/SalesBroker/Broker360'));
const SalesRepSearch = lazy(() => import('components/AvaEzm/SalesRep/SalesAgentAdminPage'));
const SalesRepAdminPage = lazy(() => import('components/SalesAgent/SalesAgent/SalesAgentPage'));
const CertificationSessionManager = lazy(
    () => import('components/SalesAgent/CertificationSessionManager/CertificationSessionManagerPage'),
);

// Compliance Report
const ComplianceReportSearch = lazy(() => import('components/AvaEzm/ComplianceReport/ComplianceAdminPage'));

//Web Enrollments
const WebEnrollmentsSearch = lazy(() => import('components/AvaEzm/WebEnrollments/WebEnrollmentsAdminPage'));

// Review Web Enrollment
const ReviewWebEnrollment = lazy(() => import('components/AvaEzm/ReviewWebEnrollment/ReviewWebEnrollmentDetails'));

const CMSEligibilityCheck = lazy(() => import('components/AvaEzm/CMSEligibilityCheck/CMSEligibilityCheck'));

// Member Enrollment
const MemberEnrollment = lazy(
    () => import('components/AvaEzm/MemberEnrollment/MemberEnrollmentDetails/MemberEnrollmentAdminPage'),
);

// Paper Application Uploader
const MemberEnrollmentUploadAdmin = lazy(
    () => import('components/AvaEzm/MemberEnrollmentUpload/MemberEnrollmentUploadAdmin'),
);

// Member Enrollment Reports and Utilities
const MemberEnrollmentReportAndUtilitiesAdmin = lazy(
    () => import('components/AvaEzm/MemberEnrollment/ReportsAndUtilities/ReportsAndUtilitiesAdmin'),
);

const ReturnMailEscalations = lazy(() => import('components/ReturnMailEscalations/ReturnMailEscalations'));

DataUtil.odv4UniOperator['any'] = 'any';
DataUtil.fnOperators['any'] = function () {
    return true;
};

const App = () => {
    const unmountSubscriptions$ = useUnmountSubject();

    useEffect(() => {
        currentUserService
            .get()
            .pipe(
                filter((user: any) => user?.email),
                mergeMap((user) =>
                    forkJoin(
                        of(user),
                        ajaxGet('api/me/config').pipe(
                            map(({ response }) => response),
                            takeUntil(unmountSubscriptions$),
                        ),
                    ),
                ),
                takeUntil(unmountSubscriptions$),
            )
            .subscribe(([user, config]) => {
                if (user.sessionRecordingEnabled && config.openReplayProjectKey && config.openReplayIngestPoint) {
                    const tracker = new OpenReplay({
                        projectKey: config.openReplayProjectKey,
                        ingestPoint: config.openReplayIngestPoint,
                        onStart: () => trackEvent('SessionRecordingStarted', SeverityLevel.Information),
                    });
                    if (user.liveAssistEnabled) {
                        tracker.use(
                            trackerAssist({
                                onAgentConnect: () => trackEvent('LiveAssistStarted', SeverityLevel.Information),
                                onCallStart: () => trackEvent('LiveAssistCallStarted', SeverityLevel.Information),
                            }),
                        );
                    }
                    tracker.start({
                        userID: user.email,
                    });
                }
            });
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Layout>
                <Routes>
                    <Route
                        path='/signin-oidc'
                        element={<Navigate to='/' />}
                    />
                    <Route
                        path='/'
                        element={LazyRender(Home)}
                    />
                    <Route
                        path='/p360/patient360'
                        element={LazyRender(Patient360)}
                    />
                    <Route
                        path='/p360/clinicaljourney'
                        element={LazyRender(ClinicalJourney)}
                    />
                    <Route
                        path='/p360/userguidesandtrainings'
                        element={LazyRender(UserGuidesAndTrainings)}
                    />
                    <Route
                        path='/messages'
                        element={LazyRender(MessagesMainTab)}
                    />
                    <Route
                        path='/memberinquiries'
                        element={LazyRender(MemberMessages)}
                    />
                    <Route
                        path='/panelmgt/mypanel'
                        element={LazyRender(MyPanel)}
                    />
                    <Route
                        path='/panelmgt/admin'
                        element={LazyRender(AdminPanel)}
                    />
                    <Route
                        path='/JSA/Assessment/'
                        element={LazyRender(Assessment)}
                    />
                    <Route
                        path='/JSA/Outreach/'
                        element={LazyRender(Outreach)}
                    />
                    <Route
                        path='/JSA/Admin/'
                        element={LazyRender(JSAAdmin)}
                    />
                    <Route
                        path='/dashboards/ipa'
                        element={LazyRender(IPADashboard)}
                    />
                    <Route
                        path='/dashboards/ncipa'
                        element={LazyRender(NCIPADashboard)}
                    />
                    <Route
                        path='/dashboards/pcp'
                        element={LazyRender(PCPDashboard)}
                    />
                    <Route
                        path='/dashboards/star'
                        element={LazyRender(StarDashboard)}
                    />
                    <Route
                        path='/audit'
                        element={LazyRender(AuditHistory)}
                    />
                    <Route
                        path='/providersearch'
                        element={LazyRender(ProviderSearch)}
                    />
                    <Route
                        path='/census/dailycensus'
                        element={LazyRender(DailyCensus)}
                    />
                    <Route
                        path='/census/dailydischarge'
                        element={LazyRender(DailyDischarge)}
                    />
                    <Route
                        path='/census/admission'
                        element={LazyRender(DailyAdmission)}
                    />
                    {/*CareOnDemand Routes*/}
                    <Route
                        path='/careondemand/mypanel'
                        element={LazyRender(MyCoDPanel)}
                    />
                    <Route
                        path='/careondemand/mypanel/:memberId/:pbpEffectiveDate'
                        element={LazyRender(MyCoDPanel)}
                    />
                    <Route
                        path='/careondemand/messagecenter'
                        element={LazyRender(CareOnDemandMessageCenter)}
                    />
                    <Route
                        path='/careondemand/audithistory'
                        element={LazyRender(CareOnDemandAuditHistoryTab, {
                            telehealthModuleType: TELEHEALTH_MODULES.CAREONDEMAND,
                        })}
                    />
                    <Route
                        path='/careondemand/admin'
                        element={LazyRender(CODAppointmentAdmin, {
                            telehealthModuleType: TELEHEALTH_MODULES.CAREONDEMAND,
                        })}
                    />
                    <Route
                        path='/careondemand/livedashboard'
                        element={LazyRender(LiveDashboardTab, {
                            module: modules.CareOnDemand,
                        })}
                    />
                    {/*VirtualPCP Routes*/}
                    <Route
                        path='/virtualpcp/mypanel'
                        element={LazyRender(MyVPCPPanel)}
                    />
                    <Route
                        path='/virtualpcp/messagecenter'
                        element={LazyRender(VirtualVisitsMessageCenter)}
                    />
                    <Route
                        path='/virtualpcp/audithistory'
                        element={LazyRender(VirtualVisitsAuditHistoryTab, {
                            telehealthModuleType: TELEHEALTH_MODULES.VPCP,
                        })}
                    />
                    <Route
                        path='/virtualpcp/livedashboard'
                        element={LazyRender(LiveDashboardTab, { module: modules.VirtualPCP })}
                    />
                    <Route
                        path='/virtualpcp/admin'
                        element={LazyRender(VPCPAppointmentAdmin, {
                            telehealthModuleType: TELEHEALTH_MODULES.VPCP,
                        })}
                    />
                    {/*AvaExpress Routes*/}
                    <Route
                        path='/eligibility/member?/:memberId?'
                        element={LazyRender(EligibilityAndBenefits)}
                    />
                    <Route
                        path='/eligibility/userguide'
                        element={LazyRender(EligibilityuideLines, {
                            module: modules.EligibilityAndBenefits,
                            showIndex: false,
                        })}
                    />
                    <Route
                        path='/ebspecialist'
                        element={LazyRender(EligibilityAndBenefitsSpecialist)}
                    />
                    <Route
                        path='/ebspecialist/userguide'
                        element={LazyRender(EligibilityuideLines, {
                            module: modules.EligibilityAndBenefits,
                            showIndex: false,
                        })}
                    />
                    <Route
                        path='/authorizations'
                        element={LazyRender(Authorization)}
                    />
                    <Route
                        path='/authorizations/userguide'
                        element={LazyRender(AuthorizationGuideLines, {
                            module: modules.Authorizations,
                            showIndex: false,
                        })}
                    />
                    <Route
                        path='/claims'
                        element={LazyRender(Claims)}
                    />
                    <Route
                        path='/claims/userguide'
                        element={LazyRender(ClaimsGuideLines, { module: modules.ClaimsInquiry, showIndex: false })}
                    />
                    <Route
                        path='/agents/eligibility'
                        element={LazyRender(EligibilityAndBenefitsAgent)}
                    />
                    <Route
                        path='/ava/dailycensus'
                        element={LazyRender(AVADailyCensus)}
                    />
                    <Route
                        path='/ava/dailydischarge'
                        element={LazyRender(AVADailyDischarge)}
                    />
                    <Route
                        path='/ava/admission'
                        element={LazyRender(AVADailyAdmit)}
                    />
                    <Route
                        path='/um/authorizations'
                        element={LazyRender(UMAuthorizationWrapper)}
                    />
                    <Route
                        path='/um/authorizations/:memberId'
                        element={LazyRender(UMAuthorizationWrapper)}
                    />
                    <Route
                        path='/um/faxes'
                        element={LazyRender(UMFaxManagement)}
                    />
                    <Route
                        path='/um/admin'
                        element={LazyRender(UMAdmin)}
                    />
                    <Route
                        path='/um/productivity'
                        element={LazyRender(UMProductivityParent)}
                    />
                    <Route
                        path='/um/auth-metrics'
                        element={LazyRender(AuthMetrics)}
                    />
                    <Route
                        path='/agents/claims'
                        element={LazyRender(ClaimsInternal)}
                    />
                    {/*AvaScheduler Routes*/}
                    <Route
                        path='/avascheduler/calendar'
                        element={LazyRender(ProviderCalendar)}
                    />
                    {/*CRM Routes*/}
                    <Route
                        path='/crm/myqueue'
                        element={LazyRender(MyQueue)}
                    />
                    <Route
                        path='/crm/myqueue/:crmItem/:crmItemId'
                        element={LazyRender(MyQueue)}
                    />
                    <Route
                        path='/crm/productivity'
                        element={LazyRender(AgentsProductivity)}
                    />
                    <Route
                        path='/crm/advancedsearch'
                        element={LazyRender(AdvancedSearch)}
                    />
                    <Route
                        path='/crm/dashboards'
                        element={LazyRender(DisenrollmentDashboards)}
                    />
                    <Route
                        path='/crm/campaign-config'
                        element={LazyRender(CampaignConfigurator)}
                    />
                    <Route
                        path='/crm/benefit-config'
                        element={LazyRender(BenefitConfigurator)}
                    />
                    <Route
                        path='/crm/login-accounts/search'
                        element={LazyRender(CrmLoginAccounts)}
                    />
                    <Route
                        path='/crm/member-escalation'
                        element={LazyRender(MemberEscalation)}
                    />
                    <Route
                        path='/crm-admin/text-replace'
                        element={LazyRender(TextReplace)}
                    />
                    <Route
                        path='/crm-admin/case-autoassign'
                        element={LazyRender(CaseReassign)}
                    />
                    <Route
                        path='/crm-admin/form-builder'
                        element={LazyRender(FormBuilder)}
                    />
                    <Route
                        path='/crm-admin/campaigns'
                        element={LazyRender(CampaignHome)}
                    />
                    <Route
                        path='/crm-admin/service-actions'
                        element={LazyRender(CrmServiceActions)}
                    />
                    <Route
                        path='/crm-admin/queues'
                        element={LazyRender(CrmQueues)}
                    />
                    <Route
                        path='/crm-admin/request-plan-materials'
                        element={LazyRender(CrmRequestPlanMaterials)}
                    />
                    <Route
                        path='/crm-admin/sticky-notes'
                        element={LazyRender(CrmStickyNotes)}
                    />
                    <Route
                        path='/crm-admin/communications-manager'
                        element={LazyRender(CommunicationsManager)}
                    />
                    <Route
                        path='/crm-admin/benefits'
                        element={LazyRender(CrmAdminBenefits)}
                    />
                    <Route
                        path='/crm/member-portal-audit-history'
                        element={LazyRender(CRMMemberPortalAdminAuditHistoryPage)}
                    />
                    <Route
                        path='/crm/campaign-stats'
                        element={LazyRender(CampaignStats)}
                    />
                    <Route
                        path='/crm/member-portal-admin-console'
                        element={LazyRender(MemberPortalAdminConsole)}
                    />
                    {/*Encounters Routes*/}
                    <Route
                        path='/encounters/search'
                        element={LazyRender(Encounters)}
                    />
                    {/*ClaimsAuditor Routes*/}
                    <Route
                        path='/claimsauditor/claims'
                        element={LazyRender(PendingClaims)}
                    />
                    <Route
                        path='/claimsauditor/admin'
                        element={LazyRender(ClaimsAuditorAdmin)}
                    />
                    <Route
                        path='/claimsauditor/roles'
                        element={LazyRender(ClaimsAuditorRoles)}
                    />
                    <Route
                        path='/claimsauditor/advancedaudits'
                        element={LazyRender(AdvancedAudits)}
                    />
                    {/*AvaCharts Routes*/}
                    <Route
                        path='/charts/search'
                        element={LazyRender(ChartsSearch)}
                    />
                    <Route
                        path='/charts/upload'
                        element={LazyRender(ChartsUpload)}
                    />
                    <Route
                        path='/charts/splitvalidation'
                        element={LazyRender(ChartsSplitAndValidation)}
                    />
                    <Route
                        path='/charts/coderstars'
                        element={LazyRender(ChartsStarsCoderView)}
                    />
                    <Route
                        path='/charts/starsadmin'
                        element={LazyRender(ChartsStarsAdmin)}
                    />
                    {/*UserManagement Routes*/}
                    <Route
                        path='/usermanagement/users'
                        element={LazyRender(UserManagementUserTab)}
                    />
                    <Route
                        path='/usermanagement/providers'
                        element={LazyRender(UserManagementProviders)}
                    />
                    <Route
                        path='/usermanagement/userevents'
                        element={LazyRender(UserManagementUserEvents)}
                    />
                    <Route
                        path='/usermanagement/groups'
                        element={LazyRender(UserManagementGroupTab)}
                    />
                    <Route
                        path='/usermanagement/organizations'
                        element={LazyRender(UserManagementOrganizationTab)}
                    />
                    <Route
                        path='/usermanagement/userreport'
                        element={LazyRender(UserManagementUserReport)}
                    />
                    {/*PatientPriority Routes */}
                    <Route
                        path='/patientpriority/worklist/'
                        element={LazyRender(PatientPriorityWorkList)}
                    />
                    <Route
                        path='/patientpriority/patients/'
                        element={LazyRender(PatientPriorityAllPatients)}
                    />
                    <Route
                        path='/patientpriority/dashboards/'
                        element={LazyRender(PatientPriorityDashboards)}
                    />
                    <Route
                        path='/patientpriority/guidelines/'
                        element={LazyRender(PatientPriorityGuidelines, {
                            module: modules.PatientPriority,
                            showIndex: true,
                        })}
                    />
                    <Route
                        path='/patientpriority/gapsreview/'
                        element={LazyRender(PatientPriorityGapsReview)}
                    />
                    <Route
                        path='/patientpriority/audit/'
                        element={LazyRender(PatientPriorityAudit)}
                    />
                    {/*Coder360 Routes */}
                    <Route
                        path='/coder360/chartreview/'
                        element={LazyRender(Coder360)}
                    />
                    <Route
                        path='/coder360/submissions/'
                        element={LazyRender(Coder360SubmissionsModule)}
                    />
                    <Route
                        path='/coder360/allcrrs/'
                        element={LazyRender(Coder360MyCRRsAdminModule, { showAllCRRs: true })}
                    />
                    <Route
                        path='/coder360/mycrrs/'
                        element={LazyRender(Coder360MyCRRsModule, { showAllCRRs: false })}
                    />
                    {/*MyOffice Routes*/}
                    <Route
                        path='/myoffice/dashboard'
                        element={LazyRender(Dashboard)}
                    />
                    {/*Telehealth Routes*/}
                    <Route
                        path='/telehealth/mypanel'
                        element={LazyRender(Telehealth)}
                    />
                    <Route
                        path='/telehealth/AppointmentRequests'
                        element={LazyRender(ApptRequests)}
                    />
                    <Route
                        path='/form-builder'
                        element={LazyRender(FormBuilder)}
                    />
                    {/*Ezm Routes*/}
                    <Route
                        path='/ezm/network/service-organization-admin/'
                        element={LazyRender(EzmServiceOrganizationAdmin)}
                    />
                    <Route
                        path='/ezm/network/provider-group-admin/'
                        element={LazyRender(EzmProviderGroupAdmin)}
                    />
                    <Route
                        path='/ezm/member/admin/'
                        element={LazyRender(EzmMemberAdminPage)}
                    />
                    <Route
                        path='/ezm/cms/batch-status-report/'
                        element={LazyRender(EzmBatchJobStatusReport)}
                    />
                    <Route
                        path='/ezm/cms/batch-error-report/'
                        element={LazyRender(EzmBatchJobErrorReport)}
                    />
                    <Route
                        path='/ezm/provider-portal-admin/account-search/'
                        element={LazyRender(EzmProviderPortalAdminReport)}
                    />
                    <Route
                        path='/ezm/provider-portal-admin/registration-manager'
                        element={LazyRender(ProviderToolsAccountEditor)}
                    />
                    <Route
                        path='/ezm/utilities/token-viewer/'
                        element={LazyRender(EzmTokenViewer)}
                    />
                    <Route
                        path='/ezm/health-plan-management/search/'
                        element={LazyRender(EzmHealthPlanManagement)}
                    />
                    <Route
                        path='/ezm/health-plan-management-admin/benefit-editor/'
                        element={LazyRender(EzmHealthPlanManagementBenefit)}
                    />
                    <Route
                        path='/ezm/invalid-member-portal/'
                        element={LazyRender(InvalidMemberEnrollment)}
                    />
                    <Route
                        path='/ezm/eob-notifications-history/'
                        element={LazyRender(EobNotificationsHistoryViewer)}
                    />
                    {/* Provider Tools Routes */}
                    <Route
                        path='/provider-tools-admin'
                        element={LazyRender(ProviderToolsAdmin)}
                    />
                    <Route
                        path='/provider-tools/eligibility-check/'
                        element={LazyRender(ProviderToolsEligibility)}
                    />
                    <Route
                        path='/provider-tools/report-upload/'
                        element={LazyRender(ProviderToolsReportUpload)}
                    />
                    <Route
                        path='/provider-tools/report-upload/userguide'
                        element={LazyRender(ReportsSubmissionGuideLines, {
                            module: modules.ReportsSubmissionort,
                            showIndex: false,
                        })}
                    />
                    <Route
                        path='/ezm/network/contract-info'
                        element={LazyRender(ContractInfo)}
                    />
                    <Route
                        path='/ezm/network/hospital-maintenance'
                        element={LazyRender(HospitalMaintenance)}
                    />
                    <Route
                        path='/ezm/network/template-manager'
                        element={LazyRender(TemplateManager)}
                    />
                    <Route
                        path='/ezm/network/data-import-utility'
                        element={LazyRender(DataImportUtility)}
                    />
                    <Route
                        path='/ezm/medical-group/ipa-administration'
                        element={LazyRender(IpaAdministrationPage)}
                    />
                    <Route
                        path='/ezm/provider-data-update-request'
                        element={LazyRender(ProviderDataUpdateRequest)}
                    />
                    {/* Admin routes */}
                    <Route
                        path='/admin/applications'
                        element={LazyRender(AdminApplications)}
                    />
                    <Route
                        path='/ezm/appeals-grievance'
                        element={LazyRender(AG360)}
                    />
                    <Route
                        path='/content-library'
                        element={LazyRender(ContentLibrary)}
                    />
                    <Route
                        path='/content-manager'
                        element={LazyRender(ContentManager)}
                    />
                    <Route
                        path='/performance-view'
                        element={LazyRender(PerformanceModule)}
                    />
                    <Route
                        path='/admin/static-file-manager'
                        element={LazyRender(StaticFileManager, { id: 'static-file-manager-admin' })}
                    />
                    {/* Broker Portal routes */}
                    <Route
                        path='/broker/reports/*'
                        element={LazyRender(BrokerReports, { id: 'reports' })}
                    />
                    <Route
                        path='/broker/file-manager'
                        element={LazyRender(FileManagerBroker, { id: 'static-file-manager-broker' })}
                    />
                    <Route
                        path='/broker/analytics'
                        element={LazyRender(BrokerAnalytics, {
                            id: 'broker-analytics',
                        })}
                    />
                    <Route
                        path='/broker/crm'
                        element={LazyRender(BrokerCRM, {
                            id: 'broker-crm',
                        })}
                    />
                    <Route
                        path='/broker/commissions'
                        element={LazyRender(BrokerCommissions, {
                            id: 'broker-commissions',
                        })}
                    />
                    <Route
                        path='/broker/admin'
                        element={LazyRender(BrokerUserManager, {
                            id: 'broker-admin',
                        })}
                    />
                    <Route
                        path='/broker/user-guides'
                        element={LazyRender(BrokerUserGuides, {
                            id: 'broker-user-guides',
                        })}
                    />
                    <Route
                        path='/broker/brokers'
                        element={LazyRender(BrokerSearch)}
                    />
                    <Route
                        path='/broker/sales'
                        element={LazyRender(SalesRepSearch)}
                    />
                    {/*CareManagement Routes*/}
                    <Route
                        path='/caremanagement/snp-team-member'
                        element={LazyRender(SNPTeamMeber)}
                    />
                    <Route
                        path='/caremanagement/post-discharge-program-manager'
                        element={LazyRender(PostDischargeProgramManager)}
                    />
                    <Route
                        path='/caremanagement/outpatient-team-member'
                        element={LazyRender(OutpatientTeam)}
                    />
                    <Route
                        path='/caremanagement/outpatient-team-member/:cmItem/:cmItemId'
                        element={LazyRender(OutpatientTeam)}
                    />
                    <Route
                        path='/caremanagement/dashboards'
                        element={LazyRender(CMDashboards)}
                    />
                    <Route
                        path='/caremanagement/paper-hra'
                        element={LazyRender(CMPaperHRA)}
                    />
                    <Route
                        path='/ezm/sales-rep'
                        element={LazyRender(SalesRepAdminPage)}
                    />
                    <Route
                        path='/ezm/certification-session-manager'
                        element={LazyRender(CertificationSessionManager)}
                    />
                    <Route
                        path='/ezm/notification-manager'
                        element={LazyRender(NotificationManagerPage)}
                    />
                    <Route
                        path='/compliancereport/search'
                        element={LazyRender(ComplianceReportSearch)}
                    />
                    <Route
                        path='/webenrollment/search'
                        element={LazyRender(WebEnrollmentsSearch)}
                    />
                    <Route
                        path='/webenrollment/review'
                        element={LazyRender(ReviewWebEnrollment)}
                    />
                    <Route
                        path='/webenrollment/cmseligibilitycheck'
                        element={LazyRender(CMSEligibilityCheck)}
                    />
                    <Route
                        path='/memberEnrollment'
                        element={LazyRender(MemberEnrollment)}
                    />
                    <Route
                        path='/memberEnrollmentUpload'
                        element={LazyRender(MemberEnrollmentUploadAdmin)}
                    />
                    <Route
                        path='/memberEnrollment/ReportsAndUtilities'
                        element={LazyRender(MemberEnrollmentReportAndUtilitiesAdmin)}
                    />
                    <Route
                        path='/memberEnrollment/AdminAndSupport'
                        element={LazyRender(AdminAndSupportAdminPortal)}
                    />
                    <Route
                        path='/return-mail-escalations'
                        element={LazyRender(ReturnMailEscalations)}
                    />
                </Routes>
            </Layout>
        </LocalizationProvider>
    );
};

export default App;
