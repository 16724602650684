import { useMemo } from 'react';

import { Observable, forkJoin, map, of } from 'rxjs';

import { BasicMemberProperties, PotentialHousehold } from '../interfaces/Member';
import { useObservable } from './useObservable';

export const usePotentialHousehold = (
    potentialHousehold: PotentialHousehold[],
    basicMemberPropertyGetter$: (memberIds: string) => Observable<BasicMemberProperties[]>,
): (PotentialHousehold | BasicMemberProperties)[] | null => {
    const getPotentialHousehold$ = useMemo(() => {
        const potentialHouseholdMemberIds = potentialHousehold?.map((s) => s.member)?.join();

        return forkJoin({
            potentialHousehold: of(potentialHousehold),
            additionalPropertiesOfPotentialHousehold: potentialHouseholdMemberIds
                ? basicMemberPropertyGetter$(potentialHouseholdMemberIds)
                : of([]),
        }).pipe(
            map(({ potentialHousehold, additionalPropertiesOfPotentialHousehold }) => {
                if (!potentialHousehold) return [];
                return potentialHousehold.map((ph) => {
                    const matchingAdditionalProperties =
                        additionalPropertiesOfPotentialHousehold?.find((ap) => ap.memberId === ph.member) || {};
                    return {
                        ...ph,
                        ...matchingAdditionalProperties,
                    };
                });
            }),
        );
    }, [potentialHousehold, basicMemberPropertyGetter$]);

    return useObservable<(PotentialHousehold | BasicMemberProperties)[] | null>(getPotentialHousehold$, null);
};
