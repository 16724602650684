import React, { useRef } from 'react';

import {
    faMicrophone,
    faMicrophoneSlash,
    faVideo,
    faVideoSlash,
    faPhone,
    faClipboard,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const VirtualAppointmentsControls = ({
    onClickIconHandler,
    enableAudio,
    enableVideo,
    finishVideoCall,
    onlyAudio,
    handleTranslateLink,
}) => {
    const tooltipRef: any = useRef(null);

    const handleHover = () => {
        tooltipRef.current.content = 'Copy invitation link for translator';
    };

    const handleCopyTranslatorLink = async () => {
        await handleTranslateLink();
        tooltipRef.current.content = 'Text is copied!';
    };

    return (
        <React.Fragment>
            <div
                id='muteIcon'
                className='white-btn'
                onClick={onClickIconHandler}
            >
                {enableAudio ? <FontAwesomeIcon icon={faMicrophone} /> : <FontAwesomeIcon icon={faMicrophoneSlash} />}
            </div>
            {onlyAudio === false ? (
                <div
                    id='videoIcon'
                    className='white-btn'
                    onClick={onClickIconHandler}
                >
                    {enableVideo ? <FontAwesomeIcon icon={faVideo} /> : <FontAwesomeIcon icon={faVideoSlash} />}
                </div>
            ) : null}

            <div
                id='conference-hang-up'
                className='red-btn'
                onClick={finishVideoCall}
            >
                <FontAwesomeIcon icon={faPhone} />
            </div>
            <div onMouseEnter={handleHover}>
                <TooltipComponent
                    ref={tooltipRef}
                    content='Copy invitation link for translator'
                    position='TopCenter'
                >
                    <div
                        id='clipboardIcon'
                        className='white-btn'
                        onClick={handleCopyTranslatorLink}
                    >
                        <FontAwesomeIcon icon={faClipboard} />
                    </div>
                </TooltipComponent>
            </div>
        </React.Fragment>
    );
};

export default VirtualAppointmentsControls;
