import { useEffect, useState } from 'react';

import { DatePickerComponent, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { MultiSelectComponent, Inject, CheckBoxSelection } from '@syncfusion/ej2-react-dropdowns';
import { AxiosError } from 'axios';
import omit from 'lodash/omit';
import moment from 'moment/moment';
import { Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { requests } from 'components/AvaEzm/AxiosApi';
import { IpaCounty } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { ipaPlanService } from 'components/ProviderData/subjects/IpaPlanService';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import { dialogService } from 'subjects/common/DialogService';

export const IpaCountyMappingForm = ({
    IPAPlanData,
    ipaCounty,
    contiguousCounties,
    operatingCounties,
    companyCode,
    ipaCode,
    ipaId,
    isViewer,
}) => {
    const [startDate, setStartDate] = useState<Date>();
    const [overlapError] = useState<string>();
    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        getValues,
        reset,
        formState: { errors },
    } = useForm<any>({
        defaultValues: {
            isActive: true,
        },
    });

    const validateCountySelection = () => {
        const selectedContiguousCounties = getValues('contiguousCounties') || [];
        const selectedOperatingCounties = getValues('operatingCounties') || [];

        const commonCounties = selectedOperatingCounties.filter((county) =>
            selectedContiguousCounties.includes(county),
        );

        if (commonCounties.length > 0) {
            return 'A county cannot be selected as both Operating and Contiguous.';
        }
    };
    const saveInformation = async (data: IpaCounty) => {
        handleDateValid(data.effectiveBeginDate);
        try {
            dialogService.loading(true);
            const _operatingCounties = Array.isArray(data.operatingCounties)
                ? operatingCounties?.filter((o) => data.operatingCounties?.includes(o.countyStateCode))
                : [];
            const _contiguousCounties = Array.isArray(data.contiguousCounties)
                ? contiguousCounties.filter((o) => data.contiguousCounties?.includes(o.countyStateCode))
                : [];
            const contractStartDate = moment(data.effectiveBeginDate).format('MM/DD/YYYY');
            const contractEndDate = moment(data.effectiveEndDate).format('MM/DD/YYYY');
            const adaptedData = omit(
                {
                    ...data,
                    operatingCounties: _operatingCounties,
                    contiguousCounties: _contiguousCounties,
                    effectiveBeginDate: contractStartDate,
                    effectiveEndDate: contractEndDate,
                },
                ['isDeleted'],
            );

            const url = ipaCounty?.id ? `/api/IpaCountyMapping/${ipaCounty?.id}` : `/api/IpaCountyMapping/Create`;
            if (ipaCounty?.id) {
                await requests.put(url, { data: adaptedData });
            } else {
                await requests.post(url, { data: adaptedData });
            }

            dialogService.loading(false);
            dialogService.close();
            ipaPlanService.submit({ error: false });
        } catch (e) {
            const message = `${(e as AxiosError)?.response?.data}`;
            ipaPlanService.submit({ error: true, message });
            dialogService.loading(false);
        }
    };

    useEffect(() => {
        const subscription = ipaPlanService.submit$().subscribe((validated) => {
            if (validated === undefined) {
                handleSubmit(saveInformation)();
            }
        });
        if (ipaCounty.id) {
            for (const c in ipaCounty) {
                if (Array.isArray(ipaCounty[c])) {
                    if (ipaCounty[c]) {
                        setValue(
                            c,
                            ipaCounty[c].map((v) => v.countyStateCode),
                        );
                    }
                } else {
                    setValue(c, ipaCounty[c]);
                }
            }
            setValue('startEndDateExist', false);
        } else {
            reset();
            setValue('ipaCode', ipaCode);
            setValue('companyCode', companyCode);
        }
        setValue('ipaId', ipaId);
        return () => {
            subscription.unsubscribe();
        };
    }, [ipaCounty]);

    const handleDateValid = (value) => {
        const startDate = moment(getValues('effectiveBeginDate')).format('MM DD YYYY');
        const endDate = moment(value).format('MM DD YYYY');
        const match = IPAPlanData.some((item) => {
            return (
                (moment(item.effectiveBeginDate).format('MM DD YYYY') === startDate &&
                    moment(item?.effectiveEndDate).format('MM DD YYYY') === endDate) ||
                new Date(startDate) <= new Date(moment(item?.effectiveEndDate).format('MM DD YYYY'))
            );
        });
        if (!ipaCounty?.id) {
            setValue('startEndDateExist', match);
        }
    };

    return (
        <Row>
            <DisabledWrapper disabled={isViewer}>
                <form
                    className='ipa-tab-form'
                    style={{ padding: '5px' }}
                >
                    <div className='row'>
                        {ipaCounty?.id && (
                            <div className='col-md-4'>
                                <div className='text-value'>
                                    <label className='float-input-field'>IPA ID</label>
                                    <label className='float-input-value'>{ipaId}</label>
                                    <span className='e-float-line' />
                                </div>
                            </div>
                        )}
                        <div className='col-md-4'>
                            <div className='text-value'>
                                <label className='float-input-field'>IPA Code</label>
                                <label className='float-input-value'>{ipaCode}</label>
                                <span className='e-float-line' />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div
                                className='text-value'
                                style={{ margin: '0px' }}
                            >
                                <label className='float-input-field'>Company Code</label>
                                <label className='float-input-value'>{companyCode}</label>
                                <span className='e-float-line' />
                            </div>
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className='col-md-6 mt-3'>
                            <div>
                                <label className='e-label-select mb-2'>Start Date *</label>
                                <DatePickerComponent
                                    format='MM/dd/yyyy'
                                    enableMask={true}
                                    id='calendar_start'
                                    {...register('effectiveBeginDate', { required: 'Start Date is required' })}
                                    change={(e) => {
                                        if (e.isInteracted) {
                                            const startOfMonth = moment(e.value).startOf('month').format();
                                            setValue('effectiveBeginDate', startOfMonth);
                                            setValue('effectiveEndDate', null);
                                            setStartDate(e.value);
                                        }
                                    }}
                                    min={undefined}
                                    max={new Date(9999, 11, 31)}
                                    strictMode
                                    start='Year'
                                    depth='Year'
                                    value={
                                        getValues('effectiveBeginDate') ? getValues('effectiveBeginDate') : undefined
                                    }
                                >
                                    <Inject services={[MaskedDateTime]} />
                                </DatePickerComponent>
                            </div>
                            {errors.effectiveBeginDate && (
                                <p className='error-form'>{errors.effectiveBeginDate.message}</p>
                            )}
                        </div>

                        <div className='col-md-6 mt-3'>
                            <div>
                                <label className='e-label-select mb-2'>End Date *</label>
                                <DatePickerComponent
                                    format='MM/dd/yyyy'
                                    enableMask={true}
                                    id='calendar_end'
                                    {...register('effectiveEndDate', {
                                        required: 'End Date is required',
                                        validate: () =>
                                            getValues('startEndDateExist') === false ||
                                            'Already Exist, Please select different Start Date and End Date',
                                    })}
                                    change={(e) => {
                                        if (e.isInteracted) {
                                            const endOfMonth = moment(e.value).endOf('month').startOf('day').format();
                                            setValue('effectiveEndDate', endOfMonth);
                                            handleDateValid(e.value);
                                            trigger('effectiveEndDate');
                                        }
                                    }}
                                    start='Year'
                                    depth='Year'
                                    min={startDate}
                                    max={new Date(9999, 11, 31)}
                                    strictMode
                                >
                                    <Inject services={[MaskedDateTime]} />
                                </DatePickerComponent>
                            </div>
                            {errors.effectiveEndDate && <p className='error-form'>{errors.effectiveEndDate.message}</p>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6  mt-3 operatingCounties'>
                            <label className='e-label-select mb-2 py-0 '>Operating Counties *</label>
                            <MultiSelectComponent
                                {...register('operatingCounties', {
                                    required: 'Please choose at least one county',
                                    validate: validateCountySelection,
                                })}
                                dataSource={operatingCounties}
                                mode='CheckBox'
                                placeholder='Select a county'
                                change={({ value }) => {
                                    setValue('operatingCounties', value);
                                }}
                                fields={{ value: 'countyStateCode', text: 'countyName' }}
                                allowCustomValue
                                showSelectAll={true}
                                selectAllText='Select All'
                                unSelectAllText='Unselect All'
                            >
                                <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>
                            {errors.operatingCounties && (
                                <p className='error-form'>{errors.operatingCounties.message}</p>
                            )}
                        </div>
                        <div className='col-md-6  mt-3'>
                            <label className='e-label-select mb-2'>Contiguous Counties</label>
                            <MultiSelectComponent
                                {...register('contiguousCounties', {
                                    validate: validateCountySelection,
                                })}
                                dataSource={contiguousCounties}
                                mode='Default'
                                placeholder='Select a county'
                                change={({ value }) => {
                                    setValue('contiguousCounties', value);
                                }}
                                fields={{ value: 'countyStateCode', text: 'countyName' }}
                                allowCustomValue
                            />
                        </div>
                    </div>
                </form>
            </DisabledWrapper>
            <p className='error-form'>{overlapError}</p>
        </Row>
    );
};
