import { Subject } from 'rxjs';

import { Popup } from 'interfaces/Popup';

const popupWindowSubject = new Subject();
const popupWindowCloseSubject = new Subject<void>();

const popupWindowService = {
    open: (popup: Popup) => popupWindowSubject.next(popup),
    close: () => popupWindowCloseSubject.next(),
    getClose: () => popupWindowCloseSubject.asObservable(),
    get: () => popupWindowSubject.asObservable(),
};

export { popupWindowService };
