import {
    BehaviorSubject,
    Observable,
    combineLatest,
    ReplaySubject,
    filter,
    map,
    startWith,
    mergeMap,
    merge,
    Subject,
    connectable,
} from 'rxjs';

import {
    talkdeskConstants as constants,
    talkdeskFactory as factory,
    talkdeskParser as parser,
    talkdeskValidator as validator,
} from '../../components/Common/TalkdeskUtils';
import { InboundCallEvent, TalkdeskCallData } from '../../interfaces/Talkdesk';

const inboundCallStartedMessages = new BehaviorSubject<MessageEvent>({} as MessageEvent);
const inboundCallEndedMessages = new BehaviorSubject<MessageEvent>({} as MessageEvent);

// Inbound call started, not exported. See inboundCalls$
const inboundCallStarted$: Observable<TalkdeskCallData> = inboundCallStartedMessages.pipe(
    filter((e) => validator.isTalkdeskMessage(e, constants.openContactMessageEvent)),
    map((e) => parser.openContactToCallData(e)),
    filter((e) => validator.isTalkdeskEvent(e, constants.inboundCallStartedEvent)),
);

// Inbound call ended, not exported. See inboundCalls$
const inboundCallEnded$: Observable<TalkdeskCallData> = inboundCallEndedMessages.pipe(
    // if an empty event is not added, the combine latest won't work for the first call
    startWith(factory.mockSendDataMessage(constants.sendDataMessageEvent, constants.inboundCallEndedEvent)),
    filter((e) => validator.isTalkdeskMessage(e, constants.sendDataMessageEvent)),
    map((e) => parser.sendDataToCallData(e)),
    filter((e) => validator.isTalkdeskEvent(e, constants.inboundCallEndedEvent)),
);

const memberSelected$ = new Subject<string>();

// Inbound calls observer, it lets you know if the current call is ongoing or not based on the interactionId
// Inbound call requested, started & ended events are sequential for a given agent, it's safe to do this.
const inboundCalls$ = combineLatest([inboundCallStarted$, inboundCallEnded$]).pipe(
    map(([started, ended]) => {
        const isNotDefaultCall = ended.interaction_id !== '0';
        const callEnded = started.interaction_id === ended.interaction_id;
        const callEvent: InboundCallEvent = {
            interaction_id: started.interaction_id,
            contact_phone_number: started.contact_phone_number,
            ended: callEnded && isNotDefaultCall,
            //// because of the testing difficulty we will not rely on Talkdesk's data dip
            // member_id: started.member_id,
            // birthDate: started.birthDate,
            // gender: started.gender,
            // member_status: started.member_status,
            // first_name: started.first_name,
            // middle_name: started.middle_name,
            // last_name: started.last_name,
            // potential_household: started.potential_household ?? [],
        };
        return callEvent;
    }),
);

const simulatedCalls$ = new Subject<InboundCallEvent>();

// Always listen for the incoming call -> member selection pair
const inboundCallThenMemberSelect$ = connectable(
    merge(inboundCalls$, simulatedCalls$).pipe(
        mergeMap(
            (inboundCallEvent: InboundCallEvent): Observable<InboundCallEvent> =>
                memberSelected$.pipe(map((memberId) => ({ ...inboundCallEvent, member_id: memberId }))),
        ),
    ),
    { connector: () => new ReplaySubject(1) },
);

const talkdeskInboundService = {
    initialize: () => inboundCallThenMemberSelect$.connect(),
    /**
     * A behavior subject which will multicast the CTI's MessageEvents (inbound call started)
     */
    inboundCallStartedMessages,
    /**
     * A behavior subject which will multicast the CTI's MessageEvents (inbound call ended)
     */
    inboundCallEndedMessages,
    /**
     * An observable for inbound calls. You can tell if the latest inbound call
     * is ongoing or has finished using TalkdeskCallEvent.ongoing
     * @returns @link TalkdeskCallEvent
     */
    inboundCallMessages$: (): Observable<InboundCallEvent> => merge(inboundCalls$, simulatedCalls$),

    inboundCallMemberSelected$: (): Observable<InboundCallEvent> => inboundCallThenMemberSelect$,

    selectMember: (memberId: string) => memberSelected$.next(memberId),

    simulateCall: (inboundCall: InboundCallEvent) => simulatedCalls$.next(inboundCall),
};

/*
MODULES USSING THI SUBJECT
    - CRM
    - Patient 360
    - Talkdesk
*/

export { talkdeskInboundService };
