import { GaussianBlurBackgroundProcessor } from '@twilio/video-processors';
import get from 'lodash/get';
import Video from 'twilio-video';

import { ajaxGet, ajaxPost } from 'common/ajax';
import { getDeviceOS } from 'common/utility';

import { TELEHEALTH_MODULES_TO_URL } from './MyPanelHelpers';
let videoProcessor;

const forEachTrack = (stream, action) => {
    //action: enable diable stop
    if (!stream) {
        return;
    }
    stream.forEach(function (trackPublication) {
        if (trackPublication.track && typeof trackPublication.track[action] === 'function')
            trackPublication.track[action]();
    });
};

export const handlerstate = (room, streamType, isAble) => {
    const actionName = !isAble ? 'enable' : 'disable';
    const stream = get(room, `localParticipant[${streamType}Tracks]`, null);

    forEachTrack(stream, actionName);
};
export const BACKGROUND_FILTER_VIDEO_CONSTRAINTS = {
    width: 640,
    height: 480,
    frameRate: 24,
};

export const enableBlurBackground = async (room) => {
    const videoTrack = room.localParticipant.videoTracks.entries().next().value[1].track;
    if (videoTrack && videoTrack.processor != null) {
        videoTrack.removeProcessor(videoTrack.processor);
        return;
    }

    console.log(process.env.PUBLIC_URL + '/virtualbackground');

    videoProcessor = new GaussianBlurBackgroundProcessor({
        assetsPath: process.env.PUBLIC_URL + '/virtualbackground',
        blurFilterRadius: 25,
        maskBlurRadius: 25,
    });
    await videoProcessor.loadModel();

    const isDesktopChrome = /Chrome/.test(navigator.userAgent);
    const setCaptureConstraints = async () => {
        const { mediaStreamTrack, processor } = videoTrack ?? {};
        if (videoTrack.processor == null && processor) {
            return mediaStreamTrack?.applyConstraints(getVideoSettings());
        } else if (videoTrack.processor != null && !processor) {
            return mediaStreamTrack?.applyConstraints(BACKGROUND_FILTER_VIDEO_CONSTRAINTS);
        }
    };
    const removeProcessor = () => {
        if (videoTrack && videoTrack.processor) {
            videoTrack.removeProcessor(videoTrack.processor);
        }
    };

    const addProcessor = (processor) => {
        if (!videoTrack || videoTrack.processor === processor) {
            return;
        }
        removeProcessor();
        videoTrack.addProcessor(processor, {
            inputFrameBufferType: 'video',
            outputFrameBufferContextType: 'webgl2',
        });
    };

    if ((videoTrack.processor && !videoTrack.processor._isSimdEnabled) || isDesktopChrome) {
        await setCaptureConstraints();
    }

    addProcessor(videoProcessor);
};

export const disconnectRoom = (currentRoom) => {
    return new Promise((resolve) => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
            const stream = currentRoom.localParticipant.tracks || null;
            forEachTrack(stream, 'stop');
            currentRoom.disconnect();
            return resolve(null);
        } else {
            return resolve(currentRoom);
        }
    });
};

export const leaveConversation = (conversation) => {
    return new Promise((resolve) => {
        if (conversation) {
            conversation.leave();
            return resolve(null);
        } else {
            return resolve(conversation);
        }
    });
};

export const getVideoSettings = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return { height: 480, frameRate: 24, width: 640 };
    } else {
        return { height: 720, frameRate: 24, width: 1280 };
    }
};

export const getRoomInfo = async (
    token,
    dataTrackPromise,
    setParticipants,
    roomName,
    onlyAudio = false,
    audioPermission = true,
    videoPermission = true,
) => {
    const dataTrack = new Video.LocalDataTrack();

    dataTrackPromise.current.promise = new Promise((resolve, reject) => {
        dataTrackPromise.current.resolve = resolve;
        dataTrackPromise.current.reject = reject;
    });

    const tracks = await Video.createLocalTracks({
        audio: audioPermission,
        video: !onlyAudio && videoPermission,
        data: true,
    });

    const participantConnected = (participant) => {
        setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
        setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
    };

    const trackPublished = (publication) => {
        if (publication.track === dataTrack) {
            dataTrackPromise.current.resolve();
        }
    };

    const trackPublicationFailed = (error, track) => {
        if (track === dataTrack) {
            dataTrackPromise.current.reject(error);
        }
    };

    const room = await new Promise((resolve, reject) => {
        Video.connect(token, {
            name: roomName,
            tracks: [dataTrack, ...tracks],
            video: getVideoSettings(),
            maxAudioBitrate: 16000,
            networkQuality: {
                local: 1,
                remote: 1,
            },
        })
            .then((room) => {
                room.on('participantConnected', participantConnected);
                room.on('participantDisconnected', participantDisconnected);
                room.participants.forEach(participantConnected);

                room.localParticipant.on('trackPublished', trackPublished);
                room.localParticipant.on('trackPublicationFailed', trackPublicationFailed);

                resolve(room);
            })
            .catch(() => {
                reject(null);
            });
    });

    return {
        room,
        dataTrack,
    };
};

export const getParticipantName = (identity, params) => {
    const { member, optionalAttendees } = params;

    if (identity === member.memberConnectionId) {
        return member.memberFullName;
    }

    const optionalAttendee = optionalAttendees.find((attendee) => {
        return attendee.connectionId === identity;
    });

    if (optionalAttendee) {
        return optionalAttendee.fullName;
    }

    return identity;
};

export const getPhoneNumber = (phoneSubscription, setPhoneNumber, telehealthModuleType) => {
    phoneSubscription.current = ajaxGet(
        `/api/${TELEHEALTH_MODULES_TO_URL[telehealthModuleType]}/DialInPhoneNumber`,
    ).subscribe({
        next: (response) => {
            if (response) {
                setPhoneNumber(response.response);
            }
        },
        error: console.error,
    });
};

export const saveAuditTrail = (payload) => {
    payload = { ...payload, device: getDeviceOS() };
    ajaxPost(`api/${TELEHEALTH_MODULES_TO_URL[payload.telehealthModuleType]}/TrackVisitEvent`, payload).subscribe({
        next: ({ response }) => {
            if (response) {
                return response;
            }
        },
        error: console.log,
    });
};

export const getTranslatorLink = (
    translatorLink,
    translatorSubscription,
    appointmentId,
    memberId,
    setTranslatorLink,
    telehealthModuleType,
) => {
    if (translatorLink) {
        navigator.clipboard.writeText(translatorLink || '');
    } else {
        translatorSubscription.current = ajaxGet(
            `/api/${TELEHEALTH_MODULES_TO_URL[telehealthModuleType]}/TranslatorLink?appointmentId=${appointmentId}&memberId=${memberId}`,
        ).subscribe({
            next: (response) => {
                if (response.response) {
                    window.focus();
                    navigator.clipboard.writeText(response.response || '');
                    setTranslatorLink(response.response);
                }
            },
            error: console.error,
        });
    }
};

export const spinnerButtonAnimation = {
    stopped: 'stopped',
    active: 'active',
    duration: 60000,
    positionRight: { position: 'Right' },
};
