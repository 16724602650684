import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import {
    talkdeskConstants as constants,
    talkdeskFactory as factory,
    talkdeskParser as parser,
    talkdeskValidator as validator,
} from '../../components/Common/TalkdeskUtils';
import { OutboundCallData, OutboundCallEvent, TalkdeskCallData } from '../../interfaces/Talkdesk';

const outboundCallRequestedMessages = new BehaviorSubject<OutboundCallData>({} as OutboundCallData);
const outboundCallStartedMessages = new BehaviorSubject<MessageEvent>({} as MessageEvent);
const outboundCallEndedMessages = new BehaviorSubject<MessageEvent>({} as MessageEvent);
const outboundCallSavedMessages = new BehaviorSubject<boolean>(false);

// Outbound call requested, not exported. See outboundCalls$
const outboundCallRequested$: Observable<OutboundCallData> = outboundCallRequestedMessages.pipe(
    filter(validOutboundCallData),
);

function validOutboundCallData(data: OutboundCallData): boolean {
    return data !== null && data.member_id !== null && data.contact_phone_number !== null;
}

// Outbound call started, not exported. See outboundCalls$
const outboundCallStarted$: Observable<TalkdeskCallData> = outboundCallStartedMessages.pipe(
    // if an empty event is not added, the combine latest won't work for the first call
    startWith(factory.mockSendDataMessage(constants.sendDataMessageEvent, constants.outboundCallStartedEvent)),
    filter((e) => validator.isTalkdeskMessage(e, constants.sendDataMessageEvent)),
    map((e) => parser.sendDataToCallData(e)),
    filter((e) => validator.isTalkdeskEvent(e, constants.outboundCallStartedEvent)),
);

// Outbound call ended, not exported. See outboundCalls$
const outboundCallEnded$: Observable<TalkdeskCallData> = outboundCallEndedMessages.pipe(
    // if an empty event is not added, the combine latest won't work for the first call
    startWith(factory.mockSendDataMessage(constants.sendDataMessageEvent, constants.outboundCallEndedEvent)),
    filter((e) => validator.isTalkdeskMessage(e, constants.sendDataMessageEvent)),
    map((e) => parser.sendDataToCallData(e)),
    filter((e) => validator.isTalkdeskEvent(e, constants.outboundCallEndedEvent)),
);

const outboundCalls$ = combineLatest([
    outboundCallRequested$,
    outboundCallStarted$,
    outboundCallEnded$,
    outboundCallSavedMessages,
]).pipe(
    map(([request, started, ended, saved]) => {
        const isNotDefaultCall = ended.interaction_id !== '0';
        const callEnded = started.interaction_id === ended.interaction_id;
        const callEvent: OutboundCallEvent = {
            userId: request.userId,
            member_id: request.member_id,
            contact_phone_number: request.contact_phone_number,
            memberName: request.memberName,
            interaction_id: started.interaction_id,
            ended: callEnded && isNotDefaultCall,
            saved: saved,
        };
        return callEvent;
    }),
);

const talkdeskOutboundService = {
    /**
     * A behavior subject which will multicast the CTI's MessageEvents (inbound call ended)
     */
    outboundCallStartedMessages,
    /**
     * A behavior subject which will multicast the CTI's MessageEvents (inbound call ended)
     * Do not subscribe directly to these events. Use
     */
    outboundCallEndedMessages,
    /**
     * Calls to the specified phone number using the CTI integration
     * @param userId the user who started the call
     * @param member_id the member who is going to receive the call
     * @param phoneNumber phone number to be called
     * @param memberName the name of the member
     */
    call: (userId: string, member_id: string, phoneNumber: string, memberName: string | null) => {
        outboundCallRequestedMessages.next({
            userId: userId,
            member_id: member_id,
            contact_phone_number: phoneNumber,
            memberName: memberName,
        });
        outboundCallSavedMessages.next(false);
    },
    /**
     * An observable for AVA click to call requests. The Talkdesk.tsx component handles
     * these messages and sends them through the CTI to Talkdesk, starting a call.
     * @returns An observable with OutboundCallData
     */
    outboundCallRequestedMessages$: (): Observable<OutboundCallData> => outboundCallRequested$,
    /**
     * Combines all outbound call observables (requested, started, ended, saved) and
     * emits updated information of the current outbound call.
     * @returns An observable with OutboundCallEvent
     */
    outboundCallMessages$: (): Observable<OutboundCallEvent> => outboundCalls$,
    outboundCallSaved$: () => outboundCallSavedMessages.next(true),
};

/*
MODULES USSING THIS SUBJECT
    - CRM
    - TaskDesk
    - Telehealth
*/

export { talkdeskOutboundService };
