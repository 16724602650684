import { DataUtil, ODataV4Adaptor } from '@syncfusion/ej2-data';

import Auth from 'Auth';
import { notificationService } from 'subjects/common/NotificationService';

export class ExtendedODataV4Adaptor extends ODataV4Adaptor {
    onPredicate(predicate) {
        if (predicate.operator === 'any') {
            if (predicate.value.length > 0 && Array.isArray(predicate.value)) {
                // This logic has to been added to ensure any works with list of objects and not only a list of simple strings
                // Filter by is used to specify with prop of the objects it array should be taken to do the comparison
                if (predicate.field.includes(':filterBy:')) {
                    const fields = predicate.field.split(':filterBy:');
                    return predicate.value
                        .map((singleValue) => {
                            if (singleValue == 'Blank') {
                                return `not ${fields[0]}/any()`;
                            } else {
                                return `${fields[0]}/any(x: x/${fields[1]} eq '${singleValue}')`;
                            }
                        })
                        .join(' or ');
                } else {
                    return predicate.value
                        .map((singleValue) => `${predicate.field}/any(x: x eq '${singleValue}')`)
                        .join(' or ');
                }
            } else {
                return `${predicate.field}/any(d:contains(d,'${predicate.value}'))`;
            }
        }
        if (predicate.field !== undefined && predicate.field.includes('/')) {
            const fields = predicate.field.split('/');
            const operator = DataUtil.odBiOperator[predicate.operator];
            return fields[0] + `/any(x: x/${fields[1]} ${operator} '${predicate.value}')`;
        }

        // hack for cascading dropdown campaign filter on outbound and case tab
        if (['memberCompanyId', 'companyId'].includes(predicate.field) &&
            predicate.value.includes('ALL')
        ) {
            return `${predicate.field} eq ${predicate.field}`;
        }

        return super.onPredicate.apply(this, arguments);
    }

    beforeSend(dm, request, settings) {
        if (Auth.isExpired()) {
            notificationService.error({
                title: 'Session Expired',
                content: 'Please login again to re-authorize',
            });
            return;
        }
        if (settings.url.includes('/odata/crm/campaigns') && settings.type === 'PATCH') {
            const body = JSON.parse(settings.data);

            for (const member in body) {
                if (member.includes('richtexteditor')) {
                    delete body[member];
                    break;
                }
            }
            settings.data = JSON.stringify(body);
        }
        super.beforeSend.apply(this, arguments);
    }
}
