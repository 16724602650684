import { useEffect, useRef, useState, useCallback } from 'react';

import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent, MaskedDateTime, Inject } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { AxiosError } from 'axios';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

import { requests } from 'components/AvaEzm/AxiosApi';
import {
    defaultValuesCapRates,
    ESRDTypes,
} from 'components/ProviderData/IpaAdministration/atoms/IpaAdministration.Metadata';
import { CapRateDetail } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { fillWithZeros } from 'components/ProviderData/IpaAdministration/atoms/utils';
import { submitCapRateService } from 'components/ProviderData/subjects/IpaAdministrationService';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import { dialogService } from 'subjects/common/DialogService';

type CapRatesProps = {
    ipaCode: string;
    ipaId?: number;
    companyCode: string;
    id?: number;
    sequence?: number;
    capRate?: CapRateDetail;
    prevDatesCap:
        | {
              effDate: Date | string;
              endDate: Date | string;
          }[]
        | null;
    ipaInfo: {
        dates: {
            effDate: Date;
            endDate: Date;
        };
        ipaPhone: string;
    };
    maxEndDate?: Date | undefined;
    isReadOnlyViewer: boolean;
};

export const CapRatesForm = (props: CapRatesProps) => {
    const { ipaCode, ipaId, companyCode, id, sequence, capRate, ipaInfo, prevDatesCap, maxEndDate, isReadOnlyViewer } =
        props;
    const { dates } = ipaInfo;
    const [conditionalValues, setConditionalValues] = useState<any>({});

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        getValues,
        reset,
        formState: { errors, isDirty },
        control,
        watch,
        trigger,
    } = useForm<CapRateDetail | any>({});
    const [capRevenues, setCapRevenues] = useState<any[]>([]);
    const [capRevenuesMP, setCapRevenuesMP] = useState<any[]>([]);
    const toastRef = useRef<ToastComponent>(null);
    const [formula, setFormula] = useState<string>();
    const [touchConditionalValues, setTouchConditionalValues] = useState(true);
    const values = getValues();
    const {
        isMemberPremiumApplicable,
        isEsrdApplicable,
        isVirtualApplicable,
        isPreferredProviderSearch,
        isValueBasedFlag,
        isAcaTax,
        isRestrictedPcpSearch,
        isAverageFloorLevel,
        isIndividualFloorLevel,
        isAverageFloorIPA,
        isIpaGroupLevel,
    } = conditionalValues;
    const conditionalCheckboxesIPA = ['isAverageFloorPCP', 'isAverageFloorBenefit'];
    const minDate =
        prevDatesCap && prevDatesCap?.length > 0 && !capRate?.id
            ? prevDatesCap?.find((p) => p)?.endDate
            : dates?.effDate;
    const fetchCapRevenuesValues = async (companyCode: string, type: string) => {
        let _capRevenues = await requests.get<any[]>(
            `/api/CapRate/GetCapRevenueFormulas?companyCode=${companyCode}&type=${type}`,
        );
        _capRevenues = _capRevenues.map((c) => ({ ...c, text: `${c.code} - ${c.description}` }));
        if (type === 'CMS') {
            setCapRevenues(_capRevenues);
        } else {
            setCapRevenuesMP(_capRevenues);
        }
    };

    const setConditionalValue = (name: string, value: boolean) => {
        setTouchConditionalValues(false);
        setConditionalValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (conditionalCheckboxesIPA.includes(name) && isAverageFloorIPA) {
            setValue(name, true);
            for (const checkbox of conditionalCheckboxesIPA) {
                if (checkbox !== name) {
                    setValue(checkbox, false);
                }
            }
        }
    };

    const resetForm = () => {
        reset(defaultValuesCapRates as any);
    };

    const saveInformation = async (data: CapRateDetail) => {
        try {
            const url = `/api/CapRate/`;
            let action = 'AddNewCapRate';
            const sequence = values.sequence ? values.sequence : 0;
            let productCode = '';
            if (Array.isArray(data.productCode)) {
                productCode = data.productCode.join();
            }
            const effDt = new Date(data.effDate);
            const endDt = new Date(data.endDate);
            data.effDate = `${effDt.getMonth() + 1}/${effDt.getDate()}/${effDt.getFullYear()}`;
            data.endDate = `${endDt.getMonth() + 1}/${endDt.getDate()}/${endDt.getFullYear()}`;
            const formattedData = {
                ...data,
                productCode,
                ipaCode,
                sequence,
                ipaWebId: ipaId,
                formula,
                companyCode,
                createDate: new Date(),
                updateDt: new Date(),
            };
            if (sequence) {
                action = 'OverwriteExistingCapRate';
            }
            await requests.post(`${url}${action}`, formattedData);
            submitCapRateService.submit();
            dialogService.close();
        } catch (e) {
            const message = `${(e as AxiosError)?.response?.data}`;
            toastRef.current?.show({
                title: 'Error',
                content: message,
                cssClass: 'e-toast-danger',
            });
        }
    };
    const ControlledCheckbox = useCallback(
        ({ label, name, change, disabled }: any) => (
            <Controller
                render={({ field }) => {
                    return (
                        <div>
                            <CheckBoxComponent
                                {...register(name)}
                                label={label}
                                checked={field.value}
                                disabled={disabled}
                                onChange={({ target }) => {
                                    const {
                                        changedProperties: { checked },
                                    } = target;
                                    setValue(name, checked);
                                    if (change) {
                                        change(checked);
                                    }
                                }}
                            />
                        </div>
                    );
                }}
                control={control}
                name={name}
            />
        ),
        [conditionalValues],
    );

    const ControlledMaskInput = useCallback(
        ({ name, decimals, label, enabled }: any) => {
            let regex = /^[0-9]*$/;
            const defaultValue = `0.${'0'.padEnd(decimals, '0')}`;
            if (decimals === 4) {
                regex = /^[0-9]*\.[0-9]{4}$/;
            } else if (decimals === 2) {
                regex = /^[0-9]*\.[0-9][0-9]$/;
            }
            return (
                <Controller
                    render={() => (
                        <div>
                            <div className={`e-float-input e-input-group ${!enabled ? 'disable-field' : ''}`}>
                                <input
                                    {...register(name, { pattern: regex })}
                                    id={name}
                                    onBlur={({ target }) => {
                                        if (decimals && !regex.test(target.value)) {
                                            const defaultDecimals = `.${'0'.padEnd(decimals, '0')}`;
                                            const valueFilled = fillWithZeros(target.value, decimals);
                                            const valueSplitted = target.value ? `${target.value}`.split('.') : '';
                                            if (target.value === '') {
                                                setValue(name as keyof CapRateDetail, defaultValue);
                                            } else if (isNaN(Number(target.value))) {
                                                setError(name, {
                                                    type: 'custom',
                                                    message: `Format ${defaultValue}`,
                                                });
                                            } else if (valueSplitted[1] && valueSplitted[1].length > decimals) {
                                                setError(name, {
                                                    type: 'custom',
                                                    message: `Format 0${defaultDecimals}`,
                                                });
                                            } else if (valueSplitted && valueFilled) {
                                                setValue(name as keyof CapRateDetail, valueFilled);
                                                clearErrors(name);
                                            }
                                        } else {
                                            clearErrors(name);
                                        }
                                    }}
                                    placeholder={decimals ? defaultValue : '0'}
                                />
                                <span className='e-float-line'></span>
                                <label className='e-float-text'>{label}</label>
                            </div>
                            {errors[name] && <p className='error-form'> {errors[name].message}</p>}
                        </div>
                    )}
                    control={control}
                    name={name}
                />
            );
        },
        [errors],
    );

    useEffect(() => {
        fetchCapRevenuesValues(companyCode, 'CMS');
        fetchCapRevenuesValues(companyCode, 'MP');
        const service = submitCapRateService.get$().subscribe(({ type }) => {
            if (type === 'add') {
                resetForm();
            }
        });
        return () => {
            toastRef.current?.destroy();
            service.unsubscribe();
        };
    }, [companyCode]);

    useEffect(() => {
        if (!isRestrictedPcpSearch && !isPreferredProviderSearch) {
            setValue('productCode', '');
        }
    }, [conditionalValues]);

    useEffect(() => {
        if (capRate && (capRate.id || capRate.isCopy)) {
            const fields = getValues();
            const _conditionalValues = {};
            for (const field in fields) {
                if (capRate[field] !== undefined) {
                    const value = capRate[field];
                    if (field === 'productCode') {
                        setValue(field as keyof CapRateDetail, value.split(',').length ? value.split(',') : []);
                    } else if (typeof value === 'boolean') {
                        _conditionalValues[field] = value;
                        setValue(field as keyof CapRateDetail, value);
                    } else if (typeof value === 'number') {
                        const decimals = `${defaultValuesCapRates[field]}`.split('.')[1];
                        setValue(
                            field as keyof CapRateDetail,
                            value && decimals ? fillWithZeros(value, decimals.length) : defaultValuesCapRates[field],
                        );
                    } else {
                        setValue(field as keyof CapRateDetail, value);
                    }
                }
            }
            setConditionalValues(_conditionalValues);
            setValue('esrdType ', capRate.esrdType);
            setValue('sequence', sequence as number);
            trigger();
        } else if (!capRate?.id) {
            resetForm();
            setValue('effDate', moment(minDate).add(1, 'day').toDate());
            setValue('endDate', dates?.endDate);

            trigger();
        }
    }, [capRate]);
    const uncheckAvergaFloorLevelValues = (nameToUncheck) => {
        setValue(nameToUncheck, false);
        setValue('isAverageFloorPCP', false);
        setValue('isAverageFloorIPA', false);
        setValue('isAverageFloorBenefit', false);
        setValue('isEsrdExclusion', false);
        setValue('isVirtualExclusion', false);
        setValue('isIpaGroupLevel', false);
        setValue('childIpas', '');
        setValue('floorMC', '0000.00');
        setValue('floorMM', '0000.00');
    };

    const validateCapRecordDates = (field: string, date: Date) => {
        const fieldName = field === 'effDate' ? 'effective' : 'end';
        if (prevDatesCap) {
            for (const prevDate of prevDatesCap) {
                const effDate = moment(prevDate?.effDate).startOf('day');
                const endDate = moment(prevDate?.endDate).startOf('day');
                const dateToCompare = moment(date);
                if (prevDate?.endDate && dateToCompare >= effDate && dateToCompare <= endDate) {
                    setError(field, {
                        message: `The ${fieldName} date range should not overlap with date range of another record.`,
                    });
                    return;
                } else {
                    clearErrors(field);
                }
            }
        }
    };
    const FormFields = useCallback(
        () => (
            <>
                <Row className='center-fields mb-2'>
                    <p className='section-container-header header-margin'></p>
                    <Col md={3}>
                        <TooltipComponent content={`Min Date: ${moment(minDate).format('MM/DD/yyyy')}`}>
                            <div>
                                <label className='e-label-select'>Effective Date</label>
                                <DatePickerComponent
                                    {...register('effDate')}
                                    id='effDate'
                                    change={({ value }) => {
                                        setValue('effDate', value);
                                        validateCapRecordDates('effDate', value);
                                    }}
                                    format='MM/dd/yyyy'
                                    enableMask={true}
                                    min={minDate as Date}
                                    max={dates?.endDate}
                                    strictMode
                                >
                                    <Inject services={[MaskedDateTime]} />
                                </DatePickerComponent>
                                {errors.effDate && <p className='error-form'>{errors.effDate.message}</p>}
                            </div>
                        </TooltipComponent>
                    </Col>
                    <Col md={3}>
                        <TooltipComponent content={`Max Date: ${moment(dates?.endDate).format('MM/DD/yyyy')}`}>
                            <div>
                                <label className='e-label-select'>End Date</label>
                                <DatePickerComponent
                                    {...register('endDate')}
                                    id='endDate'
                                    change={({ value }) => {
                                        setValue('endDate', value);
                                        validateCapRecordDates('endDate', value);
                                    }}
                                    format='MM/dd/yyyy'
                                    min={getValues().effDate}
                                    enableMask={true}
                                    max={dates?.endDate}
                                    strictMode
                                >
                                    <Inject services={[MaskedDateTime]} />
                                </DatePickerComponent>
                                {errors.endDate && <p className='error-form'>{errors.endDate.message}</p>}
                            </div>
                        </TooltipComponent>
                    </Col>
                    <Col md={3}>
                        <div className='e-float-input e-input-group'>
                            <input
                                type='text'
                                {...register('spec')}
                                maxLength={7}
                            />
                            <span className='e-float-line'></span>
                            <label className='e-float-text'>Spec 1</label>
                        </div>
                        {errors.spec && <p className='error-form'> {errors.spec.message}</p>}
                    </Col>
                    <Col md={3}>
                        <div className='e-float-input e-input-group'>
                            <input
                                type='text'
                                {...register('spec2')}
                                maxLength={7}
                            />
                            <span className='e-float-line'></span>
                            <label className='e-float-text'>Spec 2</label>
                        </div>
                        {errors.spec2 && <p className='error-form'> {errors.spec2.message}</p>}
                    </Col>
                </Row>
                <Row className='center-fields mb-2'>
                    <Col md={3}>
                        <div>
                            <label className='e-label-select'> Cap Revenue </label>
                            <DropDownListComponent
                                id='capRevenue'
                                {...register('capRevCode', {
                                    required: 'Cap Revenue is required',
                                })}
                                dataSource={capRevenues}
                                placeholder='Select a Cap Revenue'
                                fields={{ value: 'code', text: 'text' }}
                                change={({ itemData }) => {
                                    setValue('capRevCode', itemData?.code);
                                    setFormula(itemData?.description);
                                }}
                                popupHeight='220px'
                                popupWidth='900px'
                            />
                        </div>
                        {errors.capRevCode && <p className='error-form'> {errors.capRevCode.message}</p>}
                    </Col>
                    <Col md={3}>
                        <ControlledMaskInput
                            label='Premium PCT'
                            name='premiumPCT'
                            decimals={4}
                            value={capRate?.premiumPCT}
                            enabled={true}
                        />
                    </Col>
                    <Col md={3}>
                        <ControlledMaskInput
                            label='Medi Medi PCT'
                            name='mediMediPCT'
                            decimals={4}
                            value={capRate?.mediMediPCT}
                            enabled={true}
                        />
                    </Col>
                    <Col md={3}>
                        <ControlledMaskInput
                            label='Admin Fee'
                            name='adminFee'
                            decimals={4}
                            value={capRate?.adminFee}
                            enabled={true}
                        />
                    </Col>
                </Row>
                <Row className='center-fields mb-2'>
                    <Col md={3}>
                        <ControlledMaskInput
                            label='PM PM Amt'
                            name='pmPmAmt'
                            decimals={2}
                            value={capRate?.pmPmAmt}
                            enabled={true}
                        />
                    </Col>
                    <Col md={3}>
                        <ControlledMaskInput
                            label='PM PM Amt (MM)'
                            name='pmPmAmtMm'
                            decimals={2}
                            value={capRate?.pmPmAmtMm}
                            enabled={true}
                        />
                    </Col>
                    <Col md={3}>
                        <ControlledMaskInput
                            label='Hospital Surplus Split'
                            name='hospSurplusSplit'
                            decimals={4}
                            value={capRate?.hospSurplusSplit}
                            enabled={true}
                        />
                    </Col>
                    <Col md={3}>
                        <ControlledMaskInput
                            label='Prof SurplusSplit'
                            name='profSurplusSplit'
                            decimals={4}
                            value={capRate?.profSurplusSplit}
                            enabled={true}
                        />
                    </Col>
                </Row>
                <Row className='center-fields mb-2'>
                    <Col md={3}>
                        <ControlledMaskInput
                            label='Risk P Prof'
                            name='riskPprof'
                            decimals={4}
                            value={capRate?.riskPprof}
                            enabled={true}
                        />
                    </Col>
                    <Col md={3}>
                        <ControlledMaskInput
                            label='Risk P hosp'
                            name='riskPHosp'
                            decimals={4}
                            value={capRate?.riskPHosp}
                            enabled={true}
                        />
                    </Col>
                    <Col md={3}>
                        <ControlledCheckbox
                            label='Is Member Premium Applicable?'
                            name='isMemberPremiumApplicable'
                            change={(checked) => setConditionalValue('isMemberPremiumApplicable', checked)}
                        />
                    </Col>
                    <Col md={3}>
                        <div>
                            <label className='e-label-select'>MP Cap Revenue </label>
                            <DropDownListComponent
                                id='mpCapRevCode'
                                {...register('mpCapRevCode')}
                                dataSource={capRevenuesMP}
                                placeholder='Select a MP Cap Revenue'
                                fields={{ value: 'code', text: 'text' }}
                                change={({ itemData }) => {
                                    setValue('mpCapRevCode', itemData?.code);
                                    setFormula(itemData?.description);
                                }}
                                popupHeight='220px'
                                enabled={!!isMemberPremiumApplicable}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className='center-fields'>
                    <Col md={3}>
                        <ControlledCheckbox
                            label='Apply Sequestration Tax?'
                            name='isSequestrationtax'
                        />
                    </Col>
                    <Col md={3}>
                        <ControlledCheckbox
                            label='Apply ACA Tax?'
                            name='isAcaTax'
                            change={(checked) => setConditionalValue('isAcaTax', checked)}
                        />
                    </Col>
                    <Col md={3}>
                        <ControlledMaskInput
                            {...register('acaTaxDivisor')}
                            mask='000'
                            enabled={!!isAcaTax}
                            value={capRate?.acaTaxDivisor}
                            label='ACA Tax Divisor'
                        />
                    </Col>
                    <Col md={3}>
                        <div className='e-float-input e-input-group'>
                            <input
                                type='text'
                                {...register('hospitalCode')}
                            />
                            <span className='e-float-line'></span>
                            <label className='e-float-text'>Hospital Code</label>
                        </div>
                    </Col>
                </Row>
                <Row className='center-fields mb-2'></Row>
                <Row className='center-fields'>
                    <Col md={12}>
                        <div className='e-float-input e-input-group'>
                            <textarea
                                {...register('notes')}
                                rows={3}
                            />
                            <span className='e-float-line'></span>
                            <label className='e-float-text'>Notes</label>
                        </div>
                        <p className='formula-description'>{formula && formula}</p>
                    </Col>
                </Row>
                <Row className='center-fields mb-2'>
                    <p className='section-container-header header-margin'>Floor Values</p>
                    <Row>
                        <Col md={7}>
                            <Row className='center-fields mb-4'>
                                <Col
                                    md={6}
                                    className='column-padding'
                                >
                                    <ControlledCheckbox
                                        label='Is Individual Floor Level?'
                                        name='isIndividualFloorLevel'
                                        change={(checked) => {
                                            setConditionalValue('isIndividualFloorLevel', checked);
                                            uncheckAvergaFloorLevelValues('isAverageFloorLevel');
                                            if (checked) {
                                                setConditionalValue('isAverageFloorLevel', false);
                                            }
                                        }}
                                    />
                                </Col>
                                <Col
                                    md={6}
                                    className='column-padding'
                                >
                                    <ControlledCheckbox
                                        label='Is Average Floor Level?'
                                        name='isAverageFloorLevel'
                                        change={(checked) => {
                                            setConditionalValue('isAverageFloorLevel', checked);
                                            uncheckAvergaFloorLevelValues('isIndividualFloorLevel');
                                            if (checked) {
                                                setConditionalValue('isIndividualFloorLevel', false);
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-2'>
                                <Col md={6}>
                                    <ControlledMaskInput
                                        label='Floor MC'
                                        name='floorMC'
                                        decimals={2}
                                        value={capRate?.floorMC}
                                        enabled={isIndividualFloorLevel || isAverageFloorLevel}
                                    />
                                </Col>
                                <Col md={6}>
                                    <ControlledMaskInput
                                        label='Floor MM'
                                        name='floorMM'
                                        decimals={2}
                                        value={capRate?.floorMM}
                                        enabled={isIndividualFloorLevel || isAverageFloorLevel}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={5}>
                            <Row className='center-fields mb-4'>
                                <Col md={6}>
                                    <ControlledCheckbox
                                        label='IPA'
                                        name='isAverageFloorIPA'
                                        change={(checked) => {
                                            setConditionalValue('isAverageFloorIPA', checked);
                                            setValue('isIpaGroupLevel', false);
                                        }}
                                        disabled={isIndividualFloorLevel || !isAverageFloorLevel}
                                    />
                                </Col>
                                <Col md={6}>
                                    <ControlledCheckbox
                                        label='Exclude ESRD'
                                        name='isEsrdExclusion'
                                        disabled={isIndividualFloorLevel || !isAverageFloorLevel}
                                    />
                                </Col>
                            </Row>
                            <Row className='center-fields mb-4'>
                                <Col md={6}>
                                    <ControlledCheckbox
                                        label='PCP'
                                        name='isAverageFloorPCP'
                                        change={(checked) => setConditionalValue('isAverageFloorPCP', checked)}
                                        disabled={isIndividualFloorLevel || !isAverageFloorLevel}
                                    />
                                </Col>
                                <Col md={6}>
                                    <ControlledCheckbox
                                        label='Exclude Virtual'
                                        name='isVirtualExclusion'
                                        disabled={isIndividualFloorLevel || !isAverageFloorLevel}
                                    />
                                </Col>
                            </Row>
                            <Row className='center-fields'>
                                <Col md={6}>
                                    <Row>
                                        <Col md={6}>
                                            <ControlledCheckbox
                                                label='Benefit'
                                                name='isAverageFloorBenefit'
                                                change={(checked) =>
                                                    setConditionalValue('isAverageFloorBenefit', checked)
                                                }
                                                disabled={isIndividualFloorLevel || !isAverageFloorLevel}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <ControlledCheckbox
                                                label='IPA Group'
                                                name='isIpaGroupLevel'
                                                disabled={isIndividualFloorLevel || !isAverageFloorLevel}
                                                change={(checked) => {
                                                    setConditionalValue('isIpaGroupLevel', checked);
                                                    setValue('isAverageFloorIPA', false);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <div
                                        className={`e-float-input e-input-group ${
                                            isIndividualFloorLevel || !isIpaGroupLevel ? 'disable-field' : ''
                                        }`}
                                    >
                                        <input
                                            type='text'
                                            {...register('childIpas')}
                                        />
                                        <span className='e-float-line'></span>
                                        <label className='e-float-text'>Child IPAs</label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
                <Row className='center-fields mb-2'>
                    <p className='section-container-header header-margin'>Virtual Values</p>
                    <Row className='center-fields'>
                        <Col md={2}>
                            <ControlledCheckbox
                                label='Is Virtual Applicable?'
                                name='isVirtualApplicable'
                                change={(checked) => {
                                    setConditionalValue('isVirtualApplicable', checked);
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <ControlledMaskInput
                                {...register('virtualPmPm')}
                                label='Virtual PM PM'
                                decimals={2}
                                enabled={!!isVirtualApplicable}
                                value={capRate?.virtualPmPm}
                            />
                        </Col>
                        <Col md={3}>
                            <ControlledMaskInput
                                {...register('virtualPmPmMm')}
                                label='Virtual PM PM (MM)'
                                decimals={2}
                                enabled={!!isVirtualApplicable}
                                value={capRate?.virtualPmPmMm}
                            />
                        </Col>
                    </Row>
                </Row>
                <Row className='center-fields mb-2'>
                    <p className='section-container-header header-margin'>ESRD Values</p>
                    <Row className='center-fields'>
                        <Col md={3}>
                            <ControlledCheckbox
                                label='Is ESRD Applicable?'
                                name='isEsrdApplicable'
                                change={(checked) => setConditionalValue('isEsrdApplicable', checked)}
                            />
                        </Col>
                        <Col md={3}>
                            <div>
                                <label className='e-label-select'>ESRD Type</label>
                                <DropDownListComponent
                                    {...register('esrdType')}
                                    id='esrdType'
                                    dataSource={ESRDTypes}
                                    placeholder='Select ESRD Type'
                                    change={({ value }) => setValue('esrdType', value)}
                                    popupHeight='220px'
                                    popupWidth='350px'
                                    enabled={!!isEsrdApplicable}
                                />
                            </div>
                        </Col>
                        <Col md={3}>
                            <ControlledMaskInput
                                {...register('esrdPmPm')}
                                label='ESRD PM PM'
                                decimals={2}
                                enabled={!!isEsrdApplicable}
                                value={capRate?.esrdPmPm}
                            />
                        </Col>
                        <Col md={3}>
                            <div>
                                <div className='control-label'></div>
                                <ControlledMaskInput
                                    {...register('esrdPmPmMm')}
                                    label='ESRD PM PM (MM)'
                                    decimals={2}
                                    enabled={!!isEsrdApplicable}
                                    value={capRate?.esrdPmPmMm}
                                />
                            </div>
                        </Col>
                        <Row className='mt-3'>
                            <Col md={3}>
                                <ControlledMaskInput
                                    {...register('esrdPop')}
                                    label='ESRD POP'
                                    decimals={4}
                                    enabled={!!isEsrdApplicable}
                                    value={capRate?.esrdPop}
                                />
                            </Col>
                            <Col md={3}>
                                <ControlledMaskInput
                                    {...register('esrdPopMm')}
                                    label='ESRD POP (MM)'
                                    decimals={4}
                                    enabled={!!isEsrdApplicable}
                                    value={capRate?.esrdPopMm}
                                />
                            </Col>
                        </Row>
                    </Row>
                </Row>
                <Row>
                    <p className='section-container-header header-margin'>Value Based</p>
                    <Row className='center-fields row-section'>
                        <Col md={3}>
                            <ControlledCheckbox
                                label='Is Value Based Applicable?'
                                name='isValueBasedFlag'
                                change={(checked) => setConditionalValue('isValueBasedFlag', checked)}
                            />
                        </Col>
                        <Col md={3}>
                            <ControlledMaskInput
                                {...register('valueBasedPmPm')}
                                label='Value Based PMPM'
                                decimals={2}
                                enabled={!!isValueBasedFlag}
                                value={capRate?.valueBasedPmPm}
                            />
                        </Col>
                        <Col md={3}>
                            <ControlledMaskInput
                                {...register('valueBasedPmPmMm')}
                                label='Value Based PMPM (MM)'
                                decimals={2}
                                enabled={isValueBasedFlag}
                                value={capRate?.valueBasedPmPmMm}
                            />
                        </Col>
                    </Row>
                </Row>
                <Row>
                    <p className='section-container-header header-margin'>PPO IPA configuration</p>
                    <Row className='center-fields mb-3'>
                        <Col md={4}>
                            <ControlledCheckbox
                                label='Is Preferred Provider Search?'
                                name='isPreferredProviderSearch'
                                change={(checked) => setConditionalValue('isPreferredProviderSearch', checked)}
                                disabled={!!isRestrictedPcpSearch}
                            />
                        </Col>
                        <Col md={4}>
                            <ControlledCheckbox
                                label='Is Restricted PCP Search?'
                                name='isRestrictedPcpSearch'
                                disabled={!!isPreferredProviderSearch}
                                change={(checked) => setConditionalValue('isRestrictedPcpSearch', checked)}
                            />
                        </Col>
                        <Col md={4}>
                            <div>
                                <label className='e-label-select'>Product Code</label>
                                <MultiSelectComponent
                                    {...register('productCode')}
                                    placeholder='Product Code'
                                    allowCustomValue
                                    enabled={isRestrictedPcpSearch || isPreferredProviderSearch}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='center-fields mb-3'>
                        <p className='section-container-header header-margin'>IPA Cap Special Rate</p>
                        <Col md={3}>
                            <ControlledCheckbox
                                label='Is Special Rate Applicable?'
                                name='isSpecialRateApplicable'
                                disabled={true}
                                change={(checked) => setConditionalValue('isSpecialRateApplicable', checked)}
                            />
                        </Col>
                    </Row>
                </Row>
            </>
        ),
        [
            capRate,
            conditionalValues,
            watch,
            isDirty,
            isIndividualFloorLevel,
            isAverageFloorLevel,
            errors,
            capRevenues,
            capRevenuesMP,
        ],
    );
    return (
        <>
            <DisabledWrapper disabled={isReadOnlyViewer}>
                <form
                    onSubmit={handleSubmit(saveInformation)}
                    style={{ border: '1px solid #c8c8c8', padding: '10px' }}
                >
                    <FormFields />
                    {capRate?.id && (
                        <>
                            <Row className='center-fields field-margin-top'>
                                <Col md={2}>
                                    <div className='e-float-input e-input-group disable-field'>
                                        <input
                                            type='text'
                                            value={capRate?.createdBy}
                                        />
                                        <span className='e-float-line'></span>
                                        <label className='e-float-text'>Created By</label>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className='e-float-input e-input-group disable-field'>
                                        <input
                                            type='text'
                                            value={capRate?.createDate}
                                        />
                                        <span className='e-float-line'></span>
                                        <label className='e-float-text'>Created Date</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='center-fields  field-margin-top'>
                                <Col md={2}>
                                    <div className='e-float-input e-input-group disable-field'>
                                        <input
                                            type='text'
                                            value={capRate?.updatedBy}
                                        />
                                        <span className='e-float-line'></span>
                                        <label className='e-float-text'>Updated By</label>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className='e-float-input e-input-group disable-field'>
                                        <input
                                            type='text'
                                            value={capRate?.updateDt}
                                        />
                                        <span className='e-float-line'></span>
                                        <label className='e-float-text'>Updated Date</label>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row style={{ textAlign: 'left', marginTop: 10 }}>
                        {capRate?.id ? (
                            <button
                                type='button'
                                onClick={() =>
                                    submitCapRateService.addCapTier({
                                        id,
                                        ipaCode,
                                        sequence,
                                        effDate: capRate?.effDate,
                                        endDate: capRate?.endDate,
                                        maxEndDate,
                                    })
                                }
                                className='btn btn-primary btn-flat'
                                style={{ maxWidth: 180, marginLeft: 5 }}
                            >
                                Add New Tiered Rate
                            </button>
                        ) : null}
                        {capRate?.id ? (
                            <button
                                type='button'
                                onClick={() =>
                                    submitCapRateService.addCapSpecialRate({
                                        id,
                                        ipaCode,
                                        sequence: capRate.sequence,
                                        isSpecialRate: true,
                                        addNew: true,
                                        effDate: capRate?.effDate,
                                        endDate: capRate?.endDate,
                                        maxEndDate,
                                    })
                                }
                                className='btn btn-primary btn-flat'
                                style={{ maxWidth: 180, marginLeft: 5 }}
                            >
                                Add New Special Rate
                            </button>
                        ) : null}
                        {capRate?.id ? (
                            <button
                                type='button'
                                onClick={() => {
                                    submitCapRateService.copyCapRate({
                                        ...capRate,
                                        isCapRate: true,
                                        ipaCode,
                                        id: 0,
                                        sequence: 0,
                                        maxEndDate,
                                    });
                                }}
                                className='btn btn-primary btn-flat btn-orange'
                                style={{
                                    maxWidth: 180,
                                    marginLeft: 10,
                                }}
                            >
                                Copy Cap Rate
                            </button>
                        ) : null}
                        <button
                            type='button'
                            onClick={() => submitCapRateService.cancel()}
                            className='btn btn-secondary btn-flat'
                            style={{ maxWidth: 150, marginLeft: 10 }}
                        >
                            Cancel
                        </button>
                        <button
                            type='submit'
                            className='btn btn-primary btn-flat'
                            style={{ maxWidth: 150, marginLeft: 5 }}
                            disabled={(!isDirty && touchConditionalValues) || Object.keys(errors).length > 0}
                        >
                            Save
                        </button>
                    </Row>
                </form>
            </DisabledWrapper>
        </>
    );
};
