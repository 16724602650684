import React, { createRef } from 'react';

import { faSquare, faWindowRestore, faWindowMinimize } from '@fortawesome/free-regular-svg-icons';
import { faVideo, faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Draggable } from '@syncfusion/ej2-base';
import './VirtualVisit.css';
import './VirtualPCP.css';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CSSTransition } from 'react-transition-group';

import { VideoService } from 'components/Common/VirtualVisits/VideoService';
import ProgressButton from 'components/Common/Wrappers/ProgressButton';

import { ajaxGet } from '../../../common/ajax';
import { notificationService } from '../../../subjects/common/NotificationService';
import { TELEHEALTH_MODULES_TO_URL } from './MyPanelHelpers';
import { updateUpcomingVisitsService } from './Subjects/MyVirtualPanelSubjects';
import VideoChat from './VideoChat/VideoChat';
import { initResize } from './VirtualVisitHelpers';

export default class VirtualVisit extends React.Component<any, any> {
    startVideoCallBtnRef: any;
    twilioTokenSuscription: any;
    draggableRef: any;
    constructor(props) {
        super(props);

        this.state = {
            token: null,
            showVideo: false,
            maximize: false,
            minimize: false,
            conversationsToken: null,
            conversationSid: null,
            subscriptions: [],
        };

        this.startVideoCallBtnRef = createRef();
    }

    componentDidMount() {
        const container = document.getElementById('video-container') as HTMLElement;
        new Draggable(document.getElementById('virtual-visit-pcp') as HTMLElement, {
            clone: false,
            dragArea: container,
            isDragScroll: true,
            handle: '#virtual-visit-header',
        });
        updateUpcomingVisitsService.update(undefined);

        const resizer = document.getElementById('resizer');
        if (resizer) {
            resizer.addEventListener('mousedown', initResize, false);
        }
    }

    joinVideoCall = () => {
        const { roomData, appointment, telehealthModuleType } = this.props;
        this.startVideoCallBtnRef.current.stop();
        this.startVideoCallBtnRef.current.disabled = true;

        if (roomData?.item1) {
            this.twilioTokenSuscription = ajaxGet(
                `/api/${TELEHEALTH_MODULES_TO_URL[telehealthModuleType]}/AppointmentToken?appointmentId=${roomData.item1['unique_name']}&memberId=${appointment.memberId}&connectionId=${appointment.providerConnectionId}`,
            ).subscribe({
                next: (response) => {
                    if (response.response) {
                        this.setAppointmentToken(response.response);
                    }
                },
                error: (result) => {
                    if (result.response) {
                        notificationService.error({
                            title: result.response.value.error,
                        });

                        this.props.endCall();
                    }
                },
            });
        }
    };

    setAppointmentToken = (token) => {
        if (token) {
            this.setState({
                token,
                showVideo: true,
            });
        } else {
            this.setState({
                token: null,
            });
        }
    };

    handleMinimizeWindow = () => {
        this.setState({ minimize: true, maximize: false });
    };

    handleMaximizeWindow = () => {
        this.setState({ maximize: true, minimize: false });
    };

    handleRestoreWindow = () => {
        this.setState({ maximize: false, minimize: false });

        const videoFrame: any = document.getElementById('virtual-visit-pcp');
        videoFrame.style = { top: 'calc(50% - 290px)', left: 'calc(100vw - 370px)' };
    };

    componentWillUnmount() {
        if (this.twilioTokenSuscription) {
            this.twilioTokenSuscription.unsubscribe();
        }
    }

    handleWindowClosed = (token) => {
        if (token) {
            VideoService.hideVideo();
        } else {
            this.setState({ showVideo: false });
            this.props.endCall();
        }
    };

    render() {
        const { token, showVideo } = this.state;
        const { roomData, appointment, endCall } = this.props;

        return (
            <>
                <div
                    id='video-container'
                    className={this.state.minimize ? 'hide-call' : undefined}
                >
                    <div
                        id='virtual-visit-pcp'
                        ref={this.draggableRef}
                        role='dialog'
                        className={`${this.state.maximize ? 'maximize' : ''} ${this.state.minimize ? 'minimize' : ''} ${
                            appointment.type != 0 && appointment.type !== 'Video' ? 'just-audio' : ''
                        }`}
                    >
                        <div
                            id='virtual-visit-header'
                            className='virtual-visit-header'
                        >
                            {showVideo ? <div className='red-point'></div> : null}
                            <div className='virtual-visit-buttons'>
                                {appointment.type == 0 || appointment.type === 'Video' ? (
                                    this.state.maximize ? (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faWindowMinimize}
                                                className='minimize-btn'
                                                onClick={this.handleMinimizeWindow}
                                            />
                                            <FontAwesomeIcon
                                                icon={faWindowRestore}
                                                className='restore-btn'
                                                onClick={this.handleRestoreWindow}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faWindowMinimize}
                                                className='minimize-btn'
                                                onClick={this.handleMinimizeWindow}
                                            />
                                            <FontAwesomeIcon
                                                icon={faSquare}
                                                className='maximize-btn'
                                                onClick={this.handleMaximizeWindow}
                                            />
                                        </>
                                    )
                                ) : null}
                                {!showVideo && (
                                    <ButtonComponent
                                        className='virtualPCPButton'
                                        cssClass='e-danger close'
                                        iconCss='e-icons e-close-icon'
                                        onClick={() => this.handleWindowClosed(token)}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={`virtual-visit-body ${showVideo ? 'black' : undefined}`}>
                            {showVideo && token ? (
                                <>
                                    <VideoChat
                                        roomName={roomData.item1?.url}
                                        appointmentId={appointment.id}
                                        conferenceId={appointment.dialInCode}
                                        token={token}
                                        endCall={endCall}
                                        memberId={appointment.memberId}
                                        memberPhone={appointment.patientPhone}
                                        memberFullName={appointment.memberFullName}
                                        memberConnectionId={appointment.memberConnectionId}
                                        optionalAttendees={
                                            appointment.optionalAttendees ? appointment.optionalAttendees : []
                                        }
                                        onlyAudio={appointment.type == 0 || appointment.type === 'Video' ? false : true}
                                        telehealthModuleType={this.props.telehealthModuleType}
                                    />
                                </>
                            ) : (
                                <ProgressButton
                                    type='button'
                                    className='btn-light-blue join-videocall-btn'
                                    id='start-virtual-conference'
                                    buttonRef={this.startVideoCallBtnRef}
                                    handleClick={this.joinVideoCall}
                                >
                                    {appointment.type == 'Video' || appointment.type == 0
                                        ? 'Join Video Call'
                                        : 'Phone conference'}
                                </ProgressButton>
                            )}
                        </div>

                        <div id='resizer'>
                            <FontAwesomeIcon
                                className='expand-icon'
                                icon={faExpandAlt}
                            />
                        </div>
                    </div>
                </div>
                <div
                    id='minimize-pcp-content'
                    className={!this.state.minimize ? 'hide-call' : ''}
                >
                    <CSSTransition
                        in={this.state.minimize}
                        timeout={600}
                        classNames='minimize-pcp-fade'
                        unmountOnExit
                    >
                        <ButtonComponent
                            cssClass='btn-light-blue call-minimize-btn'
                            onClick={this.handleRestoreWindow}
                        >
                            <div>
                                <FontAwesomeIcon icon={faVideo} />
                                <span>
                                    {appointment.type == 'Video' || appointment.type == 0
                                        ? 'Back to conference call'
                                        : 'Back to phone call'}
                                </span>
                            </div>
                        </ButtonComponent>
                    </CSSTransition>
                </div>
            </>
        );
    }
}
