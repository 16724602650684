import { BehaviorSubject, Observable, Subject } from 'rxjs';

interface ItemClicked {
    retryNavigation: any;
}
const navMenuItemClickedSubject = new Subject<ItemClicked>();
const navMenuItemClickedService = {
    select: ({ retryNavigation }: ItemClicked) => navMenuItemClickedSubject.next({ retryNavigation }),
    get$: (): Observable<ItemClicked> => {
        return navMenuItemClickedSubject.asObservable();
    },
};

const tabsToValidateSubject = new BehaviorSubject<{ [key: string]: boolean }>({});
const tabsToValidateService = {
    getCount: () => Object.keys(tabsToValidateSubject.value).length,
    add: (id: string) => {
        const obj = tabsToValidateSubject.value;
        obj[id] = true;
        tabsToValidateSubject.next({ ...obj });
    },
    remove: (id: string) => {
        const obj = tabsToValidateSubject.value;
        if (obj.hasOwnProperty(id)) delete obj[id];
        tabsToValidateSubject.next({ ...obj });
    },
    clear: () => tabsToValidateSubject.next({}),
};

const defaultTabsCountSubject = new BehaviorSubject<number>(1);
const defaultTabsCountService = {
    get: () => defaultTabsCountSubject.value,
    set: (count: number) => defaultTabsCountSubject.next(count),
};

export { navMenuItemClickedService, tabsToValidateService, defaultTabsCountService };
