import { Query } from '@syncfusion/ej2-data';
import { Guid } from 'guid-typescript';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import moment from 'moment';

import { formatDate, formatDateTime } from './widgets';

export function isNumericInput({ keyCode }) {
    return (
        (keyCode >= 48 && keyCode <= 57) || // allow number line
        (keyCode >= 96 && keyCode <= 105) // allow number pad
    );
}

export function toMoney(num) {
    return num ? `$${Number.parseFloat(num).toFixed(2)}` : '$0.00';
}

/**
 * @param {number | string } value - A number string representing a dollar amount.
 * @param {string | undefined | null} prefix - A string to prepend to the currency value.
 * @returns {string} The currency representation of the value parameter. This function
 * will add the decimal point as soon as the number exceeds 2 digits. Ex. 500 -> 5.00.
 */
export function formatCurrencyInput(value, prefix = '') {
    const currency = value
        .toString()
        .replace(/[^\d]/g, '')
        .replace(/(\d+)(\d{2})/, '$1.$2');
    return prefix ? prefix + currency : currency;
}

export function isModifierKey(event) {
    const { keyCode, shiftKey, ctrlKey, metaKey } = event;
    return (
        shiftKey === true ||
        keyCode === 35 ||
        keyCode === 36 || // allow shift, home and end
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 46 || // allow backspace, tab, enter and delete
        (keyCode > 36 && keyCode < 41) || // Allow left, up, right, down
        // allow ctrl/command + a, c, v, x, and z
        ((ctrlKey === true || metaKey === true) &&
            (keyCode === 65 || keyCode === 67 || keyCode === 86 || keyCode === 88 || keyCode === 90))
    );
}

export function enforcePhoneFormat(event) {
    // input must be of a valid number format or a modifier key
    if (!isNumericInput(event) && !isModifierKey(event)) {
        event.preventDefault();
    }
}

export function addressTextFormat(address) {
    if (address) {
        let formattedAddress = `${address.address1}, ${address.address2?.length > 0 ? address.address2 + ', ' : ''}${
            address.city
        }, ${address.state}, ${address.zip5 ? address.zip5 : address.zip}${address.zip4 ? ' - ' + address.zip4 : ''}`;
        if (formattedAddress.charAt(0) == ',') {
            formattedAddress = formattedAddress.slice(1);
        }
        return formattedAddress;
    }
    return '';
}

export function formatEventToPhone(event) {
    if (isModifierKey(event)) {
        return;
    }

    event.target.value = convertToPhone(event.target.value);
}

export function convertToPhone(value) {
    if (!value) {
        return '';
    }

    const input = value.replace(/\D/g, '').substring(0, 10); // first ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6);

    if (input.length > 6) {
        return `(${zip})${middle}-${last}`;
    } else if (input.length > 3) {
        return `(${zip})${middle}`;
    } else if (input.length > 0) {
        return `(${zip}`;
    }
}

export function extractQueryStringFromQuery(dataManager, query) {
    if (query instanceof Query) {
        var queryData = dataManager?.adaptor?.processQuery(dataManager, query);
        var queryStringStartIdx = queryData?.url?.lastIndexOf('?');
        if (queryStringStartIdx && queryStringStartIdx !== -1) {
            return queryData.url.slice(queryStringStartIdx);
        }
        return null;
    }
    return null;
}

const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

const isReactRefObj = (target) => {
    if (target && typeof target === 'object') {
        return true;
    }
    return false;
};

export const loopTree = (root, fn) => {
    fn(root);
    if (!root.children) {
        return;
    } else {
        for (let i = 0; i < root.children.length; i++) {
            loopTree(root.children[i], fn);
        }
    }
    return;
};

export const base64ToArrayBuffer = function (base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
};

export const LastComment = function (data) {
    const lastComment = data.comments.slice(-1)[0];
    if (lastComment == null) {
        return '';
    }
    if (lastComment.comment?.length > 100) {
        return (
            lastComment.by +
            ' @ ' +
            dateTimeToString(lastComment.at) +
            ' : ' +
            lastComment.comment.substring(0, 100) +
            '...'
        );
    }
    return lastComment.by + ' @ ' + dateTimeToString(lastComment.at) + ' : ' + lastComment.comment;
};

export const dateToString = function (date) {
    return date ? moment(new Date(date)).format('MM/DD/YYYY') : '';
};

export const utcDateToString = function (date) {
    return date ? moment.utc(date).format('MM/DD/YYYY') : '';
};

export const dateTimeToString = function (dateTime) {
    return dateTime ? moment(dateTime).format('MM/DD/YYYY, hh:mm A') : '';
};

export const toDateInput = function (dateTime) {
    return `${dateTime.getFullYear()}-${('0' + (dateTime.getMonth() + 1)).slice(-2)}-${(
        '0' +
        (dateTime.getDate() + 1)
    ).slice(-2)}`;
};

export const parseDate = function (dateString, option = 0) {
    var defaultDate = option === 0 ? '' : option === 1 ? new Date(9999, 11, 31) : new Date(1, 1, 1);
    if (!dateString) return defaultDate;
    var parts = dateString.split('/');

    if (parts.length !== 3) return defaultDate;

    var year = parseInt(parts[2]);
    var month = parseInt(parts[0]) - 1;
    var date = parseInt(parts[1]);

    return new Date(year, month, date);
};

export const formatPhoneNumber = function (phoneNumberString) {
    return phoneNumberString && phoneNumberString.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const formatFaxNumber = (number) => {
    if (number != null) {
        number = number.replace(/\D/g, '');
        if (number.length == 11) {
            const formattedNumber =
                number.slice(0, 1) + '-' + number.slice(1, 4) + '-' + number.slice(4, 7) + '-' + number.slice(7, 11);
            return formattedNumber;
        }
        if (number.length == 10) {
            const formattedNumber = number.slice(0, 3) + '-' + number.slice(3, 6) + '-' + number.slice(6);
            return formattedNumber;
        }
    }
    return number;
};

const admissionRiskCategories = {
    Low: 1,
    Medium: 2,
    High: 3,
    'Very High': 4,
    Extreme: 5,
};

export const admissionRiskCategoryToIndex = function (category) {
    return (category && admissionRiskCategories[category]) || -1;
};

export const admissionRiskCategoryComparer = function (a, b) {
    return admissionRiskCategoryToIndex(a) - admissionRiskCategoryToIndex(b);
};

const acuityLevels = {
    Low: 1,
    Medium: 2,
    High: 3,
};

export const acuityLevelToIndex = function (level) {
    return (level && acuityLevels[level]) || -1;
};

export const acuityLevelComparer = function (a, b) {
    return acuityLevelToIndex(a) - acuityLevelToIndex(b);
};

export const dateColumnValueAccessor = (field, row) => formatDate(row && get(row, field));
export const dateTimeColumnValueAccessor = (field, row) => formatDateTime(row && get(row, field));
export const dateTimeWithSecondsColumnValueAccessor = (field, row) => formatDateTime(row && get(row, field), true);

export const DAYOFWEEK = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
};

// for handling reactstrap popover throwing error:
// target not found in dom
// you might see a failed prop type warning in the console,
// but if we pass an empty string or undefined it crashes.
// doesn't crash with null.
export const getPopoverTargetById = (target) => {
    if (target) {
        const element = document.getElementById(target);
        if (element) {
            return target;
        }
    }
    return null;
};

export const isPopoverTargetinDOM = (target) => {
    if (isReactRefObj(target)) {
        return true;
    }
    if (isFunction(target)) {
        return true;
    }
    if (typeof target === 'string' && canUseDOM) {
        let selection = document.querySelectorAll(target);
        if (!selection.length) {
            selection = document.querySelectorAll(`#${target}`);
        }
        if (!selection.length) {
            return false;
        }
        return true;
    }
    return false;
};

export const generateGuid = () => {
    return Guid.create().toString();
};

export const validateEmailRegExp =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

/**
 * Get Query string from a simple object, this doesn't work for more complex objects like nested ones
 * @example
 * // {a:"Hello", b:"world"}
 *
 * @param {any} object
 * @returns {string}
 * //"a=Hello&b=world"
 */
export const getQueryStringFromObject = (object) =>
    Object.keys(object)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`)
        .join('&');

export const getLastODataCountFromDataSource = (dataSource) => {
    if (dataSource?.requests?.slice(-1)[0].httpRequest?.response) {
        return JSON.parse(dataSource.requests.slice(-1)[0].httpRequest.response)['@odata.count'];
    }
    return 100000000;
};

export const unionArraysByKey = (key, ...arrays) => {
    const allItems = [].concat(...arrays);
    const seenKeys = new Set();
    return allItems.reduce((acc, item) => {
        if (!seenKeys.has(item[key])) {
            seenKeys.add(item[key]);
            acc.push(item);
        }
        return acc;
    }, []);
};

/**
 * Formats bytes into
 *
 * @param {number} bytes - Number of bytes
 * @param {number} [decimals] - Decimals to round to
 * @param {boolean} [useBinaryPrefix] - Returns binary prefix multiple
 * @returns {string} {formatted bytes}.{decimals} {Bytes | KB/KiB | MB/MiB}
 */
export const formatBytes = (bytes, decimals = 2, useBinaryPrefix = false) => {
    if (!+bytes) return '0 Bytes';
    const k = useBinaryPrefix ? 1024 : 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = useBinaryPrefix ? ['Bytes', 'KiB', 'MiB'] : ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Get Operating System from the client device.
 */
export function getDeviceOS() {
    const userAgent = navigator.userAgent;
    let operatingSystem = 'Unknown';

    if (userAgent.match(/Android/i)) {
        operatingSystem = 'Mobile - Android';
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
        operatingSystem = 'Mobile - iOS';
    } else if (userAgent.match(/Mac/i)) {
        operatingSystem = 'Desktop - macOS';
    } else if (userAgent.match(/Windows/i)) {
        operatingSystem = 'Desktop - Windows';
    } else if (userAgent.match(/Linux/i)) {
        operatingSystem = 'Desktop - Linux';
    }

    return operatingSystem;
}

export const createFilterKeyValuePairs = (query) => {
    let filters = [];

    if (typeof query !== 'object' || query === null) {
        return filters;
    }

    if (Array.isArray(query)) {
        for (const filter of query) {
            filters = filters.concat(createFilterKeyValuePairs(filter));
        }
    } else {
        for (const key in query) {
            if (key === 'field') {
                if (Array.isArray(query.value)) {
                    const newObject = {};
                    newObject[query[key]] = query.value;
                    filters.push(newObject);
                } else {
                    const newObject = {};
                    newObject[query[key]] = [query.value];
                    filters.push(newObject);
                }
            } else {
                filters = filters.concat(createFilterKeyValuePairs(query[key]));
            }
        }
    }

    const cleanFiltersList = [];
    filters.forEach((filterObject) => {
        const onlyPropertyOfObject = Object.keys(filterObject)[0];
        const foundObject = cleanFiltersList.find((filter) => filter.hasOwnProperty(onlyPropertyOfObject));

        if (foundObject === undefined) {
            cleanFiltersList.push(filterObject);
        } else {
            foundObject[onlyPropertyOfObject].push(filterObject[onlyPropertyOfObject][0]);
        }
    });

    return cleanFiltersList;
};
