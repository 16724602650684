import { forwardRef, useEffect } from 'react';

import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

const CheckBoxComponentTemplate = forwardRef(({ element, onItemSelectedChange, checked, tabIndex, destroy }, ref) => {
    useEffect(() => {
        return () => {
            if (destroy) {
                destroy();
                if (ref?.current) {
                    ref.current.destroy();
                }
            }
        };
    }, [destroy]);

    return (
        <CheckBoxComponent
            name={element.id}
            ref={ref}
            label={element.label}
            labelPosition='After'
            change={onItemSelectedChange}
            checked={checked}
            tabIndex={tabIndex}
        />
    );
});

export default CheckBoxComponentTemplate;
