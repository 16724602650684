import { Subject } from 'rxjs';

import { ajaxGet, ajaxPost } from 'common/ajax';

const saveAndJoinSubject = new Subject<void>();
const closeVideoCallSubject = new Subject<void>();
const saveOnDemandSubject = new Subject<void>();
const cancelOnDemandSubject = new Subject<void>();
const saveAndCompleteSubject = new Subject<void>();

export const appointmentDialogService = {
    // AppointmentDialog buttons for onDemand visits
    onClickSaveAndJoin: () => saveAndJoinSubject.next(),
    clickSaveAndJoin: () => saveAndJoinSubject.asObservable(),
    // open SaveOnDemandVisitDialog after close video call
    onCloseVideoCall: (appointment) => closeVideoCallSubject.next(appointment),
    closeVideoCall: () => closeVideoCallSubject.asObservable(),
    // SaveOnDemandVisitDialog buttons
    onClickSaveOnDemand: () => saveOnDemandSubject.next(),
    clickSaveOnDemand: () => saveOnDemandSubject.asObservable(),
    onClickCancelOnDemand: () => cancelOnDemandSubject.next(),
    clickCancelOnDemand: () => cancelOnDemandSubject.asObservable(),
    onClickSaveAndComplete: () => saveAndCompleteSubject.next(),
    clickSaveAndComplete: () => saveAndCompleteSubject.asObservable(),
    // Requests
    getFeatureFlags: (moduleType) => ajaxGet(`api/${moduleType}/GetFeatureFlags`),
};

const saveNoteSubject = new Subject<void>();
const savedNoteSubject = new Subject();
export const addNoteService = {
    onClickSave: () => saveNoteSubject.next(),
    clickSave: () => saveNoteSubject.asObservable(),
    save: (module: string, payload: object) => {
        ajaxPost(`api/${module}/createnote`, payload).subscribe({
            next: (response) => {
                savedNoteSubject.next(response);
            },
            error: (error) => {
                savedNoteSubject.error(error);
            },
        });
    },
    onSaved: () => savedNoteSubject.asObservable(),
};
