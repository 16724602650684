import React from 'react';

export default class BaseComponent extends React.Component {
    constructor(props) {
        super(props);

        this.subscriptions = [];
    }

    componentWillUnmount() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    subscribe = (observable, observer) => observable && this.subscriptions.push(observable.subscribe(observer));
}
