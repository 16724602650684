import { ColumnModel } from '@syncfusion/ej2-react-grids';
import { ItemModel } from '@syncfusion/ej2-react-navigations';

import { IpaHospital } from 'components/ProviderData/IpaAdministration/atoms/Models';

export const ipaHospitalColumns: (ColumnModel & { field: keyof IpaHospital })[] = [
    {
        field: 'hospitalId',
        headerText: 'Hospital Id',
        width: '150',
    },
    {
        field: 'organizationName',
        headerText: 'Organization Name',
        width: '300',
    },
    {
        field: 'effDate',
        headerText: 'Eff Date',
        type: 'DateTime',
        format: 'MM/dd/yyyy',
        width: '130',
    },
    {
        field: 'termDate',
        headerText: 'Term Date',
        type: 'DateTime',
        format: 'MM/dd/yyyy',
        width: '130',
    },
    {
        field: 'taxId',
        headerText: 'Tax Id',
        width: '120',
    },
    {
        field: 'npi',
        headerText: 'NPI',
        width: '120',
    },
    {
        field: 'address1',
        headerText: 'Address 1',
        width: '200',
    },
    {
        field: 'address2',
        headerText: 'Address 2',
        width: '120',
    },
    {
        field: 'city',
        headerText: 'City',
        width: '150',
    },
    {
        field: 'state',
        headerText: 'State',
        width: '100',
    },
    {
        field: 'zip',
        headerText: 'Zip Code',
        width: '120',
    },
    {
        field: 'phone',
        headerText: 'Phone',
        width: '120',
    },
];

export const ipaHospitalExtraColumns: (ColumnModel & { field: keyof IpaHospital })[] = [
    {
        field: 'hospitalServices',
        headerText: 'hospital Services',
        width: '500',
    },
];

export const toolbarOptions = [
    {
        text: 'Export to Excel',
        tooltipText: 'Exports the current search results to Excel',
        prefixIcon: 'e-excelexport',
        id: 'ipaHospital_exportexcel',
        align: 'Right',
    } as ItemModel,
    'ColumnChooser',
];
