import { Observable, Subject } from 'rxjs';

import { AdditionalContact } from 'components/ProviderData/IpaAdministration/atoms/Models';
export interface AdditionalContactResponse {
    error: boolean;
    message?: string;
    newRecordId?: string;
    isRecordUpdated?: number;
}
const selectedIpaAdditionalContactSubject = new Subject<AdditionalContact>();
const submitAdditionalContactSubject = new Subject<AdditionalContactResponse | void>();
const additionalContactService = {
    select: (contact: AdditionalContact) => {
        selectedIpaAdditionalContactSubject.next(contact);
    },
    get$: (): Observable<AdditionalContact> => selectedIpaAdditionalContactSubject.asObservable(),
    submit: (response: AdditionalContactResponse | void) => submitAdditionalContactSubject.next(response),
    submit$: (): Observable<AdditionalContactResponse | void> => submitAdditionalContactSubject.asObservable(),
};

export { additionalContactService };
