import { Subject } from 'rxjs';

import { Notification } from '../../interfaces/Notification';

export type NotificationLevels = 'success' | 'warning' | 'error' | 'custom';

const notificationSubject = new Subject();

const MESSAGELEVEL = {
    SUCCESS: 0,
    WARN: 1,
    ERROR: 2,
    CUSTOM: 3,
};

const notificationService = {
    success: (notification: Notification) =>
        notificationSubject.next({ ...notification, messageLevel: MESSAGELEVEL.SUCCESS }),
    info: (title: string) => notificationSubject.next({ title: title, messageLevel: MESSAGELEVEL.SUCCESS }),
    warning: (notification: Notification) =>
        notificationSubject.next({ ...notification, messageLevel: MESSAGELEVEL.WARN }),
    warn: (title: string) => notificationSubject.next({ title: title, messageLevel: MESSAGELEVEL.WARN }),
    error: (notification: Notification) =>
        notificationSubject.next({ ...notification, messageLevel: MESSAGELEVEL.ERROR }),
    err: (title: string) => notificationSubject.next({ title: title, messageLevel: MESSAGELEVEL.ERROR }),
    custom: (notification: Notification) =>
        notificationSubject.next({ ...notification, messageLevel: MESSAGELEVEL.CUSTOM }),
    get: () => notificationSubject.asObservable(),
};

export { notificationService, MESSAGELEVEL };
