import { FeatureFlag } from '../../interfaces/FeatureFlag';
import { OutboundCallData, TalkdeskCallData } from '../../interfaces/Talkdesk';

// talkdesk cti subdomain
const talkDeskBaseUrl = 'https://cti-client.talkdeskapp.com';
// Talkdesk message events
const openContactMessageEvent = 'openContact';
const sendDataMessageEvent = 'sendData';
// Custom Talkdesk integration events
const authenticationMessage = 'getExternalId';
const inboundCallStartedEvent = 'inbound_call_started';
const inboundCallEndedEvent = 'inbound_call_ended';
const outboundCallStartedEvent = 'outbound_call_started';
const outboundCallEndedEvent = 'outbound_call_ended';
// Feature flag names
const talkdeskIntegration = 'talkdesk-integration';
const talkdeskInboundCalls = 'talkdesk-inbound-calls';
const talkdeskOutboundCalls = 'talkdesk-outbound-calls';
const talkdeskClickToCallInput = 'talkdesk-click-to-call-input';
const talkdeskLoginReminders = 'talkdesk-login-reminders';

// validation functions
function isMessageEvent(input: Event): input is MessageEvent {
    return input instanceof MessageEvent;
}

function isFromTalkdesk(event: MessageEvent<any>) {
    return event.origin.startsWith(talkDeskBaseUrl);
}

function isTalkdeskMessage(input: MessageEvent, type: string): boolean {
    return input && input.data && input.data.action === type;
}

function isTalkdeskEvent(callData: TalkdeskCallData, talkdeskEvent: string): boolean {
    return callData && callData.event === talkdeskEvent;
}

// creation/transformation functions
function createGetExternalIdMessage(userId: string) {
    return {
        action: 'getExternalId_response',
        response: userId,
    };
}

function createStartOutboundCallMessage(userId: string, callData: OutboundCallData) {
    return {
        action: 'clickToCall',
        number: callData.contact_phone_number,
        externalId: userId,
    };
}

function mockSendDataMessage(talkdeskAction: string, talkdeskEvent: string) {
    const messageData: any = {};
    messageData.action = talkdeskAction;
    messageData.data = mockTalkdeskCallData(talkdeskEvent);
    return new MessageEvent('message', { data: messageData });
}

function mockTalkdeskCallData(talkdeskEvent: string) {
    const callData = {} as TalkdeskCallData;
    callData.interaction_id = '0';
    callData.event = talkdeskEvent;
    return callData;
}

function mockOutboundCallData() {
    return { contact_phone_number: '', member_id: '' } as OutboundCallData;
}

// feature flag functions
function featureEnabled(featureFlags: FeatureFlag[] | null | undefined, featureName: string) {
    const flag = featureFlags?.find((x) => x.name === featureName);
    return !!(flag && flag.isActive);
}

// messaging functions
function sendMessageEvent(window: Window, message: any) {
    const ctiElement = window.document.getElementById('talkdesk_cti');
    const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
        input !== null && input.tagName === 'IFRAME';

    if (isIFrame(ctiElement) && ctiElement.contentWindow) {
        ctiElement.contentWindow.postMessage(message, '*');
    }
}

// parsing functions
function openContactToCallData(input: MessageEvent): TalkdeskCallData {
    return JSON.parse(input.data.data.externalId) as TalkdeskCallData;
}

function sendDataToCallData(input: MessageEvent): TalkdeskCallData {
    return input.data.data as TalkdeskCallData;
}

// generic functions
function logEvent(action: string, data: any) {
    console.log(`Processing Talkdesk event: ${action}`);
    if (data) console.log(data);
}

const talkdeskConstants = {
    openContactMessageEvent,
    sendDataMessageEvent,
    authenticationMessage,
    inboundCallEndedEvent,
    inboundCallStartedEvent,
    outboundCallEndedEvent,
    outboundCallStartedEvent,
};

const talkdeskFactory = {
    mockSendDataMessage,
    mockTalkdeskCallData,
    mockOutboundCallData,
    createGetExternalIdMessage,
    createStartOutboundCallMessage,
};

const talkdeskFeatures = {
    outboundCalls: talkdeskOutboundCalls,
    ctiIntegration: talkdeskIntegration,
    inboundCalls: talkdeskInboundCalls,
    clickToCallInput: talkdeskClickToCallInput,
    loginReminders: talkdeskLoginReminders,
    featureEnabled,
};

const talkdeskMessenger = {
    sendMessageEvent,
};

const talkdeskParser = {
    openContactToCallData,
    sendDataToCallData,
};

const talkdeskValidator = {
    isMessageEvent,
    isFromTalkdesk,
    isTalkdeskMessage,
    isTalkdeskEvent,
    logEvent,
};

export { talkdeskConstants, talkdeskFactory, talkdeskFeatures, talkdeskMessenger, talkdeskParser, talkdeskValidator };
