import { Observable, Subject } from 'rxjs';

import { IpaNetworkMapping } from 'components/ProviderData/IpaAdministration/atoms/Models';

export interface NetworkMappingResponse {
    error: boolean;
    message?: string;
    data?: any;
}
const selectedNetworkMapping = new Subject<IpaNetworkMapping>();
const submitNetworkMapping = new Subject<NetworkMappingResponse | void>();

const IpaNetworkMappingService = {
    select: (network: IpaNetworkMapping) => {
        selectedNetworkMapping.next(network);
    },
    get$: (): Observable<IpaNetworkMapping> => selectedNetworkMapping.asObservable(),
    submit: (response: NetworkMappingResponse | void) => submitNetworkMapping.next(response),
    submit$: (): Observable<NetworkMappingResponse | void> => submitNetworkMapping.asObservable(),
};

export { IpaNetworkMappingService };
