import React from 'react';

import { LogoLoader } from 'components/Common/LogoLoader/LogoLoader';

function LazyRender(Component, props) {
    return (
        <React.Suspense fallback={<LogoLoader size='large' />}>
            <Component {...props} />
        </React.Suspense>
    );
}
export default LazyRender;
