import { useEffect, useRef, useState } from 'react';

import { ColumnChooser, GridComponent, Inject, Selection, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';

import { requests } from 'common/axios';
import {
    NetworkMappingGridColumns,
    tabsToolbarOptions,
} from 'components/ProviderData/IpaAdministration/atoms/IpaAdministration.Metadata';
import { IPADetail, IpaNetworkMapping } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { IpaNetworkMappingForm } from 'components/ProviderData/IpaAdministration/organisms/IpaNetworkMapping/IpaNetworkMappingForm';
import { IpaNetworkMappingService } from 'components/ProviderData/subjects/IpaNetworkMappingService';
import { dialogService } from 'subjects/common/DialogService';

type NetworkMappingProps = {
    ipa: IPADetail;
    isReadOnlyViewer: boolean;
};

const IpaNetworkMappingTab = (props: NetworkMappingProps) => {
    const toastRef = useRef<ToastComponent>(null);
    const networkMappingGridRef = useRef<GridComponent>(null);
    const [network, setNetwork] = useState<IpaNetworkMapping | null>();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [networkMappingData, setNetworkMappingData] = useState<any>([]);

    const saveNetworkMappingButtons = [
        {
            buttonModel: {
                disabled: props.isReadOnlyViewer,
                content: 'Save',
                iconCss: 'e-icons e-save-icon',
                cssClass: 'e-submit',
            },
            click: () => {
                IpaNetworkMappingService.submit();
            },
        },
        {
            buttonModel: {
                disabled: props.isReadOnlyViewer,
                content: 'Cancel',
                iconCss: 'e-icons e-cancel-icon',
                cssClass: 'e-cancel',
            },
            click: () => {
                dialogService.close();
                setNetwork(null);
            },
        },
    ];

    const updateDataSource = () => {
        const searchNetworkMappingUrl = `/api/IpaNetworkMapping/GetNetworkMappingList?ipaCode=${props.ipa.ipaCode}&top=10&skip=0`;
        requests.get<any>(searchNetworkMappingUrl).then((data) => {
            setNetworkMappingData(data);
        });
    };

    const toolbarHandler = (args: any) => {
        if (args.item.id === 'refresh') {
            updateDataSource();
        }
        if (args.item.id === 'create') {
            IpaNetworkMappingService?.select({} as IpaNetworkMapping);
        }
    };

    function handleFormDirtyChange(isDirty) {
        setIsDirty(isDirty);
    }

    useEffect(() => {
        if (networkMappingGridRef && props.isReadOnlyViewer) {
            networkMappingGridRef.current?.toolbarModule.enableItems(['create'], false);
        }
        if (network) {
            const dialogheader = network.networkId ? 'Edit ' : 'Save ';
            const dialog: any = {};
            dialog.header = dialogheader + 'Network Mapping';
            dialog.buttons = saveNetworkMappingButtons;
            dialog.content = () => (
                <IpaNetworkMappingForm
                    network={network}
                    ipa={props.ipa}
                    onFormDirtyChange={handleFormDirtyChange}
                    isReadOnlyViewer={props.isReadOnlyViewer}
                />
            );
            dialog.width = 800;
            dialog.height = 570;
            dialogService.open(dialog);
            setNetwork(null);
        }
    }, [network, isDirty]);

    useEffect(() => {
        updateDataSource();
        IpaNetworkMappingService.get$().subscribe((network) => setNetwork(network));
        const subscription = IpaNetworkMappingService.submit$().subscribe((response) => {
            if (response) {
                if (response.error) {
                    toastRef.current?.show({
                        title: 'Error',
                        content: response.message,
                        cssClass: 'e-toast-danger',
                    });
                } else {
                    toastRef.current?.show({
                        title: 'Success !',
                        content: response.message,
                        cssClass: 'e-toast-success',
                    });
                    updateDataSource();
                }
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);
    return (
        <div className='tab-content tabDiv p-3'>
            <div className='grid-container'>
                <GridComponent
                    id='searchGridRef'
                    height={450}
                    allowPaging={true}
                    allowSorting={true}
                    columns={NetworkMappingGridColumns}
                    dataSource={networkMappingData}
                    toolbar={tabsToolbarOptions as any}
                    recordClick={(data) => {
                        IpaNetworkMappingService?.select(data.rowData);
                    }}
                    ref={networkMappingGridRef}
                    toolbarClick={toolbarHandler}
                    showColumnChooser={true}
                >
                    <Inject services={[Selection, Sort, Toolbar, ColumnChooser]} />
                </GridComponent>
            </div>
            <ToastComponent
                id='toast_target'
                ref={toastRef}
                title='Login Account'
                content=''
                position={{ X: 'Right', Y: 'Top' }}
            />
        </div>
    );
};

export default IpaNetworkMappingTab;
