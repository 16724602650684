import axios from 'axios';

import Auth from 'Auth';

export const axiosHeaders = () => ({
    Authorization: `Bearer ${Auth.getToken()}`,
});

export const axiosInstance = axios.create({
    timeout: 900000,
    headers: axiosHeaders(),
});
