import { useEffect, useState } from 'react';

import { faMicrophone, faMicrophoneSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from 'reactstrap';

const ParticipantInfo = ({ participant, sendMessage, saveAuditTrail, participantName, isPhoneCall }) => {
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [kickParticipant, setKickParticipant] = useState(false);
    const [muteParticipant, setMuteParticipant] = useState(false);

    const handleTrackDisabled = (track) => {
        if (track.kind === 'audio') {
            track.on('disabled', () => {
                setAudioEnabled(track.isEnabled);
                setMuteParticipant(false);
            });

            track.on('enabled', () => {
                setAudioEnabled(track.isEnabled);
                setMuteParticipant(false);
            });
        }
    };

    useEffect(() => {
        participant.tracks.forEach((publication) => {
            if (publication.isSubscribed) {
                handleTrackDisabled(publication.track);
            }
            publication.on('subscribed', handleTrackDisabled);

            if (publication.track && publication.track.kind === 'audio') {
                publication.track.on('disabled', handleTrackDisabled);
                publication.track.on('enabled', handleTrackDisabled);
            }
        });
    }, [participant]);

    const handleKickParticipant = () => {
        setKickParticipant(true);
        sendMessage(participant, 'logout');
        saveAuditTrail('Provider Kicked Out Attendee');
    };

    const handleMuteParticipant = () => {
        setMuteParticipant(true);
        sendMessage(participant, 'mute');
    };

    return (
        <div
            key={participant.sid}
            className='participant-item'
        >
            <span>{participantName}</span>
            {!isPhoneCall && (
                <div className='participant-buttons'>
                    <div
                        className='participant-btn handle-audio'
                        onClick={handleMuteParticipant}
                    >
                        {muteParticipant ? (
                            <Spinner
                                style={{
                                    position: 'relative',
                                    color: '#e3165b',
                                    height: '15px',
                                    width: '15px',
                                }}
                            />
                        ) : audioEnabled ? (
                            <FontAwesomeIcon
                                className='microphone-icon'
                                icon={faMicrophone}
                            />
                        ) : (
                            <FontAwesomeIcon
                                className='microphone-slash-icon'
                                icon={faMicrophoneSlash}
                            />
                        )}
                    </div>
                    <div
                        className='participant-btn handle-dismiss-participant'
                        onClick={handleKickParticipant}
                    >
                        {kickParticipant ? (
                            <Spinner
                                style={{
                                    position: 'relative',
                                    color: '#e3165b',
                                    height: '15px',
                                    width: '15px',
                                }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                className='cross-icon'
                                icon={faTimes}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ParticipantInfo;
