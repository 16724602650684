import axios, { AxiosResponse } from 'axios';

import Auth from 'Auth';

export const headers = () => ({
    Authorization: `Bearer ${Auth.getToken()}`,
});

export const instance = axios.create({
    timeout: 120000,
    headers: headers(),
});

export const requests = {
    get: <T>(url: string, params?: any): Promise<T> => {
        return axios
            .get(url, {
                params,
            })
            .then((r: AxiosResponse) => {
                return new Promise<T>((resolve) => resolve(r.data as T));
            });
    },
    put: <T>(url: string, data: Record<string, any>, params?: any): Promise<T> => {
        return axios
            .put(url, data, {
                params: { ...params },
                headers: headers(),
            })
            .then((r: AxiosResponse) => {
                return new Promise<T>((resolve) => resolve(r.data as T));
            });
    },

    post: <T>(url: string, data: Record<string, any>): Promise<T> => {
        return axios
            .post(url, data, {
                params: {},
                headers: headers(),
            })
            .then((r: AxiosResponse) => {
                return new Promise<T>((resolve) => resolve(r.data as T));
            });
    },

    deleteWithParams: <T>(url: string, data: Record<string, any>, params?: any): Promise<T> => {
        return axios
            .put(url, data, {
                params: { ...params },
                headers: headers(),
            })
            .then((r: AxiosResponse) => {
                return new Promise<T>((resolve) => resolve(r.data as T));
            });
    },

    delete: <T>(url: string): Promise<T> => {
        return axios
            .delete(url, {
                params: {},
                headers: headers(),
            })
            .then((r: AxiosResponse) => {
                return new Promise<T>((resolve) => resolve(r.data as T));
            });
    },
};
