import { useEffect, useState } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useUnsubscribe } from '@hooks';
import { formatISOLocalDateTime } from 'common/widgets';
import {
    ControlledSyncfusionDatePicker,
    ControlledSyncfusionRadioButton,
    ControlledSyncfusionTextBox,
} from 'components/Common/SyncfusionWrappers/Syncfusion';
import { DOFRAssignment, DOFRContract } from 'components/ProviderData/IpaAdministration/atoms/Models';
import styles from 'components/ProviderData/IpaAdministration/organisms/DOFR/DOFRContractAssignment.module.scss';
import { dofrService } from 'components/ProviderData/subjects/IpaAdministrationService';
import { currentUserService } from 'subjects/common/CurrentUserService';

type DOFRContractAssignmentFormProps = {
    isReadOnlyViewer: boolean;
    isAdd?: boolean;
    templateType: string;
    contract: DOFRContract | null;
    assignment: DOFRAssignment | null;
    onSubmit: (data: DOFRAssignment | null, action: 'create' | 'update') => void;
};
const DOFRContractAssignmentForm = ({
    isReadOnlyViewer,
    isAdd,
    onSubmit,
    contract,
    assignment,
    templateType,
}: DOFRContractAssignmentFormProps) => {
    const {
        control,
        formState: { errors, dirtyFields },
        handleSubmit,
        reset,
        setValue,
    } = useForm<DOFRAssignment>();
    const pushSubscription = useUnsubscribe();
    const [userId, setUserId] = useState('');

    const onRiskSelected = (args) => {
        const riskNames: (keyof DOFRAssignment)[] = [
            'isHospitalLiability',
            'isSharedLiability',
            'isOurLiability',
            'isIpaLiability',
        ];
        riskNames.forEach((name) => name !== args.name && setValue(name, false));
    };

    useEffect(() => {
        pushSubscription(
            currentUserService.get().subscribe((user: any) => {
                setUserId(user.userId);
            }),
        );
    }, []);

    useEffect(() => {
        pushSubscription(
            dofrService.get$().subscribe(({ type, isContract }) => {
                if (!isContract && type === 'submit') {
                    handleSubmit((formData) => {
                        const data = Object.keys(dirtyFields).length <= 0 ? null : formData;
                        if (data && !isAdd) {
                            formData.lastUpdatedAt = new Date().toISOString();
                            formData.lastUpdatedById = userId;
                        }
                        onSubmit(data, isAdd ? 'create' : 'update');
                    })();
                }
            }),
        );
    }, [dirtyFields, handleSubmit, isAdd, onSubmit, userId]);

    useEffect(() => {
        if (!isAdd && assignment) reset(assignment);
    }, [reset, assignment, isAdd]);

    return (
        <form>
            <fieldset disabled={isReadOnlyViewer}>
                <Row className='mb-3'>
                    <Col md={12}>
                        <label className='mb-1 fw-bolder'>Category</label>
                        <ControlledSyncfusionTextBox
                            name='category'
                            control={control}
                            rules={{ required: 'This field is required' }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='category'
                            render={({ message }) => <span className='error-form'>{message}</span>}
                        />
                    </Col>
                </Row>

                <Row className='mb-3'>
                    <Col md={12}>
                        <label className='mb-1 fw-bolder'>Subcategory</label>
                        <ControlledSyncfusionTextBox
                            name='subCategory'
                            control={control}
                            rules={{ required: 'This field is required' }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='subCategory'
                            render={({ message }) => <span className='error-form'>{message}</span>}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <label className='mb-1 fw-bolder'>Start Date</label>
                        <ControlledSyncfusionDatePicker
                            name='effectiveBeginDate'
                            control={control}
                            defaultValue={contract?.effectiveBeginDate}
                            isRetrievingDateAsString={true}
                            dpAttrs={{
                                enabled: false,
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <label className='mb-1 fw-bolder'>End Date</label>
                        <ControlledSyncfusionDatePicker
                            name='effectiveEndDate'
                            control={control}
                            defaultValue={contract?.effectiveEndDate}
                            isRetrievingDateAsString={true}
                            dpAttrs={{
                                enabled: false,
                            }}
                        />
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col md={12}>
                        <label className='mb-1 fw-bolder'>Link</label>
                        <ControlledSyncfusionTextBox
                            name='link'
                            control={control}
                        />
                    </Col>
                </Row>

                <Row className='mb-3'>
                    <Col md={12}>
                        <label className='mb-1 fw-bolder'>Comment</label>
                        <ControlledSyncfusionTextBox
                            name='comment'
                            control={control}
                            multiline
                            boxAttrs={{ htmlAttributes: { rows: '3' } }}
                        />
                    </Col>
                </Row>

                <Row className='mb-3'>
                    <Col md={4}>
                        {templateType === 'Type 1' ? (
                            <ControlledSyncfusionRadioButton
                                name='isHospitalLiability'
                                control={control}
                                label='Hospital Risk'
                                onChangeHandler={onRiskSelected}
                            />
                        ) : (
                            <ControlledSyncfusionRadioButton
                                name='isSharedLiability'
                                control={control}
                                label='Shared Risk'
                                onChangeHandler={onRiskSelected}
                            />
                        )}
                    </Col>
                    <Col md={4}>
                        <ControlledSyncfusionRadioButton
                            name='isIpaLiability'
                            control={control}
                            label='IPA Risk'
                            onChangeHandler={onRiskSelected}
                        />
                    </Col>
                    <Col md={4}>
                        <ControlledSyncfusionRadioButton
                            name='isOurLiability'
                            control={control}
                            label='AHC Risk'
                            onChangeHandler={onRiskSelected}
                        />
                    </Col>
                </Row>
                <Row className='mt-2 mb-3'>
                    {assignment?.id ? (
                        <Col md={8}>
                            <Row className='mb-2'>
                                <Col>
                                    <div className={styles.timeLineGroup}>
                                        <label>Created By</label>
                                        <input
                                            type='text'
                                            readOnly
                                            value={assignment?.createdById ?? ''}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className={styles.timeLineGroup}>
                                        <label>Updated By</label>
                                        <input
                                            type='text'
                                            readOnly
                                            value={assignment?.lastUpdatedById ?? ''}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className={styles.timeLineGroup}>
                                        <label>Created Date</label>
                                        <input
                                            type='text'
                                            readOnly
                                            title={
                                                assignment?.createdAt
                                                    ? formatISOLocalDateTime(assignment.createdAt)
                                                    : undefined
                                            }
                                            value={
                                                assignment?.createdAt
                                                    ? formatISOLocalDateTime(assignment.createdAt)
                                                    : ''
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className={styles.timeLineGroup}>
                                        <label>Updated Date</label>
                                        <input
                                            type='text'
                                            readOnly
                                            title={
                                                assignment?.lastUpdatedAt
                                                    ? formatISOLocalDateTime(assignment.lastUpdatedAt)
                                                    : undefined
                                            }
                                            value={
                                                assignment?.lastUpdatedAt
                                                    ? formatISOLocalDateTime(assignment.lastUpdatedAt)
                                                    : ''
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) : null}
                </Row>
            </fieldset>
        </form>
    );
};

export default DOFRContractAssignmentForm;
