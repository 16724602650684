import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { saveFileRequestResult } from 'common/widgets';

import { ajaxPost, ajaxGet } from '../../common/ajax';
import { notificationService } from './NotificationService';
const urlGHRAGetReport = '/api/HealthRiskAssessment/HRAGetReport';

const exportExcelUrls = {
    advancedSearchgrid_exportexcel: '/api/PatientViewer/AdvancedSearchExcelExport',
    audithistory_exportexcel: '/api/audithistory/excelexport',
    memberaudithistory_exportexcel: '/api/memberaudithistory/excelexport',
    retentionSearchgrid_exportexcel: '/api/Queues/ExcelExportMyQueueSearch',
    memberPortalAccounts_exportexcel: 'api/UserLoginAccount/excelexport',
    pendingcaseFinalization_exportexcel: 'api/um/export-pending-case-finalization-worklist',
};

const exportExcelMembersService = {
    post: (data, url) => {
        url = url || exportExcelUrls[data.requestType];
        return ajaxPost(url, data, 'blob');
    },
};

const exportExcelService = {
    post: (url, data, callback) => {
        url = url || exportExcelUrls[data.requestType];
        ajaxPost(url, data, 'blob')
            .pipe(finalize(callback))
            .subscribe({
                next: (args) => {
                    saveFileRequestResult(args.response, args.xhr);
                },
                error: () => {
                    notificationService.error({
                        title: 'Failed to export excel, please try later.',
                    });
                },
            });
    },
};

const exportHRAExcelService = {
    get: (url) => {
        return ajaxGet(url + urlGHRAGetReport);
    },
};

const membersSelectedForPdfExport = new Subject();

const exportPdfMembersService = {
    select: (memberIds) => membersSelectedForPdfExport.next(memberIds),
    onSelect: () => membersSelectedForPdfExport.asObservable(),
    post: (url, data) => ajaxPost(url || '/api/PatientViewer/AdvancedMemberBulkPdf', data, 'blob'),
};

const exportExcelOutreachPatientList = {
    post: () => ajaxPost('/api/JSAOutreach/ExcelExportOutreachPatientList', null, 'blob'),
};

const exportMyJsaSearchExcelService = {
    post: (data, queryString, count = 1000) => {
        const baseUrl = `/api/JSAAssessment/ExcelExportMyJSASearch?$count=false&$skip=0&$top=${count}`;
        const url = queryString ? `${baseUrl}&${queryString.substring(1)}` : baseUrl;

        return ajaxPost(url, data, 'blob');
    },
};

const exportCompletedJSAPdfService = {
    post: (data) => {
        const urlbase = data.urlbase ? data.urlbase : '/api/JSAAssessment';
        return ajaxPost(`${urlbase}/PrintPDF`, data, 'blob');
    },
};

const exportJSAPdfService = {
    post: (data) => ajaxPost('/api/PatientViewer/MemberJSAPdf', data, 'blob'),
};

const exportHRAcSVService = {
    get: (data) => ajaxPost('/api/HealthRiskAssessment/HRAGetReport', data, 'blob'),
};

export {
    exportExcelMembersService,
    exportPdfMembersService,
    exportMyJsaSearchExcelService,
    exportExcelOutreachPatientList,
    exportCompletedJSAPdfService,
    exportJSAPdfService,
    exportExcelService,
    exportHRAExcelService,
    exportHRAcSVService,
};
