import { useEffect, useRef, useState } from 'react';

import { ColumnChooser, GridComponent, Inject, Selection, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';

import { formatUTCDate } from 'common/widgets';
import { requests } from 'components/AvaEzm/AxiosApi';
import { getItems } from 'components/AvaEzm/Utilities/ODataUtility';
import {
    IpaPlanGridColumns,
    tabsToolbarOptions,
} from 'components/ProviderData/IpaAdministration/atoms/IpaAdministration.Metadata';
import { IpaPlan } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { IpaPlanAndCountyMappingForm } from 'components/ProviderData/IpaAdministration/organisms/PlanMapping/IpaPlanAndCountyMappingForm';
import { ipaPlanService } from 'components/ProviderData/subjects/IpaPlanService';
import { dialogService } from 'subjects/common/DialogService';

type IpaPlanProps = {
    ipaCode?: string;
    ipaId?: number;
    companyCode: string;
    isReadOnlyViewer: boolean;
};
const IpaPlanMappingTab = (props: IpaPlanProps) => {
    const { ipaCode, ipaId, companyCode, isReadOnlyViewer } = props;
    const ipaPlanGridRef = useRef<GridComponent>(null);
    const [contractCodes, setContractCodes] = useState<string[]>([]);
    const toastRef = useRef<ToastComponent>(null);
    const [ipaPlan, setIpaPlan] = useState<IpaPlan | null>();
    const [IPAPlanData, setIPAPlanData] = useState<any>([]);
    const [planMapping, setPlanMapping] = useState(false);

    const fetchContractCodes = async () => {
        const _descriptions = await requests.get<string[]>(`/api/IpaPlan/GetContractCodes?companyCode=${companyCode}`);
        setContractCodes(_descriptions);
    };

    const deleteIpaPlanHandler = async () => {
        try {
            await requests.deleteWithParams('/api/IpaPlan/DeleteIpaPlan', {
                id: ipaPlan?.id,
                ipaCode,
            });
            toastRef.current?.show({
                title: 'Success !',
                content: 'Ipa Plan deleted Successfully',
                cssClass: 'e-toast-success',
            });
            updateDataSource();
            dialogService.close();
        } catch (e) {
            toastRef.current?.show({
                title: 'Error !',
                content: 'Error deleting Ipa Plan',
                cssClass: 'e-toast-danger',
            });
        }
    };

    const saveIpaPlanButtons = [
        {
            buttonModel: {
                disabled: isReadOnlyViewer,
                content: 'Save',
                iconCss: 'e-icons e-save-icon',
                cssClass: 'e-submit',
            },
            click: () => {
                ipaPlanService.submit();
            },
        },
        {
            buttonModel: {
                disabled: isReadOnlyViewer,
                content: 'Delete',
                iconCss: 'e-icons e-delete-icon',
                cssClass: 'e-cancel',
            },
            click: () => {
                deleteIpaPlanHandler();
            },
        },
        {
            buttonModel: {
                disabled: isReadOnlyViewer,
                content: 'Cancel',
                iconCss: 'e-icons e-cancel-icon',
                cssClass: 'e-cancel',
            },
            click: () => {
                dialogService.close();
                setIpaPlan(null);
            },
        },
    ];

    const updateDataSource = () => {
        const searchContactsUrl = `/api/IpaPlan/SearchIpaPlans?ipaCode=${ipaCode}`;
        getItems<any>(searchContactsUrl).then((data: IpaPlan[]) => {
            const formattedData = data.filter((d) => {
                return {
                    ...d,
                    startDate: formatUTCDate(d.startDate),
                    endDate: formatUTCDate(d.endDate),
                };
            });
            setIPAPlanData(formattedData);
        });
    };

    const toolbarHandler = (args: any) => {
        if (args.item.id === 'refresh') {
            updateDataSource();
        }
        if (args.item.id === 'create') {
            ipaPlanService?.select({} as IpaPlan);
        }
        setPlanMapping(true);
    };

    useEffect(() => {
        if (ipaPlanGridRef && props.isReadOnlyViewer) {
            ipaPlanGridRef.current?.toolbarModule.enableItems(['create', 'edit', 'delete'], false);
        }

        if (ipaPlan && planMapping) {
            const dialog: any = {};
            dialog.header = `${ipaPlan.id ? 'Edit' : 'Create'} Plan Mapping`;
            const newButtons = saveIpaPlanButtons.filter((b) => b.buttonModel.content !== 'Delete');
            dialog.buttons = ipaPlan.id ? saveIpaPlanButtons : newButtons;
            dialog.content = () => (
                <IpaPlanAndCountyMappingForm
                    ipaPlan={ipaPlan}
                    contractCodes={contractCodes}
                    companyCode={companyCode}
                    ipaCode={ipaCode}
                    ipaId={ipaId}
                    isViewer={props.isReadOnlyViewer}
                />
            );
            dialog.width = 900;
            dialog.height = 600;
            dialogService.open(dialog);
            setIpaPlan(null);
            setPlanMapping(false);
        }
    }, [ipaPlan, planMapping]);

    useEffect(() => {
        fetchContractCodes();
        updateDataSource();
        ipaPlanService.get$().subscribe((ipap) => setIpaPlan(ipap));
        const subscription = ipaPlanService.submit$().subscribe((validated) => {
            if (validated) {
                if (validated.error) {
                    toastRef.current?.show({
                        title: 'Error',
                        content: validated.message,
                        cssClass: 'e-toast-danger',
                    });
                } else {
                    toastRef.current?.show({
                        title: 'Success !',
                        content: 'Ipa Plan saved Successfully',
                        cssClass: 'e-toast-success',
                    });
                    updateDataSource();
                }
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return (
        <div className='tab-content tabDiv p-3'>
            <div className='grid-container'>
                <GridComponent
                    id='ipaPlanSearchGridRef'
                    height={450}
                    allowPaging={true}
                    allowSorting={true}
                    columns={IpaPlanGridColumns}
                    dataSource={IPAPlanData}
                    toolbar={tabsToolbarOptions as any}
                    recordClick={(data) => {
                        ipaPlanService?.select(data.rowData), setPlanMapping(true);
                    }}
                    ref={ipaPlanGridRef}
                    toolbarClick={toolbarHandler}
                    showColumnChooser={true}
                >
                    <Inject services={[Sort, Selection, Toolbar, ColumnChooser]} />
                </GridComponent>
            </div>
            <ToastComponent
                id='toast_target'
                ref={toastRef}
                title='Login Account'
                content=''
                position={{ X: 'Right', Y: 'Top' }}
                style={{ position: 'fixed', top: '50px' }}
            />
        </div>
    );
};

export default IpaPlanMappingTab;
