import { BehaviorSubject } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { prune } from '../../common/widgets';
import { AllNavItems } from '../../components/NavMenu/NavItems';
import { currentUserService } from './CurrentUserService';

const userNavItems$ = new BehaviorSubject([]);

currentUserService.moduleIds$
    .pipe(
        filter((modules) => modules && modules.length),
        map((modules) =>
            modules.reduce((prev, curr) => {
                prev[curr] = true;
                return prev;
            }, {}),
        ),
    )
    .subscribe({
        next: (moduleMap) => {
            const items = AllNavItems;
            prune(items, moduleMap).then((prunedItems) => {
                userNavItems$.next(prunedItems);
            });
        },
        error: console.error,
    });

export { userNavItems$ };
