import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { currentUserService } from 'subjects/common/CurrentUserService';

import { ajaxPost } from '../../common/ajax';
import BaseComponent from '../../common/BaseComponent';
import { saveFileRequestResult } from '../../common/widgets';
import { exportPdfMembersService } from '../../subjects/common/ExportMembersService';
import { notificationService } from '../../subjects/common/NotificationService';
import { ExportPDFButtonURL, ExportPDFButtonContainingString } from './ExportPdfButtonHelpers';

export default class ExportPdfButton extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = { memberIds: [], disabled: true, inFlight: [] };
        this.title = props.title || 'Download P360 PDF';
        this.exportClick = new Subject();
        this.url = this.props.url || '/api/PatientViewer/AdvancedMemberBulkPdfAvailability';
        this.timeout = null;
    }

    getEventTag(sourceName) {
        switch (sourceName) {
            case 'JSA Assessment':
                return 'JSAPDFDownload';
            case 'Patient 360':
                return 'PatientViewerMemberP360Download';
            case 'Patient Priority Worklist':
                return 'PatientPriorityP360Download';
            case 'Eligibility & Benefits':
                return 'AVAExpressEligibilityP360PDFDownload';
            case 'Authorization Management':
                return 'UmAuthorizationsP360PDFDownload';
            default:
                return 'PatientViewerMemberP360Download';
        }
    }

    componentDidMount() {
        this.subscribe(exportPdfMembersService.onSelect(), {
            next: (memberIds) => this.setState({ memberIds }),
        });

        this.subscribe(this.exportClick.pipe(throttleTime(500)), {
            next: this.export.bind(this),
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    export() {
        const btn = this.button;

        const payload = {
            MemberIds: this.state.memberIds,
        };
        const newInFlight = [...this.state.inFlight, ...this.state.memberIds];

        this.subscribe(ajaxPost(this.url, payload), {
            next: (availableMembersResponse) => {
                this.subscribe(exportPdfMembersService.post(this.props.memberBulkPdfUrl, payload), {
                    next: (args) => {
                        if (availableMembersResponse.response.hasMissingMembers) {
                            notificationService.warning({
                                title: availableMembersResponse.response.searchMessage,
                            });
                        }

                        saveFileRequestResult(args.response, args.xhr);
                        btn.classList.remove('loading');
                        btn.classList.add('success');
                        this.timeout = setTimeout(() => {
                            btn.classList.remove('success');
                            this.setState({
                                inFlight: newInFlight.filter((inF) => !payload.MemberIds.includes(inF)),
                            });
                            currentUserService.track(this.getEventTag(this.props.source || 'Patient 360'), {
                                mode: this.state.memberIds.length > 1 ? 'bulk' : 'single',
                                memberIds: this.state.memberIds,
                                source: this.props.source,
                            });
                            // P360 PDF calls are made in batchs of 250 and timeout is calculated depending on number of members selected
                        }, 2000 + Math.ceil(this.state.memberIds / 250) * 15000);
                    },
                    error: (args) => {
                        if (
                            args.request?.body.includes(ExportPDFButtonContainingString.ATLEASTONEID) &&
                            args.request?.url === ExportPDFButtonURL.PATIENTSWITHOPENGAPS
                        ) {
                            notificationService.error({
                                title: 'PDF data for all the selected users are missing in ADAS',
                            });
                        }

                        btn.classList.remove('loading');
                        this.setState({
                            inFlight: newInFlight.filter((inF) => !payload.MemberIds.includes(inF)),
                        });
                    },
                });
            },
            error: (args) => {
                btn.classList.remove('loading');

                if (args && args.response && args.response.message) {
                    notificationService.error({
                        title: args.response.message,
                    });
                }

                this.setState({
                    inFlight: newInFlight.filter((inF) => !payload.MemberIds.includes(inF)),
                });
            },
        });

        this.setState({ inFlight: newInFlight });
    }

    render() {
        return (
            this.state.memberIds && (
                <button
                    data-testid={'export_button_test_id'}
                    ref={(button) => (this.button = button)}
                    className={`exportPDFBtn ${
                        this.state.inFlight.some((inFlightMemberId) => this.state.memberIds.includes(inFlightMemberId))
                            ? 'loading'
                            : ''
                    }`}
                    disabled={(this.state.memberIds || []).length === 0}
                    onClick={() => this.exportClick.next()}
                    title={this.title}
                >
                    {this.state.memberIds.length > 1 ? (
                        <span className='badge badge-dark'>{this.state.memberIds.length}</span>
                    ) : (
                        ''
                    )}
                    <i className='e-icons e-pdfBtn'></i>
                </button>
            )
        );
    }
}
