import { useEffect, useState } from 'react';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { AxiosError } from 'axios';
import moment from 'moment';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useToast } from '@hooks';
import { formatISOLocalDateTime } from 'common/widgets';
import { requests } from 'components/AvaEzm/AxiosApi';
import {
    ControlledSyncfusionCheckBox,
    ControlledSyncfusionDatePicker,
    ControlledSyncfusionDropDownList,
    ControlledSyncfusionNumericTextBox,
    ControlledSyncfusionTextBox,
} from 'components/Common/SyncfusionWrappers/Syncfusion';
import { RiskPoolRateDetail } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { showConfirmation } from 'components/ProviderData/IpaAdministration/atoms/utils';
import {
    DECIMAL_4,
    defaultValuesRiskPoolRates,
    defaultValuesRiskPoolTieredRates,
    RiskPoolLabel,
} from 'components/ProviderData/IpaAdministration/organisms/RiskPoolRates/RiskPoolRates.Metadata';
import styles from 'components/ProviderData/IpaAdministration/organisms/RiskPoolRates/RiskPoolRates.module.scss';
import { riskPoolRateService } from 'components/ProviderData/subjects/IpaAdministrationService';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import { SectionHeader } from 'components/UI/BoxSection';
import { dialogService } from 'subjects/common/DialogService';

type RiskPoolRatesProps = {
    ipaCode: string;
    ipaId?: number;
    nextSequence?: number;
    riskPoolRate?: RiskPoolRateDetail;
    capRevenues?: any;
    setPendingSelection: (data) => void;
    prevDatesRiskPool:
        | {
              effectiveStartDate: Date;
              effectiveEndDate: Date;
          }[]
        | null;
    ipaInfo: {
        dates: {
            effDate: Date;
            endDate: Date;
        };
    };
    maxEndDate?: Date | undefined;
    isReadOnlyViewer: boolean;
};

export const RiskPoolRatesForm = (props: RiskPoolRatesProps) => {
    const {
        ipaCode,
        nextSequence,
        riskPoolRate,
        capRevenues,
        ipaInfo,
        prevDatesRiskPool,
        maxEndDate,
        isReadOnlyViewer,
        setPendingSelection,
    } = props;
    const { dates } = ipaInfo;
    const {
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        getValues,
        reset,
        formState: { errors, isDirty, dirtyFields },
        control,
    } = useForm<RiskPoolRateDetail | any>({ mode: 'all' });
    const { showErrorToast } = useToast();
    const [loading, setLoading] = useState(false);
    const [formula, setFormula] = useState<string>();
    const [effMinDate, setEffMinDate] = useState<string | Date | undefined>();
    const [isESRDApplicable, setIsESRDApplicable] = useState(false);
    const minDate = riskPoolRate?.isCopy
        ? riskPoolRate?.effectiveStartDate
        : prevDatesRiskPool && prevDatesRiskPool?.length > 0 && !riskPoolRate?.rateId
        ? prevDatesRiskPool?.find((p) => p)?.effectiveEndDate
        : dates?.effDate;
    const isFormClean = !isDirty || Object.keys(dirtyFields).length <= 0;

    const saveInformation = async (data: RiskPoolRateDetail) => {
        try {
            const url = `/api/IpaRiskPoolRate/`;
            let action = 'CreateRiskPoolRate';

            const formattedData = {
                ...riskPoolRate,
                ...data,
                ipaCode,
                sequence: riskPoolRate?.rateId ? riskPoolRate.sequence : nextSequence,
                formula,
            };
            let rate;
            setLoading(true);
            if (formattedData?.rateId) {
                action = 'UpdateRiskPoolRate';
                rate = await requests.put(`${url}${action}`, formattedData);
            } else rate = await requests.post(`${url}${action}`, formattedData);
            setLoading(false);
            setPendingSelection(rate);
            riskPoolRateService.submit();
            dialogService.close();
        } catch (e) {
            console.error(e);
            dialogService.loading(false);
            setLoading(false);
            const message = `${(e as AxiosError)?.response?.data}`;
            showErrorToast(message ?? `Failed to ${data?.rateId ? 'update' : 'create'} record. Please try again later`);
        }
    };

    const validateRiskPoolDates = (field: string, date: any) => {
        const fieldName = field === 'effectiveStartDate' ? 'effective' : 'end';
        if (prevDatesRiskPool) {
            for (const prevDate of prevDatesRiskPool) {
                const effectiveStartDate = moment(prevDate?.effectiveStartDate).startOf('day');
                const effectiveEndDate = moment(prevDate?.effectiveEndDate).startOf('day');
                const dateToCompare =
                    field === 'effectiveStartDate'
                        ? moment(date.value || date).add(-1, 'day')
                        : moment(date.value || date).add(1, 'day');
                if (prevDate?.effectiveEndDate && dateToCompare.isBetween(effectiveStartDate, effectiveEndDate)) {
                    setError(field, {
                        message: `The ${fieldName} date range should not overlap with date range of another record.`,
                    });
                    return;
                } else {
                    clearErrors(field);
                }
            }
        }
    };

    useEffect(() => {
        let formData: RiskPoolRateDetail = { ...defaultValuesRiskPoolRates } as any;
        if (riskPoolRate?.id || riskPoolRate?.isCopy) {
            formData = {
                ...riskPoolRate,
                effectiveStartDate: moment(riskPoolRate.effectiveStartDate).format('MM/DD/YYYY'),
                effectiveEndDate: moment(riskPoolRate.effectiveEndDate).format('MM/DD/YYYY'),
            };
            setIsESRDApplicable(riskPoolRate?.isESRDApplicable);
        }

        const effDate =
            riskPoolRate?.id || !prevDatesRiskPool || prevDatesRiskPool?.length == 0
                ? minDate
                : moment(minDate).add(1, 'day').toDate();
        setEffMinDate(effDate);

        if (!riskPoolRate?.id) {
            formData.effectiveStartDate = moment(effDate).toDate();
            formData.effectiveEndDate = moment(dates?.endDate).toDate();
        }

        reset(formData);

        if (riskPoolRate?.isCopy) {
            setValue('effectiveStartDate', moment(riskPoolRate?.effectiveStartDate).add(1, 'day').toDate());
        }
    }, [riskPoolRate]);

    return (
        <div style={{ border: '1px solid #c8c8c8', padding: '0' }}>
            {riskPoolRate?.id && (
                <SectionHeader title='Risk Pool Details'>
                    <button
                        type='button'
                        className='e-btn avaButton text-capitalize'
                        disabled={loading}
                        onClick={async () => {
                            if (!isReadOnlyViewer) {
                                const shouldProceed = isFormClean
                                    ? isFormClean
                                    : await showConfirmation({
                                          message:
                                              'You have unsaved changes, which may be lost if you proceed. Do you want to continue?',
                                      });
                                if (!shouldProceed) return;
                            }
                            riskPoolRateService.viewHistory(riskPoolRate);
                        }}
                    >
                        View Change History
                    </button>
                </SectionHeader>
            )}
            <DisabledWrapper
                disabled={isReadOnlyViewer || loading}
                withSpinner={loading}
            >
                <form
                    onSubmit={handleSubmit(saveInformation)}
                    style={{ padding: '10px' }}
                >
                    <Row className='center-fields mb-2 mt-1'>
                        <Col md={3}>
                            <div>
                                <label className='e-label-select mb-2'>{RiskPoolLabel.capRevenue} *</label>
                                <ControlledSyncfusionDropDownList
                                    name='capRevenue'
                                    control={control}
                                    dataSource={capRevenues}
                                    fields={{ text: 'text', value: 'code' }}
                                    ddlAttrs={{
                                        placeholder: 'Select a Cap Revenue',
                                        popupHeight: '220px',
                                        popupWidth: '900px',
                                    }}
                                    onChangeHandler={(value) =>
                                        setFormula(capRevenues?.find((r) => r.code === value)?.description)
                                    }
                                />
                            </div>
                            <Form.Text className='text-danger'>{errors.capRevenue?.message}</Form.Text>
                        </Col>
                        <Col
                            md={9}
                            className='pt-2'
                        >
                            <p className='formula-description'>{formula && formula}</p>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col md={6}>
                            <TooltipComponent content={`Min Date: ${moment(effMinDate).format('MM/DD/yyyy')}`}>
                                <div className='form-group'>
                                    <div className='reversed'>
                                        <Form.Label>{RiskPoolLabel.effectiveStartDate}</Form.Label>
                                        <ControlledSyncfusionDatePicker
                                            name='effectiveStartDate'
                                            control={control}
                                            isRetrievingDateAsString={true}
                                            rules={{ required: 'Effective Date is required' }}
                                            dpAttrs={{
                                                strictMode: true,
                                                min: effMinDate ? new Date(effMinDate) : undefined,
                                                max: dates?.endDate,
                                            }}
                                            onChangeHandler={(value) =>
                                                validateRiskPoolDates('effectiveStartDate', value)
                                            }
                                        />
                                    </div>
                                    <Form.Text className='text-danger'>{errors.effectiveStartDate?.message}</Form.Text>
                                </div>
                            </TooltipComponent>
                        </Col>
                        <Col md={6}>
                            <TooltipComponent content={`Max Date: ${moment(dates?.endDate).format('MM/DD/yyyy')}`}>
                                <div className='form-group'>
                                    <div className='reversed'>
                                        <Form.Label>{RiskPoolLabel.effectiveEndDate}</Form.Label>
                                        <ControlledSyncfusionDatePicker
                                            name='effectiveEndDate'
                                            control={control}
                                            isRetrievingDateAsString={true}
                                            rules={{ required: 'End Date is required' }}
                                            dpAttrs={{
                                                strictMode: true,
                                                min: getValues().effectiveStartDate,
                                                max: dates?.endDate,
                                            }}
                                            onChangeHandler={(value) =>
                                                validateRiskPoolDates('effectiveEndDate', value)
                                            }
                                        />
                                    </div>
                                    <Form.Text className='text-danger'>{errors.effectiveEndDate?.message}</Form.Text>
                                </div>
                            </TooltipComponent>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col md={4}>
                            <div className='form-group'>
                                <div className='reversed'>
                                    <Form.Label>{RiskPoolLabel.hospitalBudgetPercent}</Form.Label>
                                    <ControlledSyncfusionNumericTextBox
                                        name='hospitalBudgetPercent'
                                        control={control}
                                        defaultValue={riskPoolRate?.hospitalBudgetPercent}
                                        boxAttrs={DECIMAL_4}
                                    />
                                </div>
                                <Form.Text className='text-danger'>{errors.hospitalBudgetPercent?.message}</Form.Text>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='form-group'>
                                <div className='reversed'>
                                    <Form.Label>{RiskPoolLabel.adminFeePercent}</Form.Label>
                                    <ControlledSyncfusionNumericTextBox
                                        name='adminFeePercent'
                                        control={control}
                                        defaultValue={riskPoolRate?.adminFeePercent}
                                        boxAttrs={DECIMAL_4}
                                    />
                                </div>
                                <Form.Text className='text-danger'>{errors.adminFeePercent?.message}</Form.Text>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='form-group'>
                                <div className='reversed'>
                                    <Form.Label>{RiskPoolLabel.surplusSharePercent}</Form.Label>
                                    <ControlledSyncfusionNumericTextBox
                                        name='surplusSharePercent'
                                        control={control}
                                        defaultValue={riskPoolRate?.surplusSharePercent}
                                        boxAttrs={DECIMAL_4}
                                    />
                                </div>
                                <Form.Text className='text-danger'>{errors.surplusSharePercent?.message}</Form.Text>
                            </div>
                        </Col>
                    </Row>
                    <Row className='center-fields mb-3'>
                        <Col md={4}>
                            <div className='form-group'>
                                <div className='reversed'>
                                    <Form.Label>{RiskPoolLabel.deficitSharePercent}</Form.Label>
                                    <ControlledSyncfusionNumericTextBox
                                        name='deficitSharePercent'
                                        control={control}
                                        defaultValue={riskPoolRate?.deficitSharePercent}
                                        boxAttrs={DECIMAL_4}
                                    />
                                </div>
                                <Form.Text className='text-danger'>{errors.deficitSharePercent?.message}</Form.Text>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='form-group'>
                                <div className='reversed'>
                                    <Form.Label>{RiskPoolLabel.esrdPremiumPercent}</Form.Label>
                                    <ControlledSyncfusionNumericTextBox
                                        name='esrdPremiumPercent'
                                        control={control}
                                        defaultValue={riskPoolRate?.esrdPremiumPercent}
                                        boxAttrs={{
                                            enabled: isESRDApplicable,
                                            showSpinButton: false,
                                            validateDecimalOnType: true,
                                            min: 0,
                                            strictMode: true,
                                            placeholder: '0.0000',
                                            format: '0.0000',
                                            decimals: 4,
                                        }}
                                    />
                                </div>
                                <Form.Text className='text-danger'>{errors.esrdPremiumPercent?.message}</Form.Text>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='form-group'>
                                <div className='reversed'>
                                    <Form.Label>{RiskPoolLabel.deficitProrationPercent}</Form.Label>
                                    <ControlledSyncfusionNumericTextBox
                                        name='deficitProrationPercent'
                                        control={control}
                                        defaultValue={riskPoolRate?.deficitProrationPercent}
                                        boxAttrs={DECIMAL_4}
                                    />
                                </div>
                                <Form.Text className='text-danger'>{errors.deficitProrationPercent?.message}</Form.Text>
                            </div>
                        </Col>
                    </Row>
                    <Row className='center-fields mb-3'>
                        <Col md={3}>
                            <ControlledSyncfusionCheckBox
                                name='isESRDApplicable'
                                control={control}
                                cbAttrs={{
                                    cssClass: styles.inlineCheckbox,
                                    label: RiskPoolLabel.isESRDApplicable,
                                }}
                                onChangeHandler={(checked) => {
                                    setIsESRDApplicable(checked);
                                    if (!checked) setValue('esrdPremiumPercent', 0);
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <ControlledSyncfusionCheckBox
                                name='isCawCost'
                                control={control}
                                cbAttrs={{
                                    cssClass: styles.inlineCheckbox,
                                    label: RiskPoolLabel.isCawCost,
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <ControlledSyncfusionCheckBox
                                name='isHospitalistPmPm'
                                control={control}
                                cbAttrs={{
                                    cssClass: styles.inlineCheckbox,
                                    label: RiskPoolLabel.isHospitalistPmPm,
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <ControlledSyncfusionCheckBox
                                name='isJSAPmPm'
                                control={control}
                                cbAttrs={{
                                    cssClass: styles.inlineCheckbox,
                                    label: RiskPoolLabel.isJSAPmPm,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='center-fields'>
                        <Col md={12}>
                            <div className='form-group'>
                                <div className='reversed'>
                                    <Form.Label>{RiskPoolLabel.notes}</Form.Label>
                                    <ControlledSyncfusionTextBox
                                        name='notes'
                                        control={control}
                                        defaultValue={riskPoolRate?.notes}
                                        multiline={true}
                                    />
                                </div>
                                <Form.Text className='text-danger'>{errors.notes?.message}</Form.Text>
                            </div>
                        </Col>
                    </Row>
                    <Row className='center-fields field-margin-top'>
                        {riskPoolRate?.id ? (
                            <Col md={5}>
                                <Row>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Created By</label>
                                            <input
                                                type='text'
                                                readOnly
                                                value={riskPoolRate?.createdById ?? ''}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Updated By</label>
                                            <input
                                                type='text'
                                                readOnly
                                                value={riskPoolRate?.lastUpdatedById ?? ''}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Last Approved By</label>
                                            <input
                                                type='text'
                                                readOnly
                                                value={riskPoolRate?.lastApprovedById ?? ''}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        ) : null}
                        <Col
                            md={7}
                            className='mt-3 d-flex justify-content-end'
                        >
                            <div className={styles.formButtonsGroup}>
                                {riskPoolRate?.id ? (
                                    <button
                                        type='button'
                                        onClick={() =>
                                            riskPoolRateService.addRiskPoolTieredRate({
                                                ...defaultValuesRiskPoolTieredRates,
                                                isRiskPoolRate: false,
                                                effectiveStartDate: riskPoolRate?.effectiveStartDate,
                                                effectiveEndDate: riskPoolRate?.effectiveEndDate,
                                                ipaCode: riskPoolRate.ipaCode,
                                                parentRateId: riskPoolRate?.rateId,
                                                riskPoolRateId: riskPoolRate?.id,
                                                createdAt: new Date(),
                                                parentRateData: riskPoolRate,
                                            })
                                        }
                                        className={`btn btn-primary btn-flat ${styles.formButton}`}
                                    >
                                        Add New Tiered Rate
                                    </button>
                                ) : null}
                                {riskPoolRate?.id ? (
                                    <button
                                        type='button'
                                        onClick={() => {
                                            riskPoolRateService.copy({
                                                ...riskPoolRate,
                                                effectiveStartDate: maxEndDate,
                                                isCapRate: true,
                                                capRevenues,
                                                ipaCode,
                                                sequence: nextSequence,
                                                id: null,
                                                rateId: 0,
                                                maxEndDate,
                                            });
                                        }}
                                        className={`btn btn-primary btn-flat btn-orange ${styles.formButton}`}
                                    >
                                        Copy Risk Pool Rate
                                    </button>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <Col md={5}>
                            {riskPoolRate?.id && (
                                <Row>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Created Date</label>
                                            <input
                                                type='text'
                                                readOnly
                                                title={
                                                    riskPoolRate?.createdAt
                                                        ? formatISOLocalDateTime(riskPoolRate.createdAt)
                                                        : undefined
                                                }
                                                value={
                                                    riskPoolRate?.createdAt
                                                        ? formatISOLocalDateTime(riskPoolRate.createdAt)
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Updated Date</label>
                                            <input
                                                type='text'
                                                readOnly
                                                title={
                                                    riskPoolRate?.lastUpdatedAt
                                                        ? formatISOLocalDateTime(riskPoolRate.lastUpdatedAt)
                                                        : undefined
                                                }
                                                value={
                                                    riskPoolRate?.lastUpdatedAt
                                                        ? formatISOLocalDateTime(riskPoolRate.lastUpdatedAt)
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.timeLineGroup}>
                                            <label>Last Approved Date</label>
                                            <input
                                                type='text'
                                                readOnly
                                                title={
                                                    riskPoolRate?.lastApprovedAt
                                                        ? formatISOLocalDateTime(riskPoolRate.lastApprovedAt)
                                                        : undefined
                                                }
                                                value={
                                                    riskPoolRate?.lastApprovedAt
                                                        ? formatISOLocalDateTime(riskPoolRate.lastApprovedAt)
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        <Col
                            md={7}
                            className='mt-2 d-flex justify-content-end'
                        >
                            <div className={styles.formButtonsGroup}>
                                <button
                                    type='button'
                                    onClick={() => riskPoolRateService.cancel()}
                                    className={`btn btn-secondary btn-flat ${styles.formButton}`}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className={`btn btn-primary btn-flat ${styles.formButton}`}
                                    disabled={isFormClean}
                                >
                                    Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </DisabledWrapper>
        </div>
    );
};
