import { Subject } from 'rxjs';

import { FullMember } from 'models/P360/MemberDataService/FullMember';

const memberP360Subject = new Subject();
export const MemberP360Service = {
    get: () => memberP360Subject.asObservable(),
    goto: (member) => memberP360Subject.next({ member }),
};

const MemberCardDataSubject = new Subject();
export const MemberCardDataService = {
    get: () => MemberCardDataSubject.asObservable(),
    fetch: (rowData, isGapsInReview?) => {
        MemberCardDataSubject.next({ rowData, isGapsInReview });
    },
};

const tabContentUpdateSubject = new Subject();
export const UpdateTabContentService = {
    get: () => tabContentUpdateSubject.asObservable(),
    update: (args) => {
        tabContentUpdateSubject.next(args);
    },
};

const updateUpcomingVisitsSubject = new Subject();
export const updateUpcomingVisitsService = {
    get: () => updateUpcomingVisitsSubject.asObservable(),
    update: (args) => {
        updateUpcomingVisitsSubject.next(args);
    },
};

const updatePastVisitsSubject: any = new Subject();
export const updatePastVisitsService = {
    get: () => updatePastVisitsSubject.asObservable(),
    update: (args?) => {
        updatePastVisitsSubject.next(args);
    },
};

const memberJSASubject = new Subject();
export const MemberJSAService = {
    get: () => memberJSASubject.asObservable(),
    goToMemberJSA: (member: FullMember) => memberJSASubject.next(member),
};

const fetchRecentAppointmentsByStatus = new Subject();
export const fetchAppointmentByStatusService = {
    get: () => fetchRecentAppointmentsByStatus.asObservable(),
    openTab: (args) => fetchRecentAppointmentsByStatus.next(args),
};
