import { useEffect } from 'react';

import { Observable } from 'rxjs';

export const useSubscription = <T>(
    source$: Observable<T> | null,
    next: (v: T) => void,
    error: (err: unknown) => void,
) => {
    useEffect(() => {
        if (source$) {
            const subscription = source$.subscribe({
                next,
                error,
            });

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [source$]);
};
