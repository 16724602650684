import React, { useEffect, useRef, useState } from 'react';

import { Spinner } from 'reactstrap';

import { ajaxGet } from 'common/ajax';
import { VideoService } from 'components/Common/VirtualVisits/VideoService';
import { notificationService } from 'subjects/common/NotificationService';

import * as helpers from '../helpers';
import {
    disconnectRoom,
    getParticipantName,
    getPhoneNumber,
    getRoomInfo,
    getTranslatorLink,
    handlerstate,
    leaveConversation,
} from '../helpers';
import { TELEHEALTH_MODULES_TO_URL } from '../MyPanelHelpers';
import { conversation$, conversationSid$ } from '../VirtualVisitService';
import { getMainParticipant } from './GetMainParticipant';
import { getOtherParticipants } from './GetOtherParticipants';
import { getParticipantInfo } from './GetParticipantInfo';
import MeetingDetails from './MeetingDetails';
import './VideoChat.css';
import VideoParticipant from './VideoParticipant/VideoParticipant';
import VirtualAppointmentsControls from './VirtualAppointmentControls';

const VideoChat = ({
    token,
    appointmentId,
    conferenceId,
    roomName,
    endCall,
    memberId,
    memberConnectionId,
    memberFullName,
    memberPhone,
    optionalAttendees,
    onlyAudio,
    visitEventTypeList = {},
    telehealthModuleType,
}) => {
    const [room, setRoom]: any = useState(null);
    const [visitEvents, setVisitEvents]: any = useState(visitEventTypeList);
    const [participants, setParticipants]: Array<any> = useState([]);
    const [translatorLink, setTranslatorLink] = useState(null);

    const [enableVideo, setEnableVideo] = useState(true);
    const [enableAudio, setEnableAudio] = useState(true);
    const [permissions, setPermissions] = useState({ audio: false, video: false });

    const [phoneNumber, setPhoneNumber] = useState(true);

    const [conversation, setConversation]: any = useState(null);
    const [conversationSid, setConversationSid] = useState(null);
    const [subscriptions, setSubscriptions]: any = useState([]);

    const dataTrack: any = useRef(null);
    const dataTrackPromise: any = useRef({});

    const translatorSubscription: any = useRef(null);
    const phoneSubscription: any = useRef(null);
    const [currentMediaStream, setCurrentMediaStream]: any = useState(null);
    let currentRunning = false;

    const getParticipantNameParams = {
        member: {
            memberFullName,
            memberConnectionId,
        },
        optionalAttendees,
    };

    const onClickIconHandler = (event) => {
        const item = event.currentTarget.getAttribute('id');
        if (item === 'muteIcon') {
            handlerstate(room, 'audio', enableAudio);
            setEnableAudio(!enableAudio);
        } else if (item === 'videoIcon') {
            handlerstate(room, 'video', enableVideo);
            setEnableVideo(!enableVideo);
        }
    };

    const sendMessage = async (participant, eventKind, eventMessage) => {
        let message;
        if (eventMessage) {
            message = eventMessage;
        } else {
            message = {
                eventKind: eventKind,
                params: {
                    identity: participant.identity,
                },
            };
        }
        await dataTrackPromise.current.promise;
        dataTrack.current.send(JSON.stringify(message));
    };

    const trackVisitEvent = (eventType, visitEventList = visitEvents) => {
        const visitEvent =
            visitEventList && Object.entries(visitEventList).find(([_, itemName]) => itemName === eventType);

        if (!visitEvent) {
            return false;
        }

        helpers.saveAuditTrail({
            memberId,
            appointmentId,
            visitEvent: visitEvent[0],
            telehealthModuleType,
        });
    };

    const finishVideoCall = (videoRoom) => {
        if (currentMediaStream) {
            currentMediaStream.getTracks().forEach((track) => track.stop());
        }

        if (!videoRoom.hasOwnProperty('localParticipant')) {
            videoRoom = room;
        }

        endCall(videoRoom, conversation);
        trackVisitEvent('Provider Disconnected');
    };

    useEffect(() => {
        const conversationSub = conversation$.subscribe({
            next: (conversation) => {
                setConversation(conversation);
            },
        });
        const conversationSidSub = conversationSid$.subscribe({
            next: (sid: any) => {
                setConversationSid(sid);
            },
        });
        setSubscriptions([...subscriptions, conversationSub, conversationSidSub]);

        return () => {
            phoneSubscription && phoneSubscription.current && phoneSubscription.current.unsubscribe();

            subscriptions.forEach((sub) => sub.unsubscribe());
        };
    }, []);

    useEffect(() => {
        if (conversation && !conversationSid) {
            sendMessage(room.localParticipant, 'conversation', {
                eventKind: 'conversation',
                params: {
                    identity: room.localParticipant.identity,
                    conversationSid: conversation.sid,
                },
            });
        }
    }, [conversation, conversationSid]);

    async function validateBrowserPermissions() {
        let hasAudio = false;
        let hasVideo = false;

        const devices = await navigator.mediaDevices.enumerateDevices();
        if (devices) {
            devices.forEach((device) => {
                if (device.kind === 'audioinput' && device.label !== '') {
                    hasAudio = true;
                }

                if (device.kind === 'videoinput' && device.label !== '') {
                    hasVideo = true;
                }
            });
        }
        return { hasAudio, hasVideo };
    }

    async function getMedia(constraints) {
        try {
            if (navigator.mediaDevices) {
                const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
                setCurrentMediaStream(mediaStream);
                return await validateBrowserPermissions();
            }
        } catch (error) {
            //some permissions are missing
            return await validateBrowserPermissions();
        }
        return { hasAudio: false, hasVideo: false };
    }

    useEffect(() => {
        currentRunning = false;
        window.addEventListener('beforeunload', finishVideoCall);

        let $suscription: any = null;

        (async () => {
            //validate video and audio permissions
            const { hasAudio, hasVideo } = await getMedia({ audio: true, video: !onlyAudio });

            setPermissions({
                audio: hasAudio,
                video: hasVideo,
            });

            const response = await getRoomInfo(
                token,
                dataTrackPromise,
                setParticipants,
                roomName,
                onlyAudio,
                hasAudio,
                hasVideo,
            );

            dataTrack.current = response.dataTrack;

            $suscription = VideoService.hideObservable().subscribe(() => finishVideoCall(response.room));

            ajaxGet(`api/${TELEHEALTH_MODULES_TO_URL[telehealthModuleType]}/VisitEvents`).subscribe({
                next: ({ response }) => {
                    setVisitEvents(response);
                    trackVisitEvent('Provider Joined', response);
                },
            });

            setRoom(response.room);
            getPhoneNumber(phoneSubscription, setPhoneNumber, telehealthModuleType);

            if (navigator.mediaDevices) {
                navigator.mediaDevices.ondevicechange = () => {
                    if (!currentRunning) {
                        currentRunning = true;

                        notificationService.error({
                            title: 'Device error, try to rejoin',
                        });

                        const hangUpButton = document.getElementById('conference-hang-up');
                        hangUpButton && hangUpButton.click();
                    }
                };
            }
        })();

        return () => {
            $suscription && $suscription.unsubscribe();
            translatorSubscription && translatorSubscription.current && translatorSubscription.current.unsubscribe();
            window.removeEventListener('beforeunload', finishVideoCall);
            disconnectRoom(room);
            leaveConversation(conversation);
            dataTrack.current = null;
        };
    }, [token]);

    const otherParticipants = getOtherParticipants(participants, memberConnectionId, room, setEnableAudio);

    const mainParticipant = getMainParticipant(participants, memberConnectionId, room, setEnableAudio, memberPhone);

    const handleTranslateLink = async () => {
        getTranslatorLink(
            translatorLink,
            translatorSubscription,
            appointmentId,
            memberId,
            setTranslatorLink,
            telehealthModuleType,
        );
    };

    return (
        <div className='room'>
            {room ? (
                <React.Fragment>
                    <div className='participants-container'>
                        <div className='remote-participants'>{mainParticipant}</div>

                        <div className='local-participant'>
                            <VideoParticipant
                                key={room.localParticipant.sid}
                                participant={room.localParticipant}
                                room={room}
                                setEnableAudio={setEnableAudio}
                            />
                            {otherParticipants}
                        </div>
                    </div>
                    <div className='videocall-details'>
                        <div className='controls-management'>
                            <VirtualAppointmentsControls
                                onClickIconHandler={onClickIconHandler}
                                enableAudio={enableAudio}
                                enableVideo={enableVideo}
                                finishVideoCall={finishVideoCall}
                                onlyAudio={onlyAudio}
                                handleTranslateLink={handleTranslateLink}
                            />
                        </div>

                        {(!permissions.video || !permissions.audio) && (
                            <div className='permissions-settings-info'>
                                {!permissions.video && (
                                    <>
                                        <span id='conference-missing-camera'>You don&apos;t have a camera</span> <br />
                                    </>
                                )}
                                {!permissions.audio && (
                                    <span id='conference-missing-microphone'>You don&apos;t have a mic</span>
                                )}
                            </div>
                        )}

                        <div className='participants-list'>
                            <div className='participants-title'>Participants</div>

                            <div className='participant-item local'>
                                <span>
                                    {getParticipantName(room.localParticipant.identity, getParticipantNameParams)}
                                </span>
                            </div>
                            {participants.map((participant) => {
                                const participantName = getParticipantName(
                                    participant.identity,
                                    getParticipantNameParams,
                                );
                                return getParticipantInfo(
                                    participant,
                                    participantName,
                                    optionalAttendees,
                                    memberConnectionId,
                                    sendMessage,
                                    trackVisitEvent,
                                );
                            })}
                            <hr />
                            <MeetingDetails
                                phoneNumber={phoneNumber}
                                conferenceId={conferenceId}
                                appointmentId={appointmentId}
                                memberId={memberId}
                                memberConnectionId={memberConnectionId}
                                memberFullName={memberFullName}
                                memberPhone={memberPhone}
                                optionalAttendees={optionalAttendees}
                                participants={participants}
                                telehealthModuleType={telehealthModuleType}
                            />
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <Spinner
                    color='light'
                    className='loading-spinner'
                />
            )}
        </div>
    );
};

export default VideoChat;
